import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import {Router} from '@angular/router';

import { Loginuser } from '../../modals/login/loginviewmodal';
import  {APPCONSTANTS, BOOLEAN_FLAG, USER_ROLE} from './../../constants/appconstant';
import { Loginwebrequest, Orgwebrequest } from 'src/app/modals/login/loginwebrequest';
import { Loginwebresponse, Infusion, Prescriber } from 'src/app/modals/login/loginwebresponse';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';
import { Status } from 'src/app/modals/drugSearch/reverseclaimResponse';
import { CookieService } from 'ngx-cookie-service';
import { TokenResponse } from 'src/app/modals/login/tokenresponse';


@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  
 errorStatus!:Loginwebresponse;
 token!:TokenResponse;

  private _appstatus : APPCONSTANTS;
  public get appstatus() : APPCONSTANTS {
    return this._appstatus;
  }

  private _USER_ROLE !: USER_ROLE;
  public get USER_ROLE(): USER_ROLE {
    return this._USER_ROLE;
  }

  private _features: Features = new Features;
  public get features(): Features {
    return this._features;
  }

  public set appstatus(status:APPCONSTANTS){
    this._appstatus=status;
    if(this._appstatus!=APPCONSTANTS.APP_INITIALIZED){
      this.router.navigateByUrl("/error",{state:this.errorStatus});
    }
  }

  private _loginuser : Loginuser;
  public get loginuser() : Loginuser {
    return this._loginuser;
  }
  
  constructor(public http:HttpClient, private cookieService: CookieService,
    public jwtHelper: JwtHelperService,
    private router: Router) { 
    this._loginuser = new Loginuser();
    this._appstatus = APPCONSTANTS.APP_NOT_INITIALIZED;
  }

  private _appLogin: APPCONSTANTS = APPCONSTANTS.APP_NOT_INITIALIZED;
  public get appLogin(): APPCONSTANTS {
    return this._appLogin;
  }
  public set appLogin(v: APPCONSTANTS) {
    this._appLogin = v;
  }

  loadOrganization(infusionlist:Infusion[], infusion:Infusion):Promise<APPCONSTANTS>{
    //debugger;
      let promise:Promise<APPCONSTANTS> = new Promise<APPCONSTANTS>((resolve,reject)=>{
        if(infusionlist!=undefined){
          let orgwebrequest:Orgwebrequest = new Orgwebrequest();
          orgwebrequest.uuid = this._loginuser.UUID;
          orgwebrequest.linkUuid = this._loginuser.linkUuid;
          //either this._value.ohid or this._value.prescriber.
          //optumId should be fine. ohid available in SSO token where as optumId comes from DB.
          orgwebrequest.optumId = this._loginuser.ohid;
          orgwebrequest.presbrId = this._loginuser.prescriber.presbrId;
          orgwebrequest.infusionFacility = infusion;
          let header=new HttpHeaders({"endpoint":environment.orgUrl});
          this.http.post(environment.proxyurl,orgwebrequest,{headers:header}).subscribe((orgwebresponse:Loginwebresponse)=>{
            debugger;
              if(orgwebresponse!=undefined && orgwebresponse.status!=undefined 
                && orgwebresponse.status.statusCode!=undefined
                && orgwebresponse.status.statusCode.toLowerCase()==APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
                && orgwebresponse.infusionList!=undefined
                && orgwebresponse.infusionList.length>0){

                  this._loginuser.infusionList.forEach(item=>{
                      if(orgwebresponse!=undefined && orgwebresponse.infusionList!=undefined
                        && item.pharmacyNPI == orgwebresponse.infusionList[0].pharmacyNPI){
                          orgwebresponse.infusionList[0].organizationRelationId = item.organizationRelationId;
                          orgwebresponse.infusionList[0].roleTypeId = item.roleTypeId;
                      }
                  });
                  this._loginuser.selectedInfusion.next(orgwebresponse.infusionList[0]);
                  resolve(APPCONSTANTS.APP_INITIALIZED);
                } else {
                  resolve(APPCONSTANTS.APP_INITIALIZATION_ERROR);
                }
          },(orgApiError)=>{
            resolve(APPCONSTANTS.APP_INITIALIZATION_ERROR);
          });
        }

      });
      return promise;
  }

  loadProfile():Promise<Boolean> {
    return new Promise<Boolean>((resolve,reject)=>{
      this._loginuser = new Loginuser();
        try{
          let loginwebrequest:Loginwebrequest = new Loginwebrequest();
          loginwebrequest.uuid = this.token.sub;
          loginwebrequest.optumId = this.token.preferred_username;
          loginwebrequest.lastName = this.token.family_name;
          loginwebrequest.firstName = this.token.given_name;
         // //console.log("optumid", loginwebrequest.optumId);
         let header=new HttpHeaders({"endpoint":environment.loginUrl});
          this.http.post(environment.proxyurl,loginwebrequest,{headers:header}).subscribe((loginresponse:Loginwebresponse)=>{
            if(loginresponse.status!=undefined
              && loginresponse.status.statusCode!=undefined
              && loginresponse.status.statusCode.toLowerCase()==APPCONSTANTS.API_RESPONSE_SUCCESS_TXT){ 
                // this.errorStatus = loginresponse;
                if(loginresponse.prescribers!=undefined && loginresponse.prescribers[0].approveInd=='Y'){
                  console.log("active");
                    if(loginresponse.reActivateInd!=undefined && loginresponse.reActivateInd.toLowerCase()=="y"){
                         //No provision of this scenario in SMAP until now. This may become valid once smap self registration is enabled.
                    } else {
                        if(loginresponse.npiValidOrNot!=undefined && loginresponse.npiValidOrNot=="0"){
                            this._loginuser.email=this.token.email;
                            this._loginuser.ohid=this.token.preferred_username;
                            this._loginuser.UUID=this.token.sub;
                            if(loginresponse.prescribers!=undefined){
                              let userlist:Prescriber[]= loginresponse.prescribers.filter(item=>item.uuid==this._loginuser.UUID);
                              if(userlist.length>0){
                                this._loginuser.prescriber=userlist[0];
                                // if(this._value.prescriber.approveId==undefined || this._value.prescriber.approveId.toLowerCase()=='n'){
                                //   this._appstatus=APPCONSTANTS.APP_INITIALIZATION_ERROR;
                                //   resolve(true);
                                // } else {
                                  
                                // }
        
                                userlist = loginresponse.prescribers.filter(item=>item.uuid!=this._loginuser.UUID);
                                this._loginuser.defaultPrescriber=(userlist.length>0)?userlist[0]:this._loginuser.prescriber;
                                if(loginresponse.infusionList!=undefined && loginresponse.infusionList.length>0){
                                      this._loginuser.infusionList=loginresponse.infusionList;                      
                                      if(loginresponse.infusionList.length>1){
                                        //debugger;
                                        this._loginuser.selectedInfusion.next(loginresponse.infusionList[0]);
                                        //Though first infusion is selected by default, UI will show a pop asking to choice infusion facility.
                                        //Organization details API call will happen after selecting a infusion from that popup.
                                        this.errorStatus = loginresponse;
                                        this._appstatus=APPCONSTANTS.APP_INITIALIZED;
                                        resolve(true);
                                      } else {
                                        //Organizations details API call will happen directly on only one available organizarion/infusion during user login. 
                                        //No need of explicit popup.
                                        this.loadOrganization(loginresponse.infusionList, loginresponse.infusionList[0]).then(response=>{
                                            this._appstatus=response;
                                            resolve(true);
                                        },error=>{
                                            this.errorStatus = loginresponse;
                                            this._appstatus=APPCONSTANTS.APP_INITIALIZATION_ERROR;
                                            resolve(true);
                                        });
                                      }
                                }
                              }
                            } else {
                              /** testing logi
                               * nresponse.status.customErrorCode="5" **/;
                              this.errorStatus = loginresponse;
                              sessionStorage.setItem("state",JSON.stringify(this.errorStatus));
                              this._appstatus=APPCONSTANTS.APP_INITIALIZATION_ERROR;
                              resolve(true);
                            }
                        } else {
                          /* If further want to code below scenario's. 
                            In PCMS, Prescriber details service will be verified before user login to check 
                            if the NPI is correct and exists in RxClaims 
                            (NPI is the NPI through with user is registered in case of prescriber. It is default prescriber NPI in case of user with admin role.)  
                            1---PrescriberDetails service getting 404 respCode
                            2---PrescriberDetail service failed
                            3--Default or Current NPI is null or empty
                          */
                          this.errorStatus = loginresponse;
                          this._appstatus=APPCONSTANTS.APP_INITIALIZATION_ERROR
                          resolve(true);
                        }
                      }
                     }
                     else{
                       //debugger;
                      console.log("inactive");
                      this._appstatus=APPCONSTANTS.NOT_APPROVED;
                      resolve(true);
                      sessionStorage.setItem("state",JSON.stringify(APPCONSTANTS.NOT_APPROVED));
                      this.errorStatus=loginresponse;
                      this.router.navigateByUrl("/error",{state:this.errorStatus});                    
                        /******error component code****** */
                    }

                 } else {
                    //debugger;
                  if(loginresponse.status!=undefined && loginresponse.status.customErrorCode!= undefined){
                    this.errorStatus = loginresponse;
                    sessionStorage.setItem("state",JSON.stringify(this.errorStatus));

                    switch(parseInt(loginresponse.status.customErrorCode)){
                      case 2:
                        this._appstatus=APPCONSTANTS.APP_LOGIN_MULTIPLE_ROLES;
                        resolve(true);
                        break;
                      case 4:
                        this._appstatus=APPCONSTANTS.APP_LOGIN_ORG_MISSING;
                        resolve(true);
                        break;
                      case 5:
                        this._appstatus=APPCONSTANTS.APP_LOGIN_ROLES_MISSING;
                        resolve(true);
                        break;
                      case 6:
                        this._appstatus=APPCONSTANTS.APP_LOGIN_USER_INACTIVE;
                        resolve(true);
                        break;
                      default:
                        this._appstatus=APPCONSTANTS.APP_INITIALIZATION_ERROR;
                        resolve(true);
                        break;
                    }
                  } else {
                    this.errorStatus = loginresponse;
                    this._appstatus=APPCONSTANTS.APP_INITIALIZATION_ERROR;
                    resolve(true);
                  }
                  
                  this.router.navigateByUrl("/error",{state:this.errorStatus}); 
              }
          },
          (loginApiError)=>{
              console.error("Login API failed:"+loginApiError);
              this._appstatus=APPCONSTANTS.APP_INITIALIZATION_ERROR;
              resolve(true);
          });
        }catch(decodeError){
          console.error("SSO Token tamperred.");
          this._appstatus=APPCONSTANTS.APP_INITIALIZATION_ERROR;
          resolve(true);
        }
      });

  }
  // logout():void{
  //   sessionStorage.clear();
  //   // this.keycloakService.logout(environment.sso_redirectUri);
  // }
  logout(): Promise<any> {
    const promise: Promise<any> = new Promise<any>((resolve, reject) => {
      let header = new HttpHeaders({ "endpoint": environment.logoutUrl.replace("{redirect_uri}", environment.sso_redirectUri).replace("{some_random_string}", environment.logout_random_string), "X-CSRF-Token": this.getCsrfToken() });
      this.http.get(environment.logoutProxyurl, { headers: header }).subscribe((response: any) => {
        if (response != undefined) {
          sessionStorage.clear();
          this.cookieService.deleteAll('/');
          resolve(response);
        } else {
          resolve(response);
        }
      }, (error: any) => {
        // this.logger.error(error);
        reject(error);
      });
    });
    return promise;

  }
  
  readJWTTokenAndUserDetails(accessCode: String, blnAppInitialization: boolean = false): Promise<boolean> {
    //debugger;
    const promise: Promise<boolean> = new Promise<boolean>((resolve: any, reject: any) => {
      let header = new HttpHeaders({ "X-CSRF-Token": this.getCsrfToken() });
      this.http.post(environment.loginProxyurl, { "code": accessCode, "redirect_url": environment.sso_redirectUri }, { headers: header }).subscribe((response: any) => {
       //debugger;
        this.token=response;
        console.log(response);
        this.loadProfile().then(()=>{
          sessionStorage.setItem('loginuserdata', JSON.stringify(response));
          this.appLogin = this.setLoginUser(response);
          resolve(true);
        });
        // if (this.appLogin == APPCONSTANTS.APP_INITIALIZED) {
        //   //this.logger.userAction(`Login successfull for OHID:${response.ohid}`);
        //   resolve(true);
        // }
        // else {
        //   if (this.appLogin == APPCONSTANTS.APP_LOGIN_USER_NOT_FOUND) {
        //   //   sessionStorage.setItem("selfregistration", JSON.stringify({ uuid: response.uuid, ohid: response.ohid }));
        //   //   //this.logger.userAction(`Started registration for ${response.ohid}`);
        //      this.router.navigateByUrl('/error');
        //    }
        //   // else {
        //   //   //this.logger.error(`Login error for ${response.ohid}, error code:${this.appLogin}`);
        //   //   this.router.navigateByUrl("/error");
        //   // }
        //   reject(false);
        // }
      }, (error: any) => {
        if (!blnAppInitialization) {
          if (error.status == 403) {
            this.appLogin = APPCONSTANTS.APP_LOGIN_FORBIDDEN;
            sessionStorage.clear();
            this.router.navigateByUrl("/sso");
            reject(false);
          }
          else {
            this.appLogin = APPCONSTANTS.APP_INITIALIZATION_ERROR;
            sessionStorage.clear();
            this.router.navigateByUrl("/error");
            reject(false);
          }
          //this.logger.error(`Login error for access code:${accessCode}`);
        }
        reject(false);
      });
    });
    return promise;
  }

  
  public getCsrfToken(): string {
    return this.cookieService.get('XSRF-TOKEN');
  }

  private getLoggedInUser(webResponse: Loginwebresponse): Prescriber | undefined {
    if (Array.isArray(webResponse.prescribers) && webResponse.prescribers.length > 0) {
      for (let i: number = 0; i < webResponse.prescribers.length; i++) {
        if (webResponse.prescribers[i].approveInd == BOOLEAN_FLAG.YES)
          return webResponse.prescribers[i];
      }
      return undefined;
    }
    else
      return undefined;

  }

  private getDefaultPrescriber(webResponse: Loginwebresponse, uuid: string): Prescriber | undefined {
    if (Array.isArray(webResponse.prescribers) && webResponse.prescribers.length > 0) {
      for (let i: number = 0; i < webResponse.prescribers.length; i++) {
        if (webResponse.prescribers[i].uuid != uuid)
          return webResponse.prescribers[i];
      }
      return undefined;
    }
    else
      return undefined;
  }


  private setLoginUser(webResponse: Loginwebresponse): APPCONSTANTS {
    let user: Loginuser = new Loginuser();
    let loggedInUser: Prescriber | undefined = this.getLoggedInUser(webResponse);
    if (loggedInUser?.roleTypeId != undefined) {
      if (loggedInUser.roleTypeId == USER_ROLE.ADMIN.toString()) {
        user.defaultPrescriber = this.getDefaultPrescriber(webResponse, loggedInUser.uuid);
        if (user.defaultPrescriber != undefined) {
          user.prescriber = loggedInUser;
          this._loginuser = user;
          this.setUserRole(USER_ROLE.ADMIN);
          return APPCONSTANTS.APP_INITIALIZED;
        }
        else
          return APPCONSTANTS.APP_LOGIN_NO_DEFAULT_PRESCRIBER;
      } else {
        if (loggedInUser.roleTypeId == USER_ROLE.PRESCRIBER.toString()
          || loggedInUser.roleTypeId == USER_ROLE.ADVISOR.toString()
          || loggedInUser.roleTypeId == USER_ROLE.SUPPORT.toString()
          || loggedInUser.roleTypeId == USER_ROLE.PHARMACIST.toString()
          || loggedInUser.roleTypeId == USER_ROLE.SPECIALTYPHARMACIST.toString()) {
          user.prescriber = loggedInUser;
          switch (loggedInUser.roleTypeId) {
            case USER_ROLE.PRESCRIBER.toString():
              this._loginuser = user;
              this.setUserRole(USER_ROLE.PRESCRIBER);
              break;
            case USER_ROLE.ADVISOR.toString():
              this._loginuser = user;
              this.setUserRole(USER_ROLE.ADVISOR);
              break;
            case USER_ROLE.SUPPORT.toString():
              //this._loginUser = user;
              this.setUserRole(USER_ROLE.SUPPORT);
              return APPCONSTANTS.APP_INITIALIZATION_ERROR;
            case USER_ROLE.PHARMACIST.toString():
              this._loginuser = user;
              this.setUserRole(USER_ROLE.PHARMACIST);
              break;
            case USER_ROLE.SPECIALTYPHARMACIST.toString():
              this._loginuser = user;
              this.setUserRole(USER_ROLE.SPECIALTYPHARMACIST);
              break;
            default:
              this._loginuser = user;
              this.setUserRole(USER_ROLE.NO_ROLE);
              return APPCONSTANTS.APP_LOGIN_ROLES_MISSING;
          }
          return APPCONSTANTS.APP_INITIALIZED;
        }
        return APPCONSTANTS.APP_INITIALIZATION_ERROR;
      }
    } else {
      return APPCONSTANTS.APP_LOGIN_USER_NOT_FOUND;
    }
  }


  private setUserRole(role: USER_ROLE) {
    this._USER_ROLE = role;
    this._features = new Features();
    switch (role) {
      case USER_ROLE.ADMIN:
        this._features.membersearch = true;
        this._features.epaflow = true;
        this._features.checkcoverageflow = true;
        // this._features.manageprescribers = true;
        //removeds as per the user story US7534822
        // this._features.benefitinquiry=true;
        break;
      case USER_ROLE.PRESCRIBER:
        this._features.membersearch = true;
        this._features.epaflow = true;
        this._features.checkcoverageflow = true;
        this._features.prescriberprofile = true;
        break;
      case USER_ROLE.ADVISOR:
        this._features.membersearch = true;
        this._features.checkcoverageflow = true;
        break;
      case USER_ROLE.PHARMACIST:
        this._features.membersearch = true;
        this._features.checkcoverageflow = true;
        break;
      case USER_ROLE.SPECIALTYPHARMACIST:
        this._features.epaflow = true;
        break;
      case USER_ROLE.SUPPORT:
        //Support login is currently through technical error page upon login. Hence no capabilities been added.
        break;
    }
    // if (this.loginuser?.prescriber?.userType?.toLowerCase() == "i" && role != USER_ROLE.SUPPORT) {
    //   if (role == USER_ROLE.ADMIN || environment.ohid_proviosioning.indexOf(this.loginuser.prescriber.optumId) != -1)
    //     this._features.provisioning = true;
    //   if (environment.ohid_controlpanel.indexOf(this.loginuser.prescriber.optumId) != -1)
    //     this._features.controlpannel = true;
    //   if (environment.ohid_bannerpanel.indexOf(this.loginuser.prescriber.optumId) != -1)
    //     this._features.bannerpannel = true;

    // }
  }

}


export class Features {
  membersearch: boolean = false;
  epaflow: boolean = false;
  checkcoverageflow: boolean = false;
  prescriberprofile: boolean = false;
}