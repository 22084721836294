<div class="container-fuild nopadding">
    <div class="row">
        <div class="col text-center" *ngIf="blnLoader">
            <loader></loader>
        </div>
    </div>
    <div class="row" *ngIf="!blnLoader && detailStatus==1">
        <div class="col">
            <span class="inputFieldLabel">Prescriber details not found. Please call 555-555-5555.</span>
        </div>
    </div>
    <div class="row alert alert-danger" *ngIf="!blnLoader && detailStatus==5">
        <div class="float-start servererror">
            <i class="fa fa-ban"></i>Prescriber details do not contain valid Fax number. Please select another prescriber and try again.
        </div>
    </div>
    <div class="row" *ngIf="blnShow && !blnLoader && detailStatus!=2 && detailStatus!=1 && detailStatus!=5 && internalEdit">
        <div class="col text-start">
            <label class="inputFieldLabel">Verify the information below before submitting.</label>
        </div>
        <div class="col text-end">
            <button class="btn underline btn-link fontbold" (click)="edit()" *ngIf="detailStatus==0">
                Edit provider information
            </button>
            <div *ngIf="detailStatus==3 || detailStatus==4">
                <button class="btn underline btn-link" (click)="discardEdit()">Cancel</button>
                &nbsp;
                <button class="btn btn-primary rounded-pill" (click)="saveEdit()">Save</button>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="blnShow && !blnLoader && detailStatus!=2 && detailStatus!=1 && detailStatus!=5">
        <div class="col control">
            <label class="form-label fontbold">
                Prescriber
            </label>
            <br/>
            <span class="inputFieldLabel">{{fullName}}</span>
        </div>
        <div class="col control">
            <label class="form-label fontbold">
                NPI
            </label>
            <br/>
            <span class="inputFieldLabel">{{NPI}}</span>
        </div>
    </div>
    <div class="row" *ngIf="blnShow && !blnLoader && detailStatus!=1 && detailStatus!=2 && detailStatus!=5">
        <div class="col control">
            <label class="form-label fontbold">
                Address
            </label>
            <br/>
            <select [(ngModel)]="selectedAltAddressIndex" class="rounded-0 inp form-select dropdown">
                <option *ngFor="let alt of alternativeAddresses;index as i;" [ngValue]="i">{{alt.address.address1 | stringtosentencecase}}, {{getAddressLine3(alt) | stringtosentencecase}}</option>
            </select>
        </div>
    </div>
    <div class="row" *ngIf="blnShow && !blnLoader && detailStatus==0">
        <div class="col-md-3 col-xs-6 control">
            <label class="form-label fontbold">
                Phone
            </label>
            <br/>
            <span class="inputFieldLabel">{{selectedAddressPhone | formatphoneorfax}}</span>
        </div>
        <div class="col-md-3 col-xs-6 control">
            <label class="form-label fontbold">
                Fax
            </label>
            <br/>
            <span class="inputFieldLabel">{{selectedAddressFax | formatphoneorfax}}</span>
        </div>
    </div>
    <form name="editProviderPanelForm" *ngIf="detailStatus==3 || +detailStatus==4" [formGroup]="editProviderPanel" (ngSubmit)="saveEdit()">
        <div class="row">
            <div class="col-md-3 col-xs-6 control">
                <label class="form-label fontbold" for="phone" id="phone">
                    Phone
                </label>
                <br/>
                <input type="text" maxlength="10" name="editedAddressPhone" class="rounded-0 form-control inp" aria-required="true"
                formControlName="editedAddressPhone"
                [ngClass]="{'inpError':(blnEditingPanelSubmitted && (!editProviderPanel.controls['editedAddressPhone'].valid || !zeroRegExp.test(editProviderPanel.controls['editedAddressPhone'].value) || editProviderPanel.controls['editedAddressPhone'].value.length<10)) 
                || (!blnEditingPanelSubmitted && !editProviderPanel.controls['editedAddressPhone'].pristine 
                && editProviderPanel.controls['editedAddressPhone'].errors && editProviderPanel.controls['editedAddressPhone'].errors['pattern'])}"/>
                
                <span class="text-danger" *ngIf="!editProviderPanel.controls['editedAddressPhone'].valid 
                && editProviderPanel.controls['editedAddressPhone'].errors
                && editProviderPanel.controls['editedAddressPhone'].errors['pattern']">Invalid character.</span>
                <span class="text-danger" *ngIf="blnEditingPanelSubmitted 
                && editProviderPanel.controls['editedAddressPhone'].valid && ((!editProviderPanel.controls['editedAddressPhone'].valid || !zeroRegExp.test(editProviderPanel.controls['editedAddressPhone'].value) || editProviderPanel.controls['editedAddressPhone'].value.length<10))">
                Enter a valid phone number.
                </span>
            </div>
            <div class="col-md-3 col-xs-6 control">
                <label class="form-label fontbold" for="Fax" id="Fax">
                    Fax
                </label>
                <br/>
                <input type="text"  maxlength="10" name="editedAddressFax" class="rounded-0 form-control inp" aria-required="true" 
                formControlName="editedAddressFax"
                [ngClass]="{'inpError':(blnEditingPanelSubmitted && (!editProviderPanel.controls['editedAddressFax'].valid || !zeroRegExp.test(editProviderPanel.controls['editedAddressFax'].value) || editProviderPanel.controls['editedAddressFax'].value.length<10)) 
                || (!blnEditingPanelSubmitted && !editProviderPanel.controls['editedAddressFax'].pristine 
                && editProviderPanel.controls['editedAddressFax'].errors && editProviderPanel.controls['editedAddressFax'].errors['pattern'])}"/>

                <span class="text-danger" *ngIf="!editProviderPanel.controls['editedAddressFax'].valid 
                && editProviderPanel.controls['editedAddressFax'].errors
                && editProviderPanel.controls['editedAddressFax'].errors['pattern']">Invalid character.</span>
                <span class="text-danger" *ngIf="blnEditingPanelSubmitted 
                && editProviderPanel.controls['editedAddressFax'].valid && ((!editProviderPanel.controls['editedAddressFax'].valid || !zeroRegExp.test(editProviderPanel.controls['editedAddressFax'].value) || editProviderPanel.controls['editedAddressFax'].value.length<10))">
                Enter a valid phone number.
                </span>
            </div>
        </div>
    </form>
</div>

