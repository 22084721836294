import { Component, OnChanges, OnInit, OnDestroy } from '@angular/core';

import { Router, Event, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-checkcoverage',
  templateUrl: './checkcoverage.component.html',
  styleUrls: ['./checkcoverage.component.css']
})
export class CheckcoverageComponent implements OnInit, OnDestroy {
  routeVal:string="";
  routeData!:Subscription;
  blnProgressBar:boolean;

  constructor(private router:Router) { 
    this.blnProgressBar=true;
    this.routeData=this.router.events.subscribe((event:Event)=>{
      if(event instanceof NavigationEnd){
        if(event!=undefined && event.urlAfterRedirects!=undefined 
          && event.urlAfterRedirects.toLowerCase().indexOf("summary")!=-1){
            this.blnProgressBar=false;
          } else {
            this.blnProgressBar=true;
          }
      }
    });
  }
  
  ngOnDestroy():void{
    if(this.routeData!=undefined)
      this.routeData.unsubscribe();
  }

  ngOnInit(): void {
    sessionStorage.setItem("lastPageBeforeReload", this.router.url);
    if(this.router.url.indexOf("summary")!=-1){
      this.blnProgressBar=false;
    }
  }

}
