import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild, IterableDiffers } from '@angular/core';
import { Router } from '@angular/router';
import { ClaimSummaryDetails, BANNER_TYPE, ClaimSummaryDurReject } from 'src/app/modals/claimsummary/claimsummary';
import { SelectedMedicine } from 'src/app/modals/drugSearch/drugsearchwebresponse';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { ServertimeToStandardDate, TimeStampToStandardDate } from 'src/app/pipes/dateconversion/dateconversion.pipe';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { USER_ID_TYPE_Label, USER_ROLE, PERSONGENDER_DESC, PRESCRIBER_QUALIFIER_LABEL, PRESCRIBER_QUALIFIER, APPCONSTANTS, COVERAGE_MESSAGE_TYPE, CLAIM_STATUS_TYPE, DUR, DUR_REASON_CODES } from 'src/app/constants/appconstant';
import { explainerAnim, slideDown } from 'src/animations/animations';
import { DateFieldResultType, DateFieldValidationMode, DateFieldEvent } from 'src/app/modals/datefield/datefield';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CheckCoveragePageService } from 'src/app/services/drugSearch/check-coverage-page.service';
import { interval, Observable, Subscription, } from 'rxjs';
import { LstClaim, SaveTrailClaimRequest } from 'src/app/modals/drugSearch/SaveTrailClaimRequest';
import { DurSegment } from 'src/app/modals/drugSearch/Trailadjudicationwebrequest';
import { environment } from 'src/environments/environment';
import { SaveTrailClaimResponse, Response } from 'src/app/modals/drugSearch/SaveTrailClaimResponse';
import { SubmitMultipleClaimResponse, SubmitClaimResponseDetail, RejectCodes } from 'src/app/modals/drugSearch/submitMultipleClaimResponse';
import { ReverseClaimResponse } from 'src/app/modals/drugSearch/reverseclaimResponse';
import { ReverseClaimRequest } from 'src/app/modals/drugSearch/reverseclaimRequest';
import { Trailadjudicationwebresponse, LocalMessages } from 'src/app/modals/drugSearch/Trailadjudicationwebresponse';
import { Trailadjudicationwebrequest } from 'src/app/modals/drugSearch/Trailadjudicationwebrequest';
import { PrescriberSearchService } from 'src/app/services/prescriberSearch/prescriber-search.service';
import { SubmitMultipleClaimRequest, SubmitClaimRequest } from 'src/app/modals/drugSearch/submitMultipleClaimRequest';
import { CalculateQuantityService } from 'src/app/services/Quantity/calculate-quantity.service';
import { ClaimUpdateWebRequest, SavedClaimHistory } from 'src/app/modals/claimhistory/claimhistorywebrequest';
import { Status } from 'src/app/modals/webresponsestatus';
import { ClaimhistoryService } from 'src/app/services/claimhistory/claimhistory.service';
//import { MemberDetailLoadItem, MemberDetailWebResponse } from 'src/app/modals/membersearch/memberdetailwebresponse';
import { ClaimDetialsHistoryGroup } from 'src/app/modals/claimhistory/claimhistorywebresponse';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { QtyCalculated } from 'src/app/services/Quantity/calculate-quantity.service';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-claimsummary',
  templateUrl: './claimsummary.component.html',
  styleUrls: ['./claimsummary.component.css'],
  animations: [
    explainerAnim,
    slideDown
  ]
})
export class ClaimsummaryComponent implements OnInit {
  claimResponseList!: SelectedMedicine[];
  claimsummaryDetailsrequestList: ClaimSummaryDetails[] = [];

  claimsummaryDetailsrequest!: ClaimSummaryDetails;
  counter = 1;
  subscription: Subscription = new Subscription();
  trailAdjuducicationError = "";
  modalContent = "";
  blntermedMember = false;

  @ViewChild("saveErrorModal")
  saveErrorModalRef!: ElementRef<HTMLElement>;
  saveErrorText!: string;

  @ViewChild("reverseErrorModal")
  reverseErrorModalRef!: ElementRef<HTMLElement>;
  reverseErrorText!: string;
  

  @ViewChild("errorModal")
  errorModalRef!: ElementRef<HTMLElement>;

  @ViewChild("durSoftsUnAnsweredModal")
  durSoftsUnAnsweredModalRef!: ElementRef<HTMLElement>;

  @ViewChild("overwriteConfirmModal")
  overwriteConfirmModal!: ElementRef<HTMLElement>;

  @ViewChild("saveClaimConfirmModal")
  saveClaimConfirmModal!: ElementRef<HTMLElement>;

  @ViewChild("submitClaimSuccessModal")
  submitClaimSuccessModal!: ElementRef<HTMLElement>;

  @ViewChild("submitClaimErrorModal")
  submitClaimErrorModal!: ElementRef<HTMLElement>;
  submitClaimErrorText!: string;

  @ViewChild("submitClaimOnOldTrailErrolModal")
  submitClaimOnOldTrailErrolModalRef!: ElementRef<HTMLElement>;

  @ViewChild("reverseClaimSuccessModal")
  reverseClaimSuccessModal!: ElementRef<HTMLElement>;

  @ViewChild("reverseClaimConfirmModal")
  reverseClaimConfirmModal!: ElementRef<HTMLElement>;

  @ViewChild("reverseClaimRejected")
  reverseClaimRejected!: ElementRef<HTMLElement>;


  @ViewChild("rexecuteClaimConfirmModal")
  rexecuteClaimConfirmModal!: ElementRef<HTMLElement>;

  @ViewChild("submitClaimNotAllowedModal")
  submitClaimNotAllowedModal!: ElementRef<HTMLElement>;

  @ViewChild("updateReexecuteConfirmModal")
  updateReexecuteConfirmModal!: ElementRef<HTMLElement>;

  @ViewChild("claimSummaryProgress")
  claimSummaryProgress!: ElementRef<HTMLElement>;

  @ViewChild("memberTermedConfirmModal")
  memberTermedConfirmModal?: ElementRef<HTMLElement>;

  @ViewChild("memberTermedExpiredModal")
  memberTermedExpiredModal?: ElementRef<HTMLElement>;

  @ViewChild("LoadingContent")
  loadingContent!: ElementRef<HTMLElement>;


  constructor(private router: Router, private trailclaimadjudicationHelper: CheckCoveragePageService,
    public profileService: ProfileService, private servertimetostandarddate: ServertimeToStandardDate,
    private timestampTostandardDate: TimeStampToStandardDate, private modalService: NgbModal,
    private prescriberHelperService: PrescriberSearchService, private claimHistoryService: ClaimhistoryService,
    private quantityService: CalculateQuantityService, private logger: LoggerService,private titleService:Title) { 
      this.titleService.setTitle("SMAP - Check Prescription Coverage - Summary");
    }
  test: string = "";

  updatingDOS = 0;
  changeDateForm = new FormGroup({
    dateOfService: new FormControl('', { validators: [Validators.pattern('(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)[0-9]{2}')], updateOn: 'blur' }),
  });

  get dateOfService() {
    return this.changeDateForm.get('dateOfService')
  }

  // For Date Component integration
  dosValue: string = "";
  dosResult: DateFieldResultType = DateFieldResultType.EMPTY;
  dosValidationMode: DateFieldValidationMode = DateFieldValidationMode.ONCHANGE;
  emptyDOS = true;
  dateFieldName: string = "Date of Service";
  onDobUpdate(event: DateFieldEvent): void {
    this.dosValue = event.value;
    this.dosResult = event.valid;
  }

  ngOnInit(): void {
    this.modalService.dismissAll();
    sessionStorage.setItem("lastPageBeforeReload", this.router.url);
    //console.log("Rerouting URL:" + sessionStorage.getItem("lastPageBeforeReload"));
    this.loadClaimSummary();

  }

  private updateBannerMsgByClaimType(request: ClaimSummaryDetails, trailResponse: Trailadjudicationwebresponse, lob: string): void {
    // Trail Adjudication Error Response Handling
    //Eroro scenario 1
    if (trailResponse.status.customErrorCode == "1") {
      request.bannerType = BANNER_TYPE.RED;//"Error1Red";
      request.bannerMessage = COVERAGE_MESSAGE_TYPE.COVERAGE_ERROR;
    }

    //Eroro scenario 2
    else if ((trailResponse.status.statusCode != undefined && trailResponse.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) && trailResponse.status.respCode == APPCONSTANTS.API_RESPONSE_SUCCESS_CODE && (trailResponse.status.responseDescriptionCode != "No records found")) {
      // Claim Summary Scenarios
      // 1st Transistion Benefit
      if (trailResponse.response.rejectCode.length == 0 && trailResponse.response.transactionReponseStatus != undefined && (APPCONSTANTS.RTB_TRAILRESPONSE_PAID.indexOf(trailResponse.response.transactionReponseStatus.toLowerCase()) != -1)) {
        //if condition will be added later trailResponse.response.transitionBenefitsMessage!=undefined
        if (trailResponse.response.transitionBenefitsMessage != undefined && trailResponse.response.transitionBenefitsMessage != "") {
          request.bannerType = BANNER_TYPE.YELLOW; //"TransistionPaidYellow";
          request.bannerMessage = trailResponse.response.message.trim().replace(/’/g, '\'');
        } else {

          //2nd Medlimit message for opioid with PA 
          if (trailResponse.response.message.trim().toLowerCase() == COVERAGE_MESSAGE_TYPE.OPIOID_MORPHINE.toLowerCase()) {
            // banner color:
            //   1. noPAWithNoAlts (Green banner as paid) (When Submitted claim is not rejected)
            //   2. notCovered (Red) (When submitted claim is rejected in real claim scenario
            request.bannerType = BANNER_TYPE.GREEN; //"DisplayMedLimitBannerNoPAGreen";
            request.bannerMessage = trailResponse.response.message.trim().replace(/’/g, '\'');
          }

          //3rd Prenatal message for opioid    
          else if (trailResponse.response.message.trim().replace(/’/g, '\'').toLowerCase() == COVERAGE_MESSAGE_TYPE.OPIOID_PRENATAL.toLowerCase()) {
            request.bannerType = BANNER_TYPE.RED; //"DisplayPrenatalBannerRed";
            request.bannerMessage = trailResponse.response.message.trim().replace(/’/g, '\'');
          }

          //4th Benzo message for opioid    
          else if (trailResponse.response.message.trim().toLowerCase() == COVERAGE_MESSAGE_TYPE.OPIOID_BENZODIAZEPINE.toLowerCase()) {
            request.bannerType = BANNER_TYPE.YELLOW; //"DisplayBenzoBannerYellow";
            request.bannerMessage = trailResponse.response.message.trim().replace(/’/g, '\'');
          }

          //5th Matt message for opioid    
          else if (trailResponse.response.message.trim().toLowerCase() == COVERAGE_MESSAGE_TYPE.OPIOID_MAT.toLowerCase()) {
            request.bannerType = BANNER_TYPE.YELLOW; //"DisplayMatBannerYellow";
            request.bannerMessage = trailResponse.response.message.trim().replace(/’/g, '\'');
          }

          //6th Paid claim 
          else {
            // banner color:
            // 1. noPAWithNoAlts (Green banner as paid) (When Submitted claim is not rejected)
            // 2. notCovered (Red) (When submitted claim is rejected

            request.bannerType = BANNER_TYPE.GREEN; //"DisplayNoPABannerGreen";
            request.bannerMessage = trailResponse.response.message.trim().replace(/’/g, '\'');
          }
        }
      }
      //Scenario7 (acetaminophen opiod)
      else if (trailResponse.response.rejectCode.length != 0 && (lob.toLowerCase() == "uhccs" || lob.toLowerCase() == "uhcmr" || lob.toLowerCase() == "uhcei" || lob.toLowerCase() == "orxcom" || lob.toLowerCase() == "orxmed") && (trailResponse.response.message.trim().toLowerCase() == COVERAGE_MESSAGE_TYPE.OPIOID_ACETAMINOPHEN.toLowerCase())) {
        request.bannerType = BANNER_TYPE.RED;//"DisplayNotCoveredAcetBannerRed";
        request.bannerMessage = trailResponse.response.message.trim().replace(/’/g, '\'');
        //console.log("DisplayNotCoveredAcetBannerRed");
      } else {
        //conditions for DrugStatus 

        if (trailResponse.response.message.trim().toLowerCase() == COVERAGE_MESSAGE_TYPE.PRODUCT_EXCLUDED_IN_PLAN1.toLowerCase() || trailResponse.response.message.trim().toLowerCase() == COVERAGE_MESSAGE_TYPE.PRODUCT_EXCLUDED_IN_PLAN2.toLowerCase() || trailResponse.response.message.trim().toLowerCase() == COVERAGE_MESSAGE_TYPE.PRODUCT_EXCLUDED_IN_PLAN3.toLowerCase()) {
          request.drugStatus = 'R';
        }
        else {
          request.drugStatus = 'F';
        }
        //Scenario8 (Not covered with reject 70)

        if (trailResponse.response.rejectCode.indexOf('70') != -1 && request.drugStatus.toLowerCase() == "r") {

          request.bannerType = BANNER_TYPE.RED; //"DisplayNotCoveredR70BannerRed";
          request.bannerMessage = trailResponse.response.message.trim().replace(/’/g, '\'');
        }
        //Scenario9 (Not covered with reject 70)

        else if (trailResponse.response.rejectCode.indexOf('70') != -1 && request.drugStatus.toLowerCase() != "r") {
          request.bannerType = BANNER_TYPE.YELLOW; //"DisplayNotCoveredR70WithPABannerYellow";
          request.bannerMessage = trailResponse.response.message.trim().replace(/’/g, '\'');

        }

        //Scenario10 ( Reject code 50)

        else if (trailResponse.response.rejectCode.indexOf('50') != -1) {
          request.bannerType = BANNER_TYPE.RED; //"DisplayNotCoveredR50BannerRed";
          request.bannerMessage = trailResponse.response.message.trim().replace(/’/g, '\'');
        }

        //Scenario11 ( Require PA with reject code 75)

        else if (trailResponse.response.rejectCode.indexOf('75') != -1) {
          request.bannerType = BANNER_TYPE.YELLOW; //"DisplayPABannerYellow";
          request.bannerMessage = trailResponse.response.message.trim().replace(/’/g, '\'');
        }

        //Scenario12 ( Reject 76)

        else if (trailResponse.response.rejectCode.indexOf('76') != -1) {
          request.bannerType = BANNER_TYPE.RED; //"DisplayNotCoveredR76BannerRed";
          request.bannerMessage = trailResponse.response.message.trim().replace(/’/g, '\'');
        }

        //Scenario13 ( Reject 80)

        else if (trailResponse.response.rejectCode.indexOf('80') != -1) {
          request.bannerType = BANNER_TYPE.YELLOW; //"DisplayNotCoveredR80WithPABannerYellow";
          request.bannerMessage = trailResponse.response.message.trim().replace(/’/g, '\'');
        }
        // Scenario14 ( Rest all are not covered)

        else {

          request.bannerType = BANNER_TYPE.RED; //"DisplayNotCoveredBannerRed";
          request.bannerMessage = trailResponse.response.message.trim().replace(/’/g, '\'');
        }
      }

      //scenario 15 brandpeffered,requirePA, Initiate PA
      if (trailResponse.prefAlternativesWebResponse.scenarioClaims[0].responseClaimSegment.brandPreferred == true) {
        request.brandPreferred = true;
      }
      if ((trailResponse.response.requiresPA == true) || ((trailResponse.response.rejectCode.indexOf('70') != -1) && (trailResponse.response.drugStatus.toLowerCase() != 'r') && (trailResponse.response.message.trim().toLowerCase() == COVERAGE_MESSAGE_TYPE.PRODUCT_NOT_COVERED_IN_PLAN))) {
        request.requiresPA = true;
        request.initiatePALnk = true;
      }
      if ((this.profileService.loginuser.selectedInfusion.getValue().roleTypeId == USER_ROLE.ADVISOR.toString()) || (this.profileService.loginuser.selectedInfusion.getValue().roleTypeId == USER_ROLE.SMAPCLAIMSUB.toString())) {
        request.initiatePALnk = false;
      }

    }

    //Eroro scenario 3
    else if (trailResponse.status.responseDescriptionCode != undefined) {
      request.bannerMessage = trailResponse.status.responseDescriptionCode;
      request.bannerType = BANNER_TYPE.RED; //"Error3Red";
    }

    //Eroro scenario 4
    else if (trailResponse.status.dbStatusCode == '7') {
      request.bannerType = BANNER_TYPE.RED; //"Error4Red";
      request.bannerMessage = COVERAGE_MESSAGE_TYPE.BPG_NOT_MAPPEDTO_CARRIER;
    }

    //Eroro scenario 6 

    else if (trailResponse.prefAlternativesWebResponse == undefined && trailResponse.invocationContext == undefined && trailResponse.serviceContext == undefined && trailResponse.integrationStatus == undefined) {
      request.bannerType = BANNER_TYPE.RED; //"Error6Red";
      request.bannerMessage = COVERAGE_MESSAGE_TYPE.COVERAGE_ERROR;
    }


    //scenario 5
    else {
      request.bannerType = BANNER_TYPE.RED; //"Error5Red";
      request.bannerMessage = trailResponse.status.statusDesc;
    }
    // End Error Response Handling
  }

  private setShowPricing(request: ClaimSummaryDetails, trailResponse: Trailadjudicationwebresponse): void {
    //scenario 16 show pricing
    if (trailResponse.prefAlternativesWebResponse != undefined && trailResponse.prefAlternativesWebResponse.scenarioClaims[0].responsePricingSegment.showPricing == true) {

      if (parseInt(trailResponse.prefAlternativesWebResponse.scenarioClaims[0].responsePricingSegment.patientPayAmount) > 0) {
        request.PatientPayAmount = "$" + trailResponse.prefAlternativesWebResponse.scenarioClaims[0].responsePricingSegment.patientPayAmount;
      }
      else {
        request.PatientPayAmount = "N/A";
      }
    }
    else {
      request.PatientPayAmount = "N/A";
    }
    // //console.log("ppa", request.PatientPayAmount);

    if (trailResponse.prefAlternativesWebResponse != undefined && trailResponse.prefAlternativesWebResponse.scenarioClaims[0].responsePricingSegment.showPricing == true) {
      if (parseInt(trailResponse.prefAlternativesWebResponse.scenarioClaims[0].responsePricingSegment.provideAmountDue) > 0) {
        request.ProviderTotalAmountDue = "$" + trailResponse.prefAlternativesWebResponse.scenarioClaims[0].responsePricingSegment.provideAmountDue;
      }
      else {
        request.ProviderTotalAmountDue = "N/A";
      }
    }
    else {
      request.ProviderTotalAmountDue = "N/A";
    }

    if (trailResponse.prefAlternativesWebResponse != undefined && trailResponse.prefAlternativesWebResponse.scenarioClaims[0].responsePricingSegment.showPricing == true) {

      if (parseInt(trailResponse.prefAlternativesWebResponse.scenarioClaims[0].responsePricingSegment.approvedProfessionalFee) > 0) {
        request.ApprovedProfessionalFee = "$" + trailResponse.prefAlternativesWebResponse.scenarioClaims[0].responsePricingSegment.approvedProfessionalFee;
      }
      else {
        request.ApprovedProfessionalFee = "N/A";
      }
    }
    else {
      request.ApprovedProfessionalFee = "N/A";
    }
    if (trailResponse.prefAlternativesWebResponse != undefined && trailResponse.prefAlternativesWebResponse.scenarioClaims[0].responsePricingSegment.showPricing == true) {

      if (parseInt(trailResponse.prefAlternativesWebResponse.scenarioClaims[0].responsePricingSegment.approvedClaimIgredientCost) > 0) {
        request.ApprovedClaimIngredientCost = "$" + trailResponse.prefAlternativesWebResponse.scenarioClaims[0].responsePricingSegment.approvedClaimIgredientCost;
      }
      else {
        request.ApprovedClaimIngredientCost = "N/A";
      }
    }
    else {
      request.ApprovedClaimIngredientCost = "N/A";
    }
    //console.log("ptad", request.ProviderTotalAmountDue);
    //console.log("amount", request.PatientPayAmount);
    //console.log("anotheramount", request.ProviderTotalAmountDue);
  }

  private mapSummaryDetailsFromTrailData(request: ClaimSummaryDetails, item: SelectedMedicine): void {
    // Setting other fields for claim summary page
    //request.DateofService = this.timestampTostandardDate.transform(item.trailadjudicationwebrequest.dateOfService); 
    request.DateofService = this.timestampTostandardDate.transform(item.trailadjudicationwebrequest.dateOfService); 
    request.DaysSupply = item.trailadjudicationwebrequest.daysSupply; //item.Days;
    
    request.PlaceofService = item.trailadjudicationwebrequest.placeOfService; //item.placeOfservice;
    if(item.trailadjudicationwebresponse.response.diagnosisCode!=undefined && item.trailadjudicationwebresponse.response.diagnosisCodeDescription!=undefined)
      request.DiagnosisCode =  item.trailadjudicationwebresponse.response.diagnosisCode+" "+item.trailadjudicationwebresponse.response.diagnosisCodeDescription; 
    else
      request.DiagnosisCode = item.trailadjudicationwebrequest.diagnosisCode; //item.DiagnosisCode;
    
    if(item.trailadjudicationwebrequest.productServiceId == item.trailadjudicationwebresponse.prefAlternativesWebResponse.scenarioClaims[0].responseClaimSegment.productOrServiceId 
      || item.drugSearchItemOnTrail==undefined){
      //Same drug returned from trail response. Submitted drug has not been replaced with some other drug during real time benefit check.
      request.QuantityDesc = item.quantytyDesc;
      request.QuantityEntered = item.Quantity;
      request.MedicationName = item.trailadjudicationwebrequest.drugName; //item.MedStrengthName;
      request.ProductID = item.trailadjudicationwebrequest.productServiceId; //item.MedStrength;
      request.QuantityCalculated = item.quantytyCalculate;     
    } else {
      //Submitted drug is replace by some other drug during real time benefit check.
      request.MedicationName = item.trailadjudicationwebresponse.prefAlternativesWebResponse.scenarioClaims[0].responseClaimSegment.productLabelName; 
      request.ProductID = item.trailadjudicationwebresponse.prefAlternativesWebResponse.scenarioClaims[0].responseClaimSegment.productOrServiceId; 
      request.QuantityEntered = item.Quantity;
      let drugDescriptionWithDispensedQty:QtyCalculated = this.quantityService.quantityCalculate(item.trailadjudicationwebresponse.prefAlternativesWebResponse.scenarioClaims[0].responseClaimSegment.quantityDispensed, item.drugSearchItemOnTrail);
      request.QuantityCalculated = parseFloat(drugDescriptionWithDispensedQty.qtycaliculated);   
      request.QuantityDesc = drugDescriptionWithDispensedQty.qtydesc;
    }
    
    
    request.Name = item.trailadjudicationwebrequest.firstName + " " + item.trailadjudicationwebrequest.lastName; //item.selectedMember.firstName + " " + item.selectedMember.lastName;
    request.MemberID = item.trailadjudicationwebrequest.cardholderId; //item.selectedMember.memberId;
    let formattedDate = this.servertimetostandarddate.transform(item.trailadjudicationwebrequest.dateOfBirth); //this.servertimetostandarddate.transform(item.selectedMember.dateOfBirth);
    request.DateOfBirth = formattedDate;
    this.dosValue = request.DateofService;
  }

  private mapSummaryDetailsFromSelectedMedicine(request: ClaimSummaryDetails, item: SelectedMedicine): void {
    if (item.trailadjudicationwebresponse.response != undefined && item.trailadjudicationwebresponse.response.localMessagesList.length > 0) {
      request.localMessages = item.trailadjudicationwebresponse.response.localMessagesList;
    }
    if (item.trailadjudicationwebresponse.response != undefined) {
      request.BinPCNGroup = item.trailadjudicationwebresponse.response.bin + "/" + item.trailadjudicationwebresponse.response.pcn + "/" + item.trailadjudicationwebresponse.response.groupId;
    } else {
      request.BinPCNGroup = "N/A"
    }
    request.ContractedNetworkProvider = this.profileService.loginuser.selectedInfusion.getValue().pharmacyName;
    request.ContractedNetworkProviderAddress = this.profileService.loginuser.selectedInfusion.getValue().phmcyAddr1 + " " + this.profileService.loginuser.selectedInfusion.getValue().phmcyAddr2 + " " + this.profileService.loginuser.selectedInfusion.getValue().phmcyCty + ", " + this.profileService.loginuser.selectedInfusion.getValue().phmcySt + " " + this.profileService.loginuser.selectedInfusion.getValue().phmcyZip;
    request.claimGroupId = item.clmgroupId;
    request.dateOfLastTrail = item.dateOfLastTrial;
  }

  private mapMemberDataFromSelectedMedicine(request: ClaimSummaryDetails, item: SelectedMedicine): void {
    if (item.memberDetailResponse != undefined && item.memberDetailResponse.memberItems[0] != undefined) {
      request.Phone = (item.memberDetailResponse.memberItems[0].contact.phoneNumber != undefined && item.memberDetailResponse.memberItems[0].contact.phoneNumber.length > 0) ? item.memberDetailResponse.memberItems[0].contact.phoneNumber : "N/A";
      if (item.memberDetailResponse.memberItems[0].demographics.gender != undefined && item.memberDetailResponse.memberItems[0].demographics.gender.length > 0) {
        const gender: string | undefined = PERSONGENDER_DESC.get(item.memberDetailResponse.memberItems[0].demographics.gender.toUpperCase());
        if (gender != undefined)
          request.Gender = gender;
        else
          request.Gender = "---";
      }
      request.Address = item.memberDetailResponse.memberItems[0].contact.address1 + ", "
        + item.memberDetailResponse.memberItems[0].contact.address2 + ", "
        + item.memberDetailResponse.memberItems[0].contact.address3 + ", "
        + item.memberDetailResponse.memberItems[0].contact.city + ", "
        + item.memberDetailResponse.memberItems[0].contact.state + ", "
        + item.memberDetailResponse.memberItems[0].contact.zip;
    } else {
      if (item.selectedMember != undefined) {
        if (item.selectedMember.gender != undefined && item.selectedMember.gender.length > 0) {
          const gender: string | undefined = PERSONGENDER_DESC.get(item.selectedMember.gender.toUpperCase());
          if (gender != undefined)
            request.Gender = gender;
          else
            request.Gender = "---";
        } else {
          request.Gender = "---";
        }
        request.Address = item.selectedMember.address.address1 + ", "
          + item.selectedMember.address.address2 + " , "
          + item.selectedMember.address.address3 + " , "
          + item.selectedMember.address.city + " ,"
          + item.selectedMember.address.state + ", "
          + item.selectedMember.address.zip1;
      } else {
        request.Gender = "---";
        request.Address = "---";
      }
    }
  }

  private loadSavedClaimStatus(request: ClaimSummaryDetails, item: SelectedMedicine): boolean {
    /******************Load saved claim details if this claim is already a saved before******************/
    if (item.saveClaimResponseLstClaim != undefined && item.saveClaimResponseLstClaim.status != undefined
      && item.saveClaimResponseLstClaim.status.statusCode != undefined && item.saveClaimResponseLstClaim.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
      && item.saveClaimResponseLstClaim.status.dbStatusCode != undefined && item.saveClaimResponseLstClaim.status.dbStatusCode == APPCONSTANTS.API_RESPONSE_DB_SUCCESS_CODE) {
      request.claimGroupId = item.saveClaimResponseLstClaim.claimGroupId;
      request.dateOfLastTrail = this.timestampTostandardDate.transform(item.saveClaimResponseLstClaim.claimDate);
      if (item.saveClaimResponseLstClaim.claimStsTypId == undefined || item.saveClaimResponseLstClaim.claimStsTypId == CLAIM_STATUS_TYPE.UN_SAVED)
      {
        request.claimStsTypId = CLAIM_STATUS_TYPE.UN_SAVED;
        item.blnSavedAfterTrail=false;
        return false;
      }
      else
      {
        request.claimStsTypId = item.saveClaimResponseLstClaim.claimStsTypId;
        item.blnSavedAfterTrail=true;
        return true;
      }
    } else {
      request.claimStsTypId = CLAIM_STATUS_TYPE.UN_SAVED;
      item.blnSavedAfterTrail=false;
      return false;
    }
  }

  private loadSubmittedClaimStatus(request: ClaimSummaryDetails, item: SelectedMedicine): boolean {

    /******************Load submitted claim response if this claim is already submitted before******************/
    if (item.submitClaimResponseLstClaim != undefined) {
      if (item.submitClaimResponseLstClaim.status != undefined
        && item.submitClaimResponseLstClaim.status.statusCode != undefined
        && item.submitClaimResponseLstClaim.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
        request.rxServiceReferenceNo = item.submitClaimResponseLstClaim?.submitClaimResponse?.realClaimResponse.rxServiceReferenceNo || "";
        request.uniqueClaimId = item.submitClaimResponseLstClaim?.submitClaimResponse?.realClaimResponse.uniqueClaimId || "";
        
        if (item.submitClaimResponseLstClaim.claimStsTypId == CLAIM_STATUS_TYPE.SUBMITTED_PAID || item.submitClaimResponseLstClaim.claimStsTypId == CLAIM_STATUS_TYPE.REVERSED_PAID || item.submitClaimResponseLstClaim.claimStsTypId == CLAIM_STATUS_TYPE.REVERSED_REJECTED) {
          if (item.submitClaimResponseLstClaim.status.dbStatusCode != undefined && item.submitClaimResponseLstClaim.status.dbStatusCode == APPCONSTANTS.API_RESPONSE_DB_SUCCESS_CODE) {
            request.bannerType = BANNER_TYPE.GREEN; //item.submitClaimResponseLstClaim.status.statusCode + "SubmitGreen";
            request.claimStsTypId = item.submitClaimResponseLstClaim.claimStsTypId || CLAIM_STATUS_TYPE.SUBMITTED_PAID;
            request.bannerMessage = "The claim has been submitted.";
            if (item.submitClaimResponseLstClaim?.submitClaimResponse?.realClaimResponse.message != undefined) {
              request.bannerMessage = item.submitClaimResponseLstClaim.submitClaimResponse.realClaimResponse.message;
            }
            return true;
          } else {
            request.bannerType = BANNER_TYPE.RED; //"SubmitRed";
            request.claimStsTypId = item.submitClaimResponseLstClaim.claimStsTypId || CLAIM_STATUS_TYPE.SUBMITTED_REJECTED;
            request.bannerMessage = item.submitClaimResponseLstClaim.status.dbStatusDesc || "Submit claim has been rejected.";
            request.claimSubmitCustomErrorMessage = "This medication rejected and will need to be re-submitted under a different transaction. Please remove medication and re-submit.";
            return false;
          }
        } else {
          request.bannerType = BANNER_TYPE.RED; //item.submitClaimResponseLstClaim.status.statusCode + "SubmitRed";
          request.claimStsTypId = item.submitClaimResponseLstClaim.claimStsTypId;
          request.bannerMessage = "Submit claim has been rejected.";
          if (item.submitClaimResponseLstClaim?.submitClaimResponse?.realClaimResponse.message != undefined) {
            request.bannerMessage = item.submitClaimResponseLstClaim.submitClaimResponse.realClaimResponse.message;
          }
          request.claimSubmitCustomErrorMessage = "This medication rejected and will need to be re-submitted under a different transaction. Please remove medication and re-submit.";
          return false;
        }
      } else {
        request.bannerType = BANNER_TYPE.RED; //item.submitClaimResponseLstClaim.status.statusCode + "SubmitRed";
        request.claimStsTypId = CLAIM_STATUS_TYPE.SUBMITTED_REJECTED;
        request.bannerMessage = item.submitClaimResponseLstClaim.status.statusDesc || "Submit claim has been rejected.";
        if (item.submitClaimResponseLstClaim?.submitClaimResponse?.realClaimResponse.message != undefined) {
          request.bannerMessage = item.submitClaimResponseLstClaim.submitClaimResponse.realClaimResponse.message;
        }
        return false;
      }
    } else {
      return false;
    }
  }

  private loadReversedClaimStatus(request: ClaimSummaryDetails, item: SelectedMedicine): boolean {
    /******************Load reversal claim response if this claim is already reversed before******************/
    if (item.reverseClaimResponseLstClaim != undefined && item.reverseClaimResponseLstClaim.status != undefined
      && item.reverseClaimResponseLstClaim.status.statusCode != undefined && item.reverseClaimResponseLstClaim.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
      && item.reverseClaimResponseLstClaim.claimReverseResponse != undefined && item.reverseClaimResponseLstClaim.claimReverseResponse.realClaimReversalResponse != undefined
      && item.reverseClaimResponseLstClaim.claimStsTypId == CLAIM_STATUS_TYPE.REVERSED_PAID
      && item.saveClaimResponseLstClaim != undefined) {
      request.bannerType = BANNER_TYPE.BLACK; //"Reversed" + "Black"
      ////debugger;
      request.bannerMessage = item.reverseClaimResponseLstClaim.claimReverseResponse.realClaimReversalResponse.message || "The claim has been successfully reversed.";
      if(item.reverseClaimResponseLstClaim.claimReverseResponse.realClaimReversalResponse.message=="The claim has been submitted."){
        request.bannerMessage="The claim has been successfully reversed.";
      }
      request.claimStsTypId = item.reverseClaimResponseLstClaim.claimStsTypId;
      request.dateOfLastTrail = this.timestampTostandardDate.transform(item.saveClaimResponseLstClaim.claimDate);
      return true;
    } else {
      if (item.saveClaimResponseLstClaim != undefined) {
        request.dateOfLastTrail = this.timestampTostandardDate.transform(item.saveClaimResponseLstClaim.claimDate);
        if (item.saveClaimResponseLstClaim.claimStsTypId == CLAIM_STATUS_TYPE.REVERSED_REJECTED) {
          request.bannerType = BANNER_TYPE.RED;
          request.bannerMessage = item.reverseClaimResponseLstClaim?.claimReverseResponse?.realClaimReversalResponse.message || "The claim reversal was unsuccessful.";
        }
      }
      return false;
    }
  }

  private readDurSegmentsFromSubmittedClaim(medicine: SelectedMedicine):DurSegment[]{
    const durSegments:DurSegment[]=[];
    if(medicine.submitClaimResponseLstClaim!=undefined && medicine.submitClaimResponseLstClaim.submitClaimResponse!=undefined
      && medicine.submitClaimResponseLstClaim.submitClaimResponse.realClaimResponse!=undefined){
        if(Array.isArray(medicine.submitClaimResponseLstClaim.submitClaimResponse.realClaimResponse.durReasons)){
          for(let i:number=0;i<medicine.submitClaimResponseLstClaim.submitClaimResponse.realClaimResponse.durReasons.length;i++){
            const durSegmentObj:DurSegment = new DurSegment();
            durSegmentObj.durMessage = DUR_REASON_CODES.get(medicine.submitClaimResponseLstClaim.submitClaimResponse.realClaimResponse.durReasons[i].durReason) || "";
            durSegmentObj.freeText = medicine.submitClaimResponseLstClaim.submitClaimResponse.realClaimResponse.durReasons[i].durFreeText;
            durSegmentObj.additionalText = medicine.submitClaimResponseLstClaim.submitClaimResponse.realClaimResponse.durReasons[i].durAddlText;
            durSegmentObj.reasonSrvcCd = medicine.submitClaimResponseLstClaim.submitClaimResponse.realClaimResponse.durReasons[i].durReason;
            durSegmentObj.response = medicine.submitClaimResponseLstClaim.submitClaimResponse.realClaimResponse.durReasons[i].durResponse;
            durSegmentObj.prfsnlSrvcCd = medicine.submitClaimResponseLstClaim.submitClaimResponse.realClaimResponse.durReasons[i].prfsnlSrvcCd;
            durSegmentObj.rsltSrvcCd = medicine.submitClaimResponseLstClaim.submitClaimResponse.realClaimResponse.durReasons[i].rsltSrvcCd;
            durSegmentObj.durMsgId = medicine.submitClaimResponseLstClaim.submitClaimResponse.realClaimResponse.durReasons[i].durMsgId;
            durSegmentObj.levelOfEffort = medicine.submitClaimResponseLstClaim.submitClaimResponse.realClaimResponse.durReasons[i].levelOfEffort;
            durSegments.push(durSegmentObj);
          }
        }
    }
    return durSegments;
  }

  private showUnFilledSoftRejectsInTrailResponse(request:ClaimSummaryDetails, durSegments:Array<DurSegment>, blnDurRejectCodeExists:boolean=true):void{
    //request.durReject.isDurCodesValid=false;
    request.durReject.isDurReject = false;
    request.durReject.durMessages = [];
    request.durReject.durAllRejects=[];
    request.durReject.durSoftRejects=[];
    request.durReject.durInvalidSoftRejects=[];
    
    if(durSegments!=undefined && Array.isArray(durSegments) && durSegments.length>0){
      request.localMessages.reverse();
      let blnAllSoftRejects:boolean=true;
      //request.durReject.durMessages.push(request.bannerMessage);

      request.localMessages.reverse();

      for(let durIndex:number=0;durIndex<durSegments.length;durIndex++){
        const durItem:DurSegment = durSegments[durIndex];
        let blnSoftRejectIdentified:boolean = false;

        request.durReject.durAllRejects.push(JSON.parse(JSON.stringify(durItem)));
        
        if(durItem.response == APPCONSTANTS.RTB_DUR_RESPONSE_NONSOFTREJECT){
          blnAllSoftRejects=false;
          continue;
        } else {
          request.durReject.isDurReject=true;
        }

        if(durItem.response == APPCONSTANTS.RTB_DUR_RESPONSE_SOFTREJECT){

          if((durItem.reasonSrvcCd!=undefined && durItem.reasonSrvcCd.length>0 && durItem.reasonSrvcCd!=APPCONSTANTS.RTB_DUR_REASONSRVCCD_NONSOFTREJECT)
            && request.claimStsTypId<=CLAIM_STATUS_TYPE.RE_EXECUTE && blnDurRejectCodeExists
            && ((durItem.prfsnlSrvcCd==undefined || durItem.prfsnlSrvcCd.length==0) || (durItem.rsltSrvcCd==undefined || durItem.rsltSrvcCd.length==0)))
            {
              blnSoftRejectIdentified=true;          
              request.durReject.durSoftRejects.push(JSON.parse(JSON.stringify(durItem)));
            } else {
              request.durReject.durInvalidSoftRejects.push(JSON.parse(JSON.stringify(durItem)));
              if(request.claimStsTypId>CLAIM_STATUS_TYPE.RE_EXECUTE || !blnDurRejectCodeExists){
                if(request.durReject.durMessages.length==1 && durItem.durMessage!=undefined 
                  && durItem.durMessage.length>0 && request.durReject.durMessages.indexOf(durItem.durMessage)==-1)
                  request.durReject.durMessages.push(durItem.durMessage);
              }
            }
          } 
          else 
          {
            if(request.durReject.durMessages.length==1 && durItem.durMessage!=undefined 
            && durItem.durMessage.length>0 && request.durReject.durMessages.indexOf(durItem.durMessage)==-1)
              request.durReject.durMessages.push(durItem.durMessage);
          
            if(durItem.prfsnlSrvcCd!=undefined && durItem.prfsnlSrvcCd.length!=0 && durItem.rsltSrvcCd!=undefined && durItem.rsltSrvcCd.length!=0){
              request.durReject.durInvalidSoftRejects.push(JSON.parse(JSON.stringify(durItem)));  
            } else {
              blnAllSoftRejects=false;
            }
          }
        
          if(durItem.additionalText!=undefined && durItem.additionalText.length>0 && request.localMessages.find((msg:LocalMessages)=>msg.localMessageCodeText==durItem.additionalText)==undefined)
            request.localMessages.push({localMessageCodeText:durItem.additionalText,additionalField:[],localMessageCode:"",localMessageSeq:""});
          if(durItem.freeText!=undefined && durItem.freeText.length>0 && request.localMessages.find((msg:LocalMessages)=>msg.localMessageCodeText==durItem.freeText)==undefined)
            request.localMessages.push({localMessageCodeText:durItem.freeText,additionalField:[],localMessageCode:"",localMessageSeq:""});
      }
      // if(request.durReject.durSoftRejects.length==0){
      //   request.durReject.isDurCodesValid=true;
      // }

      request.localMessages.reverse();
      if(blnDurRejectCodeExists){
        if(request.bannerType == BANNER_TYPE.RED && blnAllSoftRejects){
          request.bannerType = BANNER_TYPE.YELLOW;
        }
      }
    }
  }
  private accumulateDurSegmentsIntoEveryTrailResponse(medicine: SelectedMedicine, durSourceSegment:DurSegment[]=[]):void{
    /*******************DUR Handling***************** */
    if(Array.isArray(medicine.trailadjudicationwebresponse.response.rejectCode) && medicine.trailadjudicationwebresponse.response.rejectCode.length>0){
        if(medicine.trailadjudicationwebresponse.response.rejectCode.indexOf(APPCONSTANTS.RTB_DUR_REJECT_CODE)!=-1){
          //Trail claim is rejected with 88 reject code. Taking latest DUR segments received from trail response.
          durSourceSegment = medicine.trailadjudicationwebresponse.response.durSegment; 
        } else {
          //Trail claim is rejected during recent trail with some other reason (Not reject code 88). 
          //Nullifying any dur segments available in the trail response.
          durSourceSegment =[];
        }
    }
    medicine.trailadjudicationwebresponse.response.durSegment = durSourceSegment;
    /*******************DUR Handling***************** */
  }
  mapDurRejectData(request: ClaimSummaryDetails, medicine: SelectedMedicine): void {

    if(request!=undefined){

      request.claimStsTypId = (request.claimStsTypId==undefined)?CLAIM_STATUS_TYPE.UN_SAVED:request.claimStsTypId;
      
      switch(request.claimStsTypId){
        case CLAIM_STATUS_TYPE.UN_SAVED:
          if(medicine.trailadjudicationwebresponse!=undefined && medicine.trailadjudicationwebresponse.status!=undefined
            && medicine.trailadjudicationwebresponse.status.statusCode!=undefined && medicine.trailadjudicationwebresponse.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
            && medicine.trailadjudicationwebresponse.status.respCode == APPCONSTANTS.API_RESPONSE_SUCCESS_CODE){
              this.accumulateDurSegmentsIntoEveryTrailResponse(medicine,medicine.trailadjudicationwebrequest.durSegment);
              this.showUnFilledSoftRejectsInTrailResponse(request, medicine.trailadjudicationwebresponse.response.durSegment,  medicine.trailadjudicationwebresponse.response.rejectCode.length>0 &&  medicine.trailadjudicationwebresponse.response.rejectCode.indexOf(APPCONSTANTS.RTB_DUR_REJECT_CODE)!=-1);
          }        
        break;
        case CLAIM_STATUS_TYPE.SAVED:
        case CLAIM_STATUS_TYPE.RE_EXECUTE:
          if(medicine.saveClaimResponseLstClaim!=undefined && medicine.saveClaimResponseLstClaim.status!=undefined
            && medicine.saveClaimResponseLstClaim.status.statusCode!=undefined && medicine.saveClaimResponseLstClaim.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
            && medicine.saveClaimResponseLstClaim.status.dbStatusCode!=undefined && medicine.saveClaimResponseLstClaim.status.dbStatusCode == APPCONSTANTS.API_RESPONSE_DB_SUCCESS_CODE
            && medicine.saveClaimResponseLstClaim.claimDetailId!=undefined && medicine.saveClaimResponseLstClaim.claimDetailId.toString().length>0 
            && medicine.blnSavedAfterTrail){
              this.accumulateDurSegmentsIntoEveryTrailResponse(medicine, medicine.saveClaimResponseLstClaim.durSegment);
              this.showUnFilledSoftRejectsInTrailResponse(request, medicine.trailadjudicationwebresponse.response.durSegment,  medicine.trailadjudicationwebresponse.response.rejectCode.length>0 &&  medicine.trailadjudicationwebresponse.response.rejectCode.indexOf(APPCONSTANTS.RTB_DUR_REJECT_CODE)!=-1);
            }
        break;
      }
    }
  }

  loadClaimSummary() {
    // //debugger;
    window.scroll(0, 0);
    //console.log(JSON.parse("" + sessionStorage.getItem('selectedMedicineList')));
    this.claimResponseList = JSON.parse("" + sessionStorage.getItem('selectedMedicineList'));
    //console.log("claimresponse", this.claimResponseList);
    
    //To add another medication post saving the claims in DB.
    let saveClaimRequired: boolean=false;
    
    if(this.claimResponseList.length>0 && this.claimResponseList[0].clmgroupId!=undefined && this.claimsummaryDetailsrequestList.length==0){
      this.claimResponseList.forEach((item:SelectedMedicine)=>{
        // //debugger;
        if(item.claimDetailId==0 || item.claimDetailId==undefined)
        // //debugger;
          saveClaimRequired=true;
      });

      if(saveClaimRequired)
        this.saveTrailClaimDetails();

    }
      
    this.claimsummaryDetailsrequestList = [];

    let blnAllSaved: boolean = true;
    let blnAllSubmitted: boolean = true;
    let blnAllReversed: boolean = true;

    if (Array.isArray(this.claimResponseList) && this.claimResponseList.length > 0) {
      this.claimResponseList.forEach((item: SelectedMedicine) => {
        const claimsummaryDetailsrequest: ClaimSummaryDetails = new ClaimSummaryDetails();
        this.updateBannerMsgByClaimType(claimsummaryDetailsrequest, item.trailadjudicationwebresponse, item.selectedMember.lob);
        this.setShowPricing(claimsummaryDetailsrequest, item.trailadjudicationwebresponse);
        this.mapSummaryDetailsFromSelectedMedicine(claimsummaryDetailsrequest, item);
        this.mapSummaryDetailsFromTrailData(claimsummaryDetailsrequest, item);
        this.mapMemberDataFromSelectedMedicine(claimsummaryDetailsrequest, item);

        if (!this.loadSavedClaimStatus(claimsummaryDetailsrequest, item))
          blnAllSaved = false;

        if (!this.loadSubmittedClaimStatus(claimsummaryDetailsrequest, item))
          blnAllSubmitted = false;

        if (!this.loadReversedClaimStatus(claimsummaryDetailsrequest, item))
          blnAllReversed = false;

        this.mapDurRejectData(claimsummaryDetailsrequest,item);
        //console.log("Testing ClaimList2:");
        //console.log(claimsummaryDetailsrequest);
        this.claimsummaryDetailsrequestList.push(claimsummaryDetailsrequest);
      });
      //console.log("Testing ClaimList:");
      //console.log(this.claimsummaryDetailsrequestList);
      // //console.log("Date:"+Date.parse("11/20/2022"));
      this.claimsummaryDetailsrequest = JSON.parse(JSON.stringify(this.claimsummaryDetailsrequestList[0]));
    }
    this.claimsummaryDetailsrequest.claimStsTypId = (blnAllSaved) ? CLAIM_STATUS_TYPE.RE_EXECUTE : CLAIM_STATUS_TYPE.UN_SAVED;
    this.claimsummaryDetailsrequest.claimStsTypId = (blnAllSubmitted) ? CLAIM_STATUS_TYPE.SUBMITTED_PAID : this.claimsummaryDetailsrequest.claimStsTypId;
    this.claimsummaryDetailsrequest.claimStsTypId = (blnAllReversed) ? CLAIM_STATUS_TYPE.REVERSED_PAID : this.claimsummaryDetailsrequest.claimStsTypId;
  }
  

  changeDOS(saveToDB: boolean = false) {
    this.modalService.dismissAll();
    saveToDB = this.saveToDB;
    if (this.updatingDOS == 0) {
      this.updatingDOS = 1;
      this.dosValue = this.claimsummaryDetailsrequest.DateofService;
    } else {
      // start calling the Api
      this.updatingDOS = 0;
      this.logger.userAction("Date of Service Changed to :"+this.dosValue);
      this.reexecuteClaim(this.dosValue, saveToDB);
    }
  }

  isValidDateFormat(d: any) {
    if (Object.prototype.toString.call(d) === "[object Date]") {
      // it is a date
      if (isNaN(d)) { // d.getTime() or d.valueOf() will also work
        return false
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  checkMemberTermed(response: SelectedMedicine): any {

    var blntermedMember: boolean = false;
    var eligibilityItem = response.memberDetailResponse.memberItems[0].memberEligibility.filter((item: any) => item.status != undefined);
    if (eligibilityItem.length > 0) {
      var thruDate;
      if (this.isValidDateFormat(new Date(eligibilityItem[0].eligibilityEndDate))) {
        thruDate = new Date(eligibilityItem[0].eligibilityEndDate);
        //console.log("Enter");
      }
      else {
        var year = eligibilityItem[0].eligibilityEndDate.substring(0, 4);
        var month = eligibilityItem[0].eligibilityEndDate.substring(4, 6);
        var day = eligibilityItem[0].eligibilityEndDate.substring(6, 8);
        thruDate = new Date(year + "/" + month + "/" + day);
        //console.log("Enter", thruDate);
      }
      var today = new Date();
      if (thruDate < today) {
        //Memer termed.
        var timeDiff = Math.abs(today.getTime() - thruDate.getTime());
        var daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
        //console.log("Enter");
        return daysDiff;
      }
    }
    //console.log("", blntermedMember);
    return 0;

  }

  reexecuteClaim(dateOfServ: string, saveToDB: boolean) {
    
    this.logger.userAction("Rexecuting Claim");
    this.modalService.dismissAll();
    this.modalService.open(this.claimSummaryProgress, { modalDialogClass: 'claimSummaryProgress' });

    this.claimResponseList.forEach((item: SelectedMedicine) => {
      item.dateOfservice = dateOfServ;
      item.blnSavedAfterTrail=false;
      const claimSummaryDetail: ClaimSummaryDetails | undefined = (Array.isArray(item.trailadjudicationwebresponse.prefAlternativesWebResponse.scenarioClaims) && item.trailadjudicationwebresponse.prefAlternativesWebResponse.scenarioClaims.length>0)?this.claimsummaryDetailsrequestList.find((summaryItem:ClaimSummaryDetails)=>summaryItem.ProductID == item.trailadjudicationwebresponse.prefAlternativesWebResponse.scenarioClaims[0].responseClaimSegment.productOrServiceId):undefined;
      if(claimSummaryDetail!=undefined && this.isDurSoftRejectOnPageAnswered(claimSummaryDetail)){
        item.durReject = claimSummaryDetail.durReject;
      } else {
        item.durReject = new ClaimSummaryDurReject();
      }
    });

      this.logger.log("Rexecuting Claim: "+JSON.stringify(this.claimResponseList));
      //console.log(this.claimResponseList);
      this.trailclaimadjudicationHelper.checkCoverage(this.claimResponseList).then((resp: SelectedMedicine[]) => {
        if (resp) {
          this.counter = 2;
          const source = interval(2000);
          this.subscription = source.subscribe(() => {
            this.counter++;
            //console.log(this.counter);
            if (this.counter == 4) {
              //console.log(this.counter);
              this.subscription.unsubscribe();
              this.modalService.dismissAll();
              
              if (resp[0].trailadjudicationwebresponse.status.respCode == '404'
                && resp[0].trailadjudicationwebresponse.status.statusCode.toLowerCase() == 'success'
                && resp[0].trailadjudicationwebresponse.status.responseDescriptionCode != undefined
                && resp[0].trailadjudicationwebresponse.status.responseDescriptionCode.toLowerCase().indexOf("member not found") != -1
                && this.checkMemberTermed(this.claimResponseList[0]) > 0) {
                //member termed and is a saved trail claim
                //console.log("Member termed");
                var diffDays: any = this.checkMemberTermed(this.claimResponseList[0]);
                if (diffDays <= 30) {
                  this.logger.log("Member termed and the trail claim is saved one.");
                  //console.log("Member termed and the trail claim is saved one.");
                  //Member termed and the trail claim is saved one.
                  this.modalService.dismissAll();
                  this.modalService.open(this.memberTermedConfirmModal, { modalDialogClass: 'memberTermedConfirmModal' });
                }
                else {
                  //Member termed more than 30days ago
                  this.logger.log("Member termed more than 30days ago");
                  //console.log("Member termed more than 30days ago");
                  this.modalService.dismissAll();
                  this.modalService.open(this.memberTermedExpiredModal, { modalDialogClass: 'memberTermedExpiredModal' });
                }
              }else{
                ////debugger;
                if (saveToDB && resp[0].trailadjudicationwebresponse.status.respCode=='200') {
                  //this.saveClaimDetails(true);
                  this.saveTrailClaimDetails();
                } else {
                  // //debugger;
                  sessionStorage.setItem("selectedMedicineList", JSON.stringify(this.claimResponseList));
                  this.loadClaimSummary();
                }
              }
            }
          });
        }
      }, (error: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorModalRef, { size: "md" });
      }).catch((error: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorModalRef, { size: "md" });
      });
  }

  tobeDeleted = "";
  removeMedication(productId?: string) {
    
    if (productId != undefined) {
      //Show confirmation modal
      this.tobeDeleted = productId;
      this.modalService.dismissAll();
      this.modalService.open(this.confirmModal, { modalDialogClass: 'confirmModal' });
    } else {
      
      this.modalService.dismissAll();
      let removeIndex: number | undefined = this.claimsummaryDetailsrequestList.findIndex((item: ClaimSummaryDetails) => {
        return item.ProductID == this.tobeDeleted;
      });

      if (this.claimsummaryDetailsrequestList[removeIndex].claimGroupId != undefined) {
        removeIndex = this.claimResponseList.findIndex((item: SelectedMedicine) => {
          if (this.tobeDeleted == item.drugSearchItem.productId && item.saveClaimResponseLstClaim.claimGroupId != undefined) {
            const webrequest: ClaimUpdateWebRequest = new ClaimUpdateWebRequest();
            //console.log("item", item);
            webrequest.claimDetailId = item.saveClaimResponseLstClaim.claimDetailId;
            webrequest.claimStsTypId = CLAIM_STATUS_TYPE.REMOVED;
            //console.log("claimDetailId", webrequest.claimDetailId);
            this.logger.log("Removing Medication: "+JSON.stringify(webrequest));
            this.claimHistoryService.updateClaimStatus(webrequest).then((response: Status) => {
              //console.log("Enetered into service");
              this.tobeDeleted = "" + item.saveClaimResponseLstClaim.claimDetailId;
              //console.log("deleted", item.saveClaimResponseLstClaim.claimDetailId);

              if (removeIndex != undefined)
                this.claimsummaryDetailsrequestList.splice(removeIndex, 1);
            });
          }
          return item.drugSearchItem.productId == this.tobeDeleted;
        });
      } else {
        if (removeIndex != undefined)
          this.claimsummaryDetailsrequestList.splice(removeIndex, 1);
      }

      if (removeIndex != undefined)
        
      this.claimResponseList.splice(removeIndex, 1);
      sessionStorage.setItem("selectedMedicineList", JSON.stringify(this.claimResponseList));
      //console.log("response", this.claimResponseList);
      //console.log("request", this.claimsummaryDetailsrequestList);
      this.tobeDeleted = "";
      this.modalService.dismissAll();
    }

  }

  goToSelectMember() {
    //console.log("Redirecting to Select Member");
    this.logger.userAction("Redirecting to Select Member")
    this.router.navigateByUrl("/coverage/member");
  }

  goToSelectMedication() {
    // //debugger;
    // this.modalService.open(this.drugConfirmModal, { modalDialogClass: 'drugConfirmModal' });
    sessionStorage.setItem('selectedMedicineList', JSON.stringify(this.claimResponseList));
    //console.log("Redirecting to Select Medication");
    this.logger.userAction("Redirecting to Select Medication")
    this.router.navigateByUrl("/coverage/drug");
  }
  cancelDOS() {
    this.updatingDOS = 0;
  }
  dismissAll() {
    this.tobeDeleted = "";
    this.modalService.dismissAll();
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  @ViewChild("confirmModal")
  confirmModal!: ElementRef<HTMLElement>;

  // @ViewChild("drugConfirmModal")
  // drugConfirmModal!: ElementRef<HTMLElement>;

  saveToDB = false;
  upadateOrReexecute(saveToDB: boolean = false) {
    //console.log("Result:" + this.dosResult);
    if (this.dosResult == DateFieldResultType.SUCCESS) {
      this.saveToDB = saveToDB;
      this.modalService.dismissAll();
      this.modalService.open(this.updateReexecuteConfirmModal, { modalDialogClass: 'updateReexecuteConfirmModal' });
    }
  }

  private getSaveClaimWebRequest(): SaveTrailClaimRequest | undefined {
    if (this.claimResponseList != undefined && Array.isArray(this.claimResponseList)) {
      const saveClaimRequest: SaveTrailClaimRequest = new SaveTrailClaimRequest();
      this.claimResponseList.forEach((item: SelectedMedicine) => {

        if(saveClaimRequest.claimGroupId==undefined)
          saveClaimRequest.claimGroupId = item.clmgroupId;

        let lstClaim: LstClaim = new LstClaim();
        lstClaim.trailClaimRequest = item.trailadjudicationwebrequest;
        lstClaim.trailClaimResponse = item.trailadjudicationwebresponse;
        lstClaim.breakableIndicator = item.drugSearchItem.breakableIndicator;
        lstClaim.claimDate = new Date().getTime().toString();
        lstClaim.claimDetailId = (item.claimDetailId == undefined) ? 0 : item.claimDetailId;
        lstClaim.dateOfService = item.trailadjudicationwebrequest.dateOfService;
        lstClaim.drgQty = parseInt(item.Quantity);
        lstClaim.gpi = item.trailadjudicationwebrequest.gpi;
        lstClaim.infsnId = parseInt(this.profileService.loginuser.selectedInfusion.getValue().infsnId);
        lstClaim.lob = environment.lob;
        lstClaim.middleName = "";
        lstClaim.packageSize = item.trailadjudicationwebrequest.qtyCalculationOption2.packageSize;
        lstClaim.presbrId = this.profileService.loginuser.prescriber.presbrId;
        lstClaim.prescriberFirstName = (item.selectedPrescriber != undefined) ? item.selectedPrescriber.firstName : item.selectedPrescriberDetail.firstName;
        lstClaim.prescriberIdQualifier = PRESCRIBER_QUALIFIER.NPI;
        lstClaim.prescriberInstanceId = (item.selectedPrescriber != undefined) ? item.selectedPrescriber.instanceId : item.selectedPrescriberDetail.instanceId;
        lstClaim.prescriberLastName = (item.selectedPrescriber != undefined) ? item.selectedPrescriber.lastName : item.selectedPrescriberDetail.lastName;
        lstClaim.prescriberNpiId = this.prescriberHelperService.findPrescriberNPI((item.selectedPrescriber != undefined) ? item.selectedPrescriber.identifiers : item.selectedPrescriberDetail.identifiers);
        lstClaim.productNameExt = item.drugSearchItem.productNameExt;
        lstClaim.productServiceId = item.drugSearchItem.productId;
        lstClaim.productServiceIdQualifier = item.drugSearchItem.productIdQlfr;
        lstClaim.drugName = item.drugSearchItem.detailedDrugName;
        lstClaim.zipCode = item.selectedMember.address.zip1;
        saveClaimRequest.lstClaims.push(lstClaim);
      });
      return JSON.parse(JSON.stringify(saveClaimRequest).replace(/’/g, "'").replace(/[/\u200B]/g, ''));;
    }
    return undefined;
  }

  private showLoadingModal(content: string): void {
    this.modalContent = content;
    this.modalService.dismissAll();
    this.modalService.open(this.loadingContent, { modalDialogClass: "content" });
  }
  private isDurSoftRejectOnPageAnswered(claim:ClaimSummaryDetails):boolean{
    let blnAllDurSoftRejectsAnswered:boolean=true;
    if(claim.durReject.isDurReject && claim.durReject.durSoftRejects.length>0){
      claim.durReject.durSoftRejects.forEach((softReject:DurSegment)=>{
        if(softReject.prfsnlSrvcCd==undefined || softReject.prfsnlSrvcCd.length==0 || softReject.rsltSrvcCd==undefined || softReject.rsltSrvcCd.length==0){
          blnAllDurSoftRejectsAnswered=false;
        }
      });
    }
    return blnAllDurSoftRejectsAnswered;
  }
  validateSaveOperation():void{
    /************************DUR Handling***************************/
    let blnRexecuteWithDurAnswers:boolean=false;
    let blnAllSoftRejectsAnswered:boolean=true;
    if(Array.isArray(this.claimsummaryDetailsrequestList) && this.claimsummaryDetailsrequestList.length>0){
      this.claimsummaryDetailsrequestList.forEach((claimOnPage:ClaimSummaryDetails)=>{
        if(claimOnPage.durReject.durSoftRejects.length!=0){
          if(this.isDurSoftRejectOnPageAnswered(claimOnPage)){
            blnRexecuteWithDurAnswers=true;
          } else {
            blnAllSoftRejectsAnswered=false;
          }
        }
      });
    }
    if(!blnAllSoftRejectsAnswered){
      //Error modal
      //Since soft rejects are identified and unanswered.
      this.modalService.dismissAll();
      this.modalService.open(this.durSoftsUnAnsweredModalRef,{size:"md"});
      return;
    } else {
      //Means, Either soft rejects are answered or not found at all.
      if(blnRexecuteWithDurAnswers){
        //Means, Soft rejects are identified and answered. Hence have to reexecute trail first and then save.
        this.reexecuteClaim(this.dosValue,true);
        return;
      }
    }
    /************************DUR Handling***************************/
    this.saveTrailClaimDetails();
  }
  saveTrailClaimDetails(): void {
    // //debugger;
    this.showLoadingModal("Saving...");
    let saveWebRequest: SaveTrailClaimRequest | undefined = this.getSaveClaimWebRequest();
    this.saveErrorText = "";
    if (saveWebRequest != undefined) {
      this.logger.userAction("Saving Trail Claim Details");
      this.logger.log("Saving Trail Claim Details: "+ JSON.stringify(saveWebRequest));
      this.trailclaimadjudicationHelper.saveMultipleTrailClaims(saveWebRequest).then((response: SaveTrailClaimResponse) => {
        // //debugger;
        if (response != undefined && response.status != undefined && response.status.statusCode != undefined
          && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT) {
            // //debugger;
          switch (response.status.dbStatusCode) {
            case APPCONSTANTS.API_RESPONSE_DB_SUCCESS_CODE:
              let blnSaved: boolean = true;
              this.claimResponseList.forEach((item: SelectedMedicine, i: number) => {
                let responseSavedClaim: Response = response.responses.filter((data) => data.productServiceId == item.drugSearchItem.productId)[0];
                if (responseSavedClaim.status == undefined || responseSavedClaim.status.statusCode == undefined
                  || responseSavedClaim.status.statusCode.toLowerCase() != APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
                  || responseSavedClaim.status.dbStatusCode != APPCONSTANTS.API_RESPONSE_DB_SUCCESS_CODE
                  || responseSavedClaim.claimDetailId == undefined) {
                    // //debugger;
                  blnSaved = false;
                  //Capture just status of current save transaction in order to prevent any next processing which depends on save transaction status.
                  if(this.claimResponseList[i].saveClaimResponseLstClaim != undefined){
                    this.claimResponseList[i].blnSavedAfterTrail=false;
                  }
                }
                else {
                  responseSavedClaim.claimDate = saveWebRequest!.lstClaims[0].claimDate;
                  this.claimResponseList[i].saveClaimResponseLstClaim = responseSavedClaim;
                  this.claimResponseList[i].clmgroupId=responseSavedClaim.claimGroupId;
                  this.claimResponseList[i].claimDetailId=responseSavedClaim.claimDetailId;
                  this.claimResponseList[i].blnSavedAfterTrail=true;
                }
              });
              if (blnSaved) {
                // //debugger;
                this.modalService.dismissAll();
                // this.modalService.open(this.saveClaimConfirmModal, { modalDialogClass: 'saveClaimConfirmModal' });
                // //debugger;
                sessionStorage.setItem('selectedMedicineList', JSON.stringify(this.claimResponseList));
                this.loadClaimSummary();
              } else {
                this.modalService.dismissAll();
                // this.modalService.open(this.saveErrorModalRef, { size: "md" });
              }
              break;
            case APPCONSTANTS.API_RESPONSE_DB_ALREADY_EXIST_SAVEDCLAIM:
              this.claimResponseList.forEach((item: SelectedMedicine, i: number) => {
                let responseSavedClaim: Response = response.responses.filter((data) => data.productServiceId == item.drugSearchItem.productId)[0];
                //console.log("found");
                //console.log(responseSavedClaim);
                this.logger.log("Duplicate claim found in DB.");
                item.claimDetailId = responseSavedClaim.claimDetailId;
                item.clmgroupId = responseSavedClaim.claimGroupId;
              });
              this.modalService.dismissAll();
              this.modalService.open(this.overwriteConfirmModal, { modalDialogClass: 'overwriteConfirmModal' });
              break;
            case APPCONSTANTS.API_RESPONSE_DB_SAVEDCLAIM_INFUSION_CHANGED:
              this.logger.log("Your infusion facility got changed while saveing the Claim in DB.");
              this.saveErrorText = "Your infusion facility got changed. Please select appropriate one.";
              break;
            default:
              this.modalService.dismissAll();
              this.modalService.open(this.saveErrorModalRef, { size: "md" });
          }
        } else {
          this.modalService.dismissAll();
          this.modalService.open(this.saveErrorModalRef, { size: "md" });
        }
      }, (error: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.saveErrorModalRef, { size: "md" });
      }).catch((error: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.saveErrorModalRef, { size: "md" });
      });
    } else {
      this.modalService.dismissAll();
      this.modalService.open(this.saveErrorModalRef, { size: "md" });
    }
  }

  submitClaims(memberTermed: boolean = false): any {
    
    this.logger.userAction("Submitting Claims");
    if (this.claimsummaryDetailsrequest.dateOfLastTrail != undefined) {

      var lastTrailDate: Date = new Date(this.claimsummaryDetailsrequest.dateOfLastTrail);
      var todayDate: Date = new Date();

      if (memberTermed) {
        this.claimsummaryDetailsrequest.dateOfLastTrail = this.servertimetostandarddate.transform(""+new Date());
      } else {
        if (lastTrailDate.getDate() != todayDate.getDate() || lastTrailDate.getMonth() != todayDate.getMonth() || lastTrailDate.getFullYear() != todayDate.getFullYear()) {
          this.modalService.dismissAll();
          this.modalService.open(this.submitClaimOnOldTrailErrolModalRef, { size: "md" });
          return;
        }
      }


      if (this.claimsummaryDetailsrequestList.filter((data) => data.bannerType == BANNER_TYPE.RED || data.bannerType == BANNER_TYPE.YELLOW).length > 0) {
        //console.log("All claims not payble");
        this.logger.log("Submitting Claims, All claims not payble");
        this.modalService.dismissAll();
        this.modalService.open(this.submitClaimNotAllowedModal, { modalDialogClass: 'submitClaimNotAllowedModal' });
      } else {
        //console.log("All claims payble");
        // //debugger;
        this.showLoadingModal("Submitting Claims...");
        const saveClaimRequest: SaveTrailClaimRequest | undefined = this.getSaveClaimWebRequest();
        if (saveClaimRequest != undefined) {
          this.logger.log("Submitting Claims, All claims payble");
          let submitClaimRequestList: SubmitMultipleClaimRequest = this.trailclaimadjudicationHelper.setMultipleclaimSubmit(saveClaimRequest);
          this.trailclaimadjudicationHelper.submitMultipleClaims(submitClaimRequestList).then((response: SubmitMultipleClaimResponse) => {
            //console.log(response);
            // //debugger;
            this.modalService.dismissAll();
            if (response != undefined && response.status != undefined && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
              && response.status.dbStatusCode != undefined && response.status.dbStatusCode == APPCONSTANTS.API_RESPONSE_DB_SUCCESS_CODE
              && Array.isArray(response.responses)) {
              let blnAllSubmitted: boolean = true;
              for (let i = 0; i < this.claimResponseList.length; i++) {
                let submitResponseLst: SubmitClaimResponseDetail[] = response.responses.filter((data) => data.claimDtlId == this.claimResponseList[i].saveClaimResponseLstClaim.claimDetailId);
                if (Array.isArray(submitResponseLst) && submitResponseLst.length > 0) {
                  this.claimResponseList[i].submitClaimResponseLstClaim = submitResponseLst[0];
                  if (this.claimResponseList[i].submitClaimResponseLstClaim?.status == undefined || this.claimResponseList[i].submitClaimResponseLstClaim?.status.statusCode == undefined
                    || this.claimResponseList[i].submitClaimResponseLstClaim?.status.statusCode.toLowerCase() != APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
                    || this.claimResponseList[i].submitClaimResponseLstClaim?.status.dbStatusCode == undefined || this.claimResponseList[i].submitClaimResponseLstClaim?.status.dbStatusCode.toLowerCase() != APPCONSTANTS.API_RESPONSE_DB_SUCCESS_CODE
                    || this.claimResponseList[i].submitClaimResponseLstClaim?.claimStsTypId != CLAIM_STATUS_TYPE.SUBMITTED_PAID) {
                    blnAllSubmitted = false;
                  } else {
                    const lstClaim: LstClaim[] = saveClaimRequest.lstClaims.filter((claim: LstClaim) => {
                      return claim.claimDetailId == submitResponseLst[0].claimDtlId;
                    });
                    if (lstClaim != undefined && Array.isArray(lstClaim) && lstClaim.length > 0) {
                      this.claimResponseList[i].saveClaimResponseLstClaim.claimDate = lstClaim[0].claimDate;
                      this.claimResponseList[i].saveClaimResponseLstClaim.claimStsTypId = submitResponseLst[0].claimStsTypId;
                    }
                  }
                } else {
                  blnAllSubmitted = false;
                  this.claimResponseList[i].submitClaimResponseLstClaim = {
                    claimStsTypId: CLAIM_STATUS_TYPE.SUBMITTED_REJECTED,
                    claimDtlId: this.claimResponseList[i].claimDetailId,
                    status: JSON.parse(JSON.stringify(response.status))
                  };
                }
              }
              if (blnAllSubmitted && response.responses.length > 0) {
                this.modalService.open(this.submitClaimSuccessModal, { modalDialogClass: 'submitClaimSuccessModal' });
              }
              sessionStorage.setItem('selectedMedicineList', JSON.stringify(this.claimResponseList));
              this.loadClaimSummary();
            } else {
              this.blntermedMember = false;
              this.logger.log("Submit Claims Failed: "+JSON.stringify(response));
              this.submitClaimErrorText = response.status.dbStatusDesc || response.status.statusDesc || "An error occurred. Please try again.";
              this.modalService.open(this.submitClaimErrorModal, { size: "md" });
            }
          }, (error: any) => {
            this.modalService.dismissAll();
            this.modalService.open(this.errorModalRef, { size: "md" });
          }).catch((error: any) => {
            this.modalService.dismissAll();
            this.modalService.open(this.errorModalRef, { size: "md" });
          });
        }
      }

    }
  }


  claimReversalProductId = "";
  reversClaimRejectmessage = "";
  reverseClaimsConfrmModel(productId: any) {
    this.claimReversalProductId = productId;
    //console.log(this.claimReversalProductId);
    this.modalService.dismissAll();
    this.modalService.open(this.reverseClaimConfirmModal, { modalDialogClass: 'reverseClaimConfirmModal' });
  }

  reverseClaim() {
    this.logger.userAction("Revering Claim");
    this.reverseErrorText='';
    const saveClaimRequest: SaveTrailClaimRequest | undefined = this.getSaveClaimWebRequest();
    if (saveClaimRequest != undefined) {
      let submitClaimRequestList: SubmitMultipleClaimRequest = this.trailclaimadjudicationHelper.setMultipleclaimSubmit(saveClaimRequest, true);
      if (submitClaimRequestList != undefined && Array.isArray(submitClaimRequestList.requests) && submitClaimRequestList.requests.length > 0) {

        const claimSubmitRequest: SubmitClaimRequest | undefined = submitClaimRequestList.requests.find((request: SubmitClaimRequest) => {
          return request.productServiceId == this.claimReversalProductId;
        });

        const claimResponseItem: SelectedMedicine | undefined = this.claimResponseList.find((item: SelectedMedicine) => {
          return item.trailadjudicationwebrequest.productServiceId == this.claimReversalProductId;
        });

        if (claimSubmitRequest != undefined && claimResponseItem != undefined && claimResponseItem.submitClaimResponseLstClaim != undefined) {
          const reverClaimRequest: ReverseClaimRequest = claimSubmitRequest;
          reverClaimRequest.rxServiceReferenceNo = claimResponseItem.submitClaimResponseLstClaim?.submitClaimResponse?.realClaimResponse.rxServiceReferenceNo;
          this.showLoadingModal("Reversing Claim...");
          this.logger.log("Revering Claim: "+ JSON.stringify(reverClaimRequest));
          this.trailclaimadjudicationHelper.reverseMultipleClaim(reverClaimRequest).then((response: ReverseClaimResponse) => {
            ////debugger;
            if (response) {
              this.modalService.dismissAll();
              if (response.status != undefined && response.status.statusCode != undefined && response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
                && response.claimReverseResponse != undefined && response.claimReverseResponse.status != undefined && response.claimReverseResponse.status.statusCode != undefined && response.claimReverseResponse.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
                && response.claimReverseResponse.realClaimReversalResponse != undefined && response.claimStsTypId == CLAIM_STATUS_TYPE.REVERSED_PAID) {
                //console.log(response);
                //console.log("Reversal Accepted");
                claimResponseItem.reverseClaimResponseLstClaim = response;
                claimResponseItem.saveClaimResponseLstClaim.claimStsTypId = response.claimStsTypId;
                claimResponseItem.saveClaimResponseLstClaim.claimDate = claimSubmitRequest.claimDt;
                this.modalService.dismissAll();
                this.modalService.open(this.reverseClaimSuccessModal, { modalDialogClass: 'reverseClaimSuccessModal' });
                sessionStorage.setItem('selectedMedicineList', JSON.stringify(this.claimResponseList));
                this.loadClaimSummary();
              } else {
                //console.log("Reversal Rejected");
                this.logger.log("Reversing Claim Rejected: "+ JSON.stringify(response));
                //this.claimResponseList[i].reverseClaimRequest = reverClaimRequest;
                claimResponseItem.reverseClaimResponseLstClaim = response;
                claimResponseItem.saveClaimResponseLstClaim.claimStsTypId = response.claimStsTypId;

                if (response.claimReverseResponse != undefined && response.claimReverseResponse.realClaimReversalResponse != undefined
                  && Array.isArray(response.claimReverseResponse.realClaimReversalResponse.rejectCodes) && response.claimReverseResponse.realClaimReversalResponse.rejectCodes.length > 0) {
                  if (response.claimReverseResponse.realClaimReversalResponse.rejectCodes.find((item: RejectCodes) => item.rejectCode == "87") != undefined) {
                    this.reversClaimRejectmessage = "The claim reversal was unsuccessful. Please contact customer service at 1-866-842-3278 or try to reverse the claim again.";
                  } else {
                    this.reversClaimRejectmessage = response.claimReverseResponse.realClaimReversalResponse.message || "The claim reversal was unsuccessful.";
                  }
                } else {
                  this.reversClaimRejectmessage = response.claimReverseResponse.realClaimReversalResponse.message || "The claim reversal was unsuccessful.";
                }
                this.modalService.dismissAll();
                this.modalService.open(this.reverseClaimRejected, { modalDialogClass: 'reverseClaimRejected' });
                sessionStorage.setItem('selectedMedicineList', JSON.stringify(this.claimResponseList));
                this.loadClaimSummary();
              }
            }
          }, (error: any) => {
            ////debugger;
            this.modalService.dismissAll();
            this.modalService.open(this.reverseErrorModalRef, { size: "md" });
          }).catch((error: any) => {
            ////debugger;
            this.modalService.dismissAll();
            this.modalService.open(this.reverseErrorModalRef, { size: "md" });
          });
        }
      }
    }
    this.claimReversalProductId = "";
  }
}
