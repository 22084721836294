import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Trailadjudicationwebrequest, QtyCalculationOption1, QtyCalculationOption2, DurSegment } from 'src/app/modals/drugSearch/Trailadjudicationwebrequest';
import { Trailadjudicationwebresponse, ScenarioClaim, MultipleTrailadjudicationWebResponse } from 'src/app/modals/drugSearch/Trailadjudicationwebresponse';
import { environment } from 'src/environments/environment';
import { APPCONSTANTS, PRESCRIBER_QUALIFIER, DRUG_QUALIFIER_LABEL, DRUG_QUALIFIER, DUR_LABEL, PRESCRIBER_QUALIFIER_LABEL, DUR_LABELTO_VALUE, USER_ROLE_Label, USER_ROLE, CLAIM_STATUS_TYPE } from 'src/app/constants/appconstant';
import { MemberDetailWebRequesthemi } from 'src/app/modals/membersearch/memberdetailwebrequest';
//import { MemberDetailWebResponse } from 'src/app/modals/membersearch/memberdetailwebresponse';
import { DiagnosisCodeSearchItem, DrugSearchItem, SelectedMedicine, Drugsearchwebresponse } from 'src/app/modals/drugSearch/drugsearchwebresponse';
import { ClaimTransactionHistoryResponse, LstClaimHistory } from "src/app/modals/claimhistory/claimhistorywebresponse";
import { ProfileService } from '../profile/profile.service';
import { PrescriberSearchService } from 'src/app/services/prescriberSearch/prescriber-search.service';
import { Observable } from 'rxjs/internal/Observable';
import { LstClaim, SaveTrailClaimRequest } from 'src/app/modals/drugSearch/SaveTrailClaimRequest';
import { SaveTrailClaimResponse, Response } from 'src/app/modals/drugSearch/SaveTrailClaimResponse';
import { SubmitClaimRequest, SubmitMultipleClaimRequest, DURSegmentClaimSubmit } from 'src/app/modals/drugSearch/submitMultipleClaimRequest';
import { SubmitMultipleClaimResponse, SubmitClaimResponseDetail, RealClaimResponse, SubmitClaimResponse, ResponsePricing } from 'src/app/modals/drugSearch/submitMultipleClaimResponse';
import { ClaimReverseResponse, RealClaimReversalResponse, ReverseClaimResponse } from 'src/app/modals/drugSearch/reverseclaimResponse';
import { ReverseClaimRequest } from 'src/app/modals/drugSearch/reverseclaimRequest';
import { MemberSearchItem, Drugsearchwebrequest } from 'src/app/modals/drugSearch/drugsearchwebrequest';
import { StandardDateToServertime, TimeStampToStandardDate } from 'src/app/pipes/dateconversion/dateconversion.pipe';
import { DrugsearchService } from 'src/app/services/drugSearch/drugsearch.service';
import { CalculateQuantityService, QtyCalculated } from 'src/app/services/Quantity/calculate-quantity.service';
import { PrescriberSearchCriteria } from 'src/app/modals/prescriberSearch/prescriberviewmodal';
import { PrescriberSearchRootObject, PrescriberDetailWebResponse, PrescriberSearchItem } from 'src/app/modals/prescriberSearch/prescriberSearchwebresponse';
import { PrescriberDetailWebRequest, PrescriberSearchWebRequest } from 'src/app/modals/prescriberSearch/prescriberSearchweberequest';
import { Membersearchwebrequest } from 'src/app/modals/membersearch/membersearchwebrequest';
import { MembersearchService } from '../membersearch/membersearch.service';
import { FavouriteMember, Membersearchwebresponse } from 'src/app/modals/membersearch/membersearchwebresponse';
import { LoggerService } from '../logger/logger.service';
import { MemberDetailWebResponsehemi } from 'src/app/modals/membersearch/memberdetailresponsehemi';

@Injectable({
  providedIn: 'root'
})
export class CheckCoveragePageService {

  selectedMedicine: SelectedMedicine[] = [];
  selectedDrugItems: DrugSearchItem[] = [];
  currentSelectedDrug!: DrugSearchItem[];
  drugQuestions: string = 'N';
  constructor(private http: HttpClient, private profileService: ProfileService,
    private prescriberSearch: PrescriberSearchService,
    private timeStampToStandardDate: TimeStampToStandardDate,
    private drugSearchHelper: DrugsearchService,
    private calculateQtyService: CalculateQuantityService,
    private standardDateToServertime: StandardDateToServertime,
    private memberSearchHelper: MembersearchService, private logger: LoggerService) { }


  // Trail adjudication Details Service for Single Trail
  getTrailClaimAdjudicationDetails(webrequest: Trailadjudicationwebrequest): Promise<Trailadjudicationwebresponse> {
    const promise: Promise<Trailadjudicationwebresponse> = new Promise<Trailadjudicationwebresponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.getTrailClaimAdjudicationDetailsURL});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        if (response.status != undefined
          && response.status.statusCode != undefined
          && (response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT ||
            response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_WARNING_TXT ||
            response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_INFO_TXT)
        ) {
          //console.log("ApI Call");
          //console.log(response);
          resolve(response);
        } else {
          //console.log("ApI Reject");
          this.logger.log(response);
          reject(response);
        }
      }, (error: any) => {
        //console.log("ApI Error");
        this.logger.error(error);
          reject(error);
      });
    });
    return promise;
  }


  // Trail adjudication Details Service for Multiple Trail
  getMultipleTrailClaimAdjudicationDetails(webrequest: Trailadjudicationwebrequest[]): Promise<MultipleTrailadjudicationWebResponse> {
    const promise: Promise<MultipleTrailadjudicationWebResponse> = new Promise<MultipleTrailadjudicationWebResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.getMultipleTrailClaimAdjudicationDetailsURL});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
      //this.http.get("./assets/mockresponses/durClaimResponse.json").subscribe((response: any) => {
        if (response != undefined) {
          resolve(response);
        } else {
          reject(response)
        }
      }, (error: any) => {
        //console.log("ApI Error");
        this.logger.error(error);
          reject(error);
      });
    });
    return promise;
  }


 /* getMemberDetails(webrequest: MemberDetailwebrequest): Promise<MemberDetailWebResponse> {
    const promise: Promise<MemberDetailWebResponse> = new Promise<MemberDetailWebResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.getMemberDetailsUrl});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        if (response.status != undefined
          && response.status.statusCode != undefined
          && (response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT ||
            response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_WARNING_TXT ||
            response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_INFO_TXT)
        ) {
          resolve(response);
        } else {
          this.logger.log(response);
          reject(response);
        }
      }, (error: any) => {
        this.logger.error(error);
          reject(error);
      });
    });
    return promise;
  }*/
  getMemberDetailshemi(webrequest: MemberDetailWebRequesthemi): Promise<MemberDetailWebResponsehemi> {
    const promise: Promise<MemberDetailWebResponsehemi> = new Promise<MemberDetailWebResponsehemi>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.getMemberDetailsUrl});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        if (response.status != undefined
          && response.status.statusCode != undefined
          && (response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT ||
            response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_WARNING_TXT ||
            response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_INFO_TXT)
        ) {
          resolve(response);
        } else {
          this.logger.log(response);
          reject(response);
        }
      }, (error: any) => {
        this.logger.error(error);
          reject(error);
      });
    });
    return promise;
  }

  private getTrailAdjudicationWebRequest(item: SelectedMedicine): Trailadjudicationwebrequest {

    let request: Trailadjudicationwebrequest = new Trailadjudicationwebrequest();
    let qtyCalculationOption1Object: QtyCalculationOption1 = new QtyCalculationOption1();
    let qtyCalculationOption2Object: QtyCalculationOption2 = new QtyCalculationOption2();
    let additionalFieldArr2: any[] = [];
    let diagnosistlistArr: DiagnosisCodeSearchItem[] = [];

    /*******************DUR Handling***************** */
    ////debugger;
    // let durgSegObj = new DurSegment();
    // durgSegObj.prfsnlSrvcCd=APPCONSTANTS.RTB_DUR_PRFSNLSRVCCD_NONSOFTREJECT;
    // durgSegObj.response=APPCONSTANTS.RTB_DUR_RESPONSE_NONSOFTREJECT;
    // if(item.DrugAdmin!=undefined && item.DrugAdmin.length==2)
    //   durgSegObj.levelOfEffort=item.DrugAdmin;
    // else{
    //   const durLOE:string|undefined= DUR_LABELTO_VALUE.get(item.DrugAdmin);
    //   if(durLOE!=undefined)
    //     durgSegObj.levelOfEffort = durLOE;
    // }
    // let dursegmentObject: DurSegment[] = [];
    // dursegmentObject.push(durgSegObj);
    // request.durSegment = dursegmentObject;
    request.durSegment =[];
    // if(item.durReject!=undefined && Array.isArray(item.durReject.durAllRejects) && item.durReject.durAllRejects.length>0){
    //     item.durReject.durAllRejects.forEach((durFilled:DurSegment)=>{
    //       if((durFilled.durMsgId!=undefined && durFilled.durMsgId.toString().length>0) || (durFilled.prfsnlSrvcCd!=undefined && durFilled.prfsnlSrvcCd.length>0 
    //         && durFilled.rsltSrvcCd!=undefined && durFilled.rsltSrvcCd.length>0)){
    //           request.durSegment.push(JSON.parse(JSON.stringify(durFilled)));
    //         }
    //     });
    // }
    if(item.durReject!=undefined && Array.isArray(item.durReject.durInvalidSoftRejects) && Array.isArray(item.durReject.durSoftRejects)){
      const durRejectsToGether:DurSegment[] = item.durReject.durInvalidSoftRejects.concat(item.durReject.durSoftRejects);
      durRejectsToGether.forEach((durFilled:DurSegment)=>{
      if((durFilled.durMsgId!=undefined && durFilled.durMsgId.toString().length>0) || (durFilled.prfsnlSrvcCd!=undefined && durFilled.prfsnlSrvcCd.length>0 
        && durFilled.rsltSrvcCd!=undefined && durFilled.rsltSrvcCd.length>0)){
          request.durSegment.push(JSON.parse(JSON.stringify(durFilled)));
        }
      });
    }
    /*******************DUR Handling***************** */

    request.userId = "" + this.profileService.loginuser.ohid;
    if (item.memberDetailResponse != undefined && item.memberDetailResponse.memberItems != undefined) {
      request.zipCode = item.memberDetailResponse.memberItems[0].contact.zip;
      request.cardholderId = item.memberDetailResponse.memberItems[0].memberId;
      request.personCode = item.memberDetailResponse.memberItems[0].personCode;
      request.relationshipCode = item.memberDetailResponse.memberItems[0].relationshipCode;
      //Group ID mapping not happening in old portal for trail adjudication.
      //request.groupId = item.memberDetailResponse.memberItems[0].groupId;
      request.firstName = item.memberDetailResponse.memberItems[0].firstName;
      request.lastName = item.memberDetailResponse.memberItems[0].lastName;
      if(item.memberDetailResponse.memberItems[0].demographics.dateOfBirth.indexOf('/')!=-1){
        request.dateOfBirth = this.standardDateToServertime.transform(item.memberDetailResponse.memberItems[0].demographics.dateOfBirth);
      }else{
        request.dateOfBirth = item.memberDetailResponse.memberItems[0].demographics.dateOfBirth;
      }
      request.dateOfBirth = item.memberDetailResponse.memberItems[0].demographics.dateOfBirth;
      request.gender = item.memberDetailResponse.memberItems[0].demographics.gender;
      request.instanceId = item.memberDetailResponse.memberItems[0].sourceSystemInstance;
      request.cagAccountId = item.memberDetailResponse.memberItems[0].accountId;
      request.cagCarrierId = item.memberDetailResponse.memberItems[0].carrierId;
      request.cagGroupId = item.memberDetailResponse.memberItems[0].groupId;
      request.gender = item.memberDetailResponse.memberItems[0].demographics.gender;

    } else {
      request.zipCode = item.selectedMember.address.zip1;
      request.cardholderId = item.selectedMember.memberId;
      request.personCode = item.selectedMember.personCode;
      request.relationshipCode = item.selectedMember.relationshipCode;
      request.groupId = item.selectedMember.groupId;
      request.firstName = item.selectedMember.firstName;
      request.lastName = item.selectedMember.lastName;
      if(item.selectedMember.dateOfBirth.indexOf('/')!=-1){
        request.dateOfBirth = this.standardDateToServertime.transform(item.selectedMember.dateOfBirth);
      }else{
        request.dateOfBirth = item.selectedMember.dateOfBirth;
      }
      request.gender = item.selectedMember.gender;
      request.instanceId = item.selectedMember.instanceId;
      request.cagCarrierId = item.selectedMember.carrierId;
      request.cagAccountId = item.selectedMember.accountId;
      request.cagGroupId = item.selectedMember.groupId;
    }

    request.rxServiceReferenceQualifier = null;
    request.rxServiceReferenceNo = null;
    request.serviceProviderIdQualifier = PRESCRIBER_QUALIFIER.NPI;// "01"
    request.serviceProviderId = this.profileService.loginuser.selectedInfusion.getValue().pharmacyNPI;
    request.productServiceIdQualifier = item.drugSearchItem.productIdQlfr;
    request.productServiceId = item.drugSearchItem.productId;
    request.drugName = item.drugSearchItem.detailedDrugName.substring(0, 49);
    request.prescriberIdQualifier = PRESCRIBER_QUALIFIER.NPI;
    // //console.log("prscriberqual"+this.selectedPrescriber.prescriberIdQualifier);
    if (item.selectedPrescriberDetail != undefined)
      request.prescriberId = this.prescriberSearch.findPrescriberNPI(item.selectedPrescriberDetail.identifiers);
    else
      request.prescriberId = this.prescriberSearch.getSelectedPrescriberNPI(item.selectedPrescriber);

    //console.log("presif", +request.prescriberId);
    request.daysSupply = item.Days;
    request.qtyCalculationOption1 = qtyCalculationOption1Object;
    request.qtyCalculationOption1.quantityDispensed = item.quantytyCalculate;
    request.qtyCalculationOption2 = qtyCalculationOption2Object;
    request.dateOfService = "" + Date.parse(item.dateOfservice);
    request.datePrescriptionWritten = "" + Date.parse(item.dateOfservice);
    if (this.drugQuestions == 'Y') {
      request.patientResidence = APPCONSTANTS.RTB_HIGHTRUSTQUESTIONS;
    }
    request.placeOfService = item.placeOfservice;

    request.uuid = "" + this.profileService.loginuser.UUID;
    request.drugName = item.drugSearchItem.detailedDrugName;
    request.gpi = item.drugSearchItem.gpi;

    if (item.diagnosisCodeSearchItem != undefined) {
      request.diagnosisCode = (item.diagnosisCodeSearchItem.diagnosisCode != undefined) ? item.diagnosisCodeSearchItem.diagnosisCode.trim() : item.diagnosisCodeSearchItem.diagnosisCode;
      request.diagnosisCodeDescription = (item.diagnosisCodeSearchItem.diagnosisDescription != undefined) ? item.diagnosisCodeSearchItem.diagnosisDescription.trim() : item.diagnosisCodeSearchItem.diagnosisDescription;
      request.diagnosisCodeQualifier = (item.diagnosisCodeSearchItem.diagnosisQualifier != undefined) ? item.diagnosisCodeSearchItem.diagnosisQualifier.trim() : item.diagnosisCodeSearchItem.diagnosisQualifier;
    }
    request.userIdType = this.getUserIdType();
    return request
  }

  private getUserIdType(): string {
    if (parseInt(this.profileService.loginuser.selectedInfusion.getValue().roleTypeId) == USER_ROLE.SMAPADMIN)
      return APPCONSTANTS.RTB_SMAPADMIN_USER;
    else {
      if (parseInt(this.profileService.loginuser.selectedInfusion.getValue().roleTypeId) == USER_ROLE.SMAPPRESCRIBER)
        return APPCONSTANTS.RTB_SMAPPRESCRIBER_USER;
      else
        return this.profileService.loginuser.selectedInfusion.getValue().roleTypeId;
    }
  }

  private startMappingTrailResponse(items:SelectedMedicine[], response: MultipleTrailadjudicationWebResponse, medicineIndex:number):Promise<SelectedMedicine[]>{
    const promise: Promise<SelectedMedicine[]> = new Promise<SelectedMedicine[]>((resolve,reject)=>{
      if(medicineIndex<items.length){
          const i:number = medicineIndex;
          if (response.responses[i].status != undefined
            && response.responses[i].status.statusCode != undefined
            && (response.responses[i].status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT ||
              response.responses[i].status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_WARNING_TXT ||
              response.responses[i].status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_INFO_TXT)
            && (response.responses[i].status.respCode == undefined || response.responses[i].status.respCode == APPCONSTANTS.API_RESPONSE_SUCCESS_CODE)
          ) {
            items[i].trailadjudicationwebresponse = response.responses[i];
          } else {
            if (items[i].trailadjudicationwebresponse != undefined)
              items[i].trailadjudicationwebresponse.status = response.responses[i].status;
            else
              items[i].trailadjudicationwebresponse = response.responses[i];
          }
  
          items[i].submitClaimResponseLstClaim = undefined;
          items[i].reverseClaimResponseLstClaim = undefined;

          if(items[i].trailadjudicationwebresponse.prefAlternativesWebResponse!=undefined && Array.isArray(items[i].trailadjudicationwebresponse.prefAlternativesWebResponse.scenarioClaims) && items[i].trailadjudicationwebresponse.prefAlternativesWebResponse.scenarioClaims.length>0
            && items[i].trailadjudicationwebrequest.productServiceId!=items[i].trailadjudicationwebresponse.prefAlternativesWebResponse.scenarioClaims[0].responseClaimSegment.productOrServiceId
            && (items[i].drugSearchItemOnTrail==undefined || items[i].drugSearchItemOnTrail.productId!=items[i].trailadjudicationwebresponse.prefAlternativesWebResponse.scenarioClaims[0].responseClaimSegment.productOrServiceId)){
              let request: Drugsearchwebrequest = new Drugsearchwebrequest();
              request.ndc = items[i].trailadjudicationwebresponse.prefAlternativesWebResponse.scenarioClaims[0].responseClaimSegment.productOrServiceId;
              request.instanceId = environment.instanceId;
              this.drugSearchHelper.getDrugSearchItems(request).then((drgresponse: Drugsearchwebresponse) => {
                if(Array.isArray(drgresponse.response.drugSearchItems) && drgresponse.response.drugSearchItems.length>0){
                  items[i].drugSearchItemOnTrail = drgresponse.response.drugSearchItems[0];
                }
                this.startMappingTrailResponse(items, response,i+1).then((mpResponse:SelectedMedicine[])=>{
                  resolve(mpResponse);
                },(error:any)=>{
                  reject(error);
                });
              });
          } else {
            this.startMappingTrailResponse(items, response,i+1).then((mpResponse:SelectedMedicine[])=>{
              resolve(mpResponse);
            },(error:any)=>{
              reject(error);
            });
          }
        } else {
          resolve(items);
        }
    });
    return promise;
  }

  checkCoverage(items: SelectedMedicine[]): Promise<SelectedMedicine[]> {
    const promise: Promise<SelectedMedicine[]> = new Promise<SelectedMedicine[]>((resolve, reject) => {
      let requests: Trailadjudicationwebrequest[] = [];
      items.forEach((item: SelectedMedicine) => {
        const request: Trailadjudicationwebrequest = this.getTrailAdjudicationWebRequest(item);
        requests.push(request);
        item.trailadjudicationwebrequest = request;
      });

      this.getMultipleTrailClaimAdjudicationDetails(requests).then((response: MultipleTrailadjudicationWebResponse) => {
        //console.log("GOTO");
        //console.log(response);
        if (response != undefined && Array.isArray(response.responses) && response.responses.length > 0) {
          this.startMappingTrailResponse(items,response,0).then((mapResponse:SelectedMedicine[])=>{
            // console.log("GOTO");
            // console.log(items);
            resolve(items);
          },(error:any)=>{
            reject(error);
          });
        } else {
          this.logger.log(JSON.stringify(response));
          reject(response);
        }
      }, (error: any) => {
        this.logger.error(error);
          reject(error);
      }).catch((error: any) => {
        this.logger.error(error);
          reject(error);
      })
    });
    return promise;
  }


  saveMultipleTrailClaims(webrequest: SaveTrailClaimRequest): Promise<SaveTrailClaimResponse> {
    const promise: Promise<SaveTrailClaimResponse> = new Promise<SaveTrailClaimResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.getSaveMultipleTrailDetailUrl});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        if (response.status != undefined
          && response.status.statusCode != undefined
          && (response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT ||
            response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_WARNING_TXT ||
            response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_INFO_TXT)
        ) {
          resolve(response);
        } else {
          this.logger.log(response);
          reject(response);
        }
      }, (error: any) => {
        this.logger.error(error);
          reject(error);
      });
    });
    return promise;
  }

  submitMultipleClaims(webrequest: SubmitMultipleClaimRequest): Promise<SubmitMultipleClaimResponse> {
    const promise: Promise<SubmitMultipleClaimResponse> = new Promise<SubmitMultipleClaimResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.getSubmitMultipleSmapClaimUrl});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        if (response.status != undefined
          && response.status.statusCode != undefined
          && (response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT ||
            response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_WARNING_TXT ||
            response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_INFO_TXT)
        ) {
          resolve(response);
        } else {
          this.logger.log(response);
          reject(response);
        }
      }, (error: any) => {
        this.logger.error(error);
          reject(error);
      });
    });
    return promise;
  }

  setMultipleclaimSubmit(items: SaveTrailClaimRequest, blnReverseClaim: boolean = false): SubmitMultipleClaimRequest {
    // //debugger;
    let submitClaimList: SubmitMultipleClaimRequest = new SubmitMultipleClaimRequest();
    let requestList: SubmitClaimRequest[] = [];
    let lastClaims = items.lstClaims;
    lastClaims.forEach((item: LstClaim) => {
      
      let claim = new SubmitClaimRequest();
      claim.transactionCode = (!blnReverseClaim) ? APPCONSTANTS.RTB_SUBMITCLAIM_TRANSACTION_CODE : APPCONSTANTS.RTB_REVERSECLAIM_TRANSACTIOn_CODE;
      claim.transactionType = APPCONSTANTS.RTB_SUBMITCLAIM_TRANSACTION_TYPE;
      claim.claimDetailId = item.claimDetailId;
      claim.claimDt = item.claimDate;
      claim.userId = this.profileService.loginuser.ohid; //item.trailClaimRequest.userId;
      claim.userIdType = this.getUserIdType();
      claim.uuid = this.profileService.loginuser.UUID; //item.trailClaimRequest.uuid;

      if (item.trailClaimResponse != undefined) {
        claim.bin = item.trailClaimResponse.response.bin;
        claim.groupId = item.trailClaimResponse.response.groupId;
        claim.instanceId = item.trailClaimResponse.invocationContext.instanceId;
        claim.pcn = item.trailClaimResponse.response.pcn;
      }

      if (item.trailClaimRequest != undefined) {
        claim.cagAccountId = item.trailClaimRequest.cagAccountId;
        claim.cagCarrierId = item.trailClaimRequest.cagCarrierId;
        claim.cagGroupId = item.trailClaimRequest.cagGroupId;
        claim.cardholderId = item.trailClaimRequest.cardholderId;
        claim.compound = item.trailClaimRequest.compound;
        claim.consumingApp = item.trailClaimRequest.consumingApp;
        claim.dateOfBirth = item.trailClaimRequest.dateOfBirth;
        claim.dateOfService = item.trailClaimRequest.dateOfService;
        claim.datePrescriptionWritten = item.trailClaimRequest.datePrescriptionWritten;
        claim.dispenseAsWrittenCode = item.trailClaimRequest.dispenseAsWrittenCode;
        claim.drugName = item.trailClaimRequest.drugName;
        claim.durPharmacyProfessionalService = item.trailClaimRequest.durPharmacyProfessionalService;
        claim.fillNumber = item.trailClaimRequest.fillNumber;
        claim.firstName = item.trailClaimRequest.firstName;
        claim.lastName = item.trailClaimRequest.lastName;
        claim.gender = item.trailClaimRequest.gender;
        claim.zipCode = item.trailClaimRequest.zipCode;
        claim.gpi = item.trailClaimRequest.gpi;
        claim.grossAmountDue = item.trailClaimRequest.grossAmountDue;
        claim.isBenefitInquiry = item.trailClaimRequest.isBenefitInquiry;
        claim.numberOfRefillsAuthorized = item.trailClaimRequest.numberOfRefillsAuthorized;
        claim.otherCoverageCode = item.trailClaimRequest.otherCoverageCode;
        claim.paNumber = item.trailClaimRequest.paNumber;
        claim.paType = item.trailClaimRequest.paType;
        claim.patientResidence = item.trailClaimRequest.patientResidence;
        claim.personCode = item.trailClaimRequest.personCode;
        claim.pharmacyServiceType = item.trailClaimRequest.pharmacyServiceType;
        claim.placeOfService = item.trailClaimRequest.placeOfService;
        claim.prescriberId = item.trailClaimRequest.prescriberId;
        claim.prescriberIdQualifier = item.trailClaimRequest.prescriberIdQualifier;
        claim.productServiceId = item.trailClaimRequest.productServiceId;
        claim.productServiceIdQualifier = item.trailClaimRequest.productServiceIdQualifier;
        claim.qtyCalculationOption1 = item.trailClaimRequest.qtyCalculationOption1;
        claim.qtyCalculationOption2 = item.trailClaimRequest.qtyCalculationOption2;
        claim.relationshipCode = item.trailClaimRequest.relationshipCode;
        claim.routeOfAdmin = item.trailClaimRequest.routeOfAdmin;
        claim.rxServiceReferenceNo = item.trailClaimRequest.rxServiceReferenceNo;
        claim.rxServiceReferenceQualifier = item.trailClaimRequest.rxServiceReferenceQualifier;
        claim.sbmClarificationCode = item.trailClaimRequest.sbmClarificationCode;
        claim.scheduledRxId = item.trailClaimRequest.scheduledRxId;
        claim.specialPackaging = item.trailClaimRequest.specialPackaging;
        claim.subConsumingAppId = item.trailClaimRequest.consumingApp;
        claim.usualAndCustomaryCharge = item.trailClaimRequest.usualAndCustomaryCharge;
        if(item.trailClaimRequest.durSegment!=undefined){
          item.trailClaimRequest.durSegment.forEach((durItem:DurSegment)=>{
            if(durItem.prfsnlSrvcCd!=undefined && durItem.prfsnlSrvcCd.length!=0 
              && durItem.rsltSrvcCd!=undefined && durItem.rsltSrvcCd.length!=0 ){
                const durClaimSegment:DURSegmentClaimSubmit = new DURSegmentClaimSubmit();
                durClaimSegment.levelOfEffort = durItem.levelOfEffort;
                durClaimSegment.professionalCode = durItem.prfsnlSrvcCd;
                durClaimSegment.reasonCode = durItem.reasonSrvcCd;
                durClaimSegment.resultCode = durItem.rsltSrvcCd;
                durClaimSegment.codeCounter = claim.durClaimSubmitSegment.length
                claim.durClaimSubmitSegment.push(durClaimSegment);
            }
        });
        }

        if (item.trailClaimResponse.prefAlternativesWebResponse != undefined
          && Array.isArray(item.trailClaimResponse.prefAlternativesWebResponse.scenarioClaims)
          && item.trailClaimResponse.prefAlternativesWebResponse.scenarioClaims.length > 0) {
          claim.daysSupply = item.trailClaimResponse.prefAlternativesWebResponse.scenarioClaims[0].responseClaimSegment.daysSupply;
          claim.diagnosisCode = item.trailClaimResponse.prefAlternativesWebResponse.scenarioClaims[0].responseClinicalSegment.diagnosisInformation[0].diagnosisCode;
          claim.diagnosisCodeDescription = item.trailClaimResponse.prefAlternativesWebResponse.scenarioClaims[0].responseClinicalSegment.diagnosisInformation[0].diagnosisCodeDescription;
          claim.diagnosisCodeQualifier = item.trailClaimResponse.prefAlternativesWebResponse.scenarioClaims[0].responseClinicalSegment.diagnosisInformation[0].diagnosisCodeQualifier;
          claim.serviceProviderId = item.trailClaimResponse.prefAlternativesWebResponse.scenarioClaims[0].responseHeaderSegment.serviceProviderId;
          claim.serviceProviderIdQualifier = item.trailClaimResponse.prefAlternativesWebResponse.scenarioClaims[0].responseHeaderSegment.serviceProviderIdQualifier;
        }
      }
      requestList.push(claim);
    });
    submitClaimList.requests = requestList;
    return submitClaimList;
  }

  reverseMultipleClaim(webrequest: ReverseClaimRequest): Promise<ReverseClaimResponse> {
    const promise: Promise<ReverseClaimResponse> = new Promise<ReverseClaimResponse>((resolve, reject) => {
      let header=new HttpHeaders({"endpoint":environment.getReverseClaimUrl});
      this.http.post(environment.proxyurl, webrequest,{headers:header}).subscribe((response: any) => {
        if (response.status != undefined
          && response.status.statusCode != undefined
          && (response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT ||
            response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_WARNING_TXT ||
            response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_INFO_TXT)
        ) {
          resolve(response);
        } else {
          this.logger.log(response);
          reject(response);
        }
      }, (error: any) => {
        this.logger.error(error);
          reject(error);
      });
    });
    return promise;
  }

  generateSelectedMedicinesFromLstClaims(result: ClaimTransactionHistoryResponse): Promise<SelectedMedicine[]> {

    const promise: Promise<SelectedMedicine[]> = new Promise<SelectedMedicine[]>((resolve, reject) => {
      if (Array.isArray(result.responses) && result.responses.length > 0) {

        const memberWebRequest: MemberDetailWebRequesthemi = new MemberDetailWebRequesthemi();
        memberWebRequest.id = result.responses[0].mbrId;
        memberWebRequest.sourceSystemInstance = result.responses[0].instanceId;
        memberWebRequest.carrierId = result.responses[0].cagCarrierId;
        memberWebRequest.accountId = result.responses[0].cagAccountId;
        memberWebRequest.groupId = result.responses[0].cagGroupId;
        let mbrResponse: MemberDetailWebResponsehemi;
        this.getMemberDetailshemi(memberWebRequest).then((response: MemberDetailWebResponsehemi) => {
          
          if (mbrResponse.memberItems != undefined) {
            mbrResponse=response;
          } else {
            // reject(APPCONSTANTS.API_RESPONSE_ERROR_TEXT);
          }
        }, (error: any) => {
          // reject(APPCONSTANTS.API_RESPONSE_ERROR_TEXT);
        }).catch((error: any) => {
          // reject(APPCONSTANTS.API_RESPONSE_ERROR_TEXT);
        });

            let webrequest: Membersearchwebrequest = new Membersearchwebrequest();
            webrequest.lastName = result.responses[0].lastName;
            webrequest.firstName = result.responses[0].firstName;
            webrequest.dateOfBirth = result.responses[0].dateOfBirth.replace(new RegExp("-", "g"), "");
            webrequest.zipCode = result.responses[0].zipCode;
            webrequest.memberId = result.responses[0].mbrId;
            webrequest.includeLob = environment.membersearchIncludeLob;
            webrequest.includeExcludeListIndicator = environment.membersearchIncludeExcludeIndicator;
            webrequest.groupList = environment.membersearchGroupList;
            this.memberSearchHelper.searchForMember(webrequest).then((response: Membersearchwebresponse) => {
              if (response.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT && response.memberSearchV5Items.length > 0) {
                this.memberSearchHelper.storeSelectedMember(response.memberSearchV5Items[0]);
                // Setting member result session
                let memberResults = response.memberSearchV5Items;
                let blnSearchedMember: FavouriteMember = new FavouriteMember();
                blnSearchedMember.firstName = response.memberSearchV5Items[0].firstName;
                blnSearchedMember.lastName = response.memberSearchV5Items[0].lastName;
                blnSearchedMember.zipCode = response.memberSearchV5Items[0].address.zip1;
                blnSearchedMember.memberId = response.memberSearchV5Items[0].memberId;
                sessionStorage.setItem('memberResults', JSON.stringify(memberResults));
                sessionStorage.setItem('blnSearchedMember', JSON.stringify(blnSearchedMember));
                ////////

                const prescriberDetailWebRequest: PrescriberDetailWebRequest = new PrescriberDetailWebRequest();
                prescriberDetailWebRequest.prescriberId = result.responses[0].prescriberNpiId;
                prescriberDetailWebRequest.instanceId = environment.instanceId;
                //prescriberDetailWebRequest.instanceId = result.responses[0]
                const prescriberQalifier: PRESCRIBER_QUALIFIER | undefined = PRESCRIBER_QUALIFIER_LABEL.get(result.responses[0].prescriberIdQualifier);
                if (prescriberQalifier != undefined)
                  prescriberDetailWebRequest.prescriberIdQualifier = prescriberQalifier;
                else
                  prescriberDetailWebRequest.prescriberIdQualifier = PRESCRIBER_QUALIFIER.NPI;

                // this.prescriberSearch.getPrescriberDetail(prescriberDetailWebRequest).then((pbrResponse: PrescriberDetailWebResponse) => {
                //   ////debugger;
                  // if (pbrResponse.response != undefined && pbrResponse.response.prescriberDetailItem != undefined) {
                    var prescriber = new PrescriberSearchWebRequest();
                    prescriber.prescriberId = result.responses[0].prescriberNpiId;
                    prescriber.prescriberIdQualifier = PRESCRIBER_QUALIFIER.NPI;

                    this.prescriberSearch.getPrescriberSearch(prescriber).subscribe((data: PrescriberSearchRootObject) => {
                      //console.log(data);
                      if (data.status.respCode == APPCONSTANTS.API_RESPONSE_SUCCESS_CODE) {
                        let prescriberList = data.response.prescriberSearchItems;
                        prescriberList = prescriberList.slice(0, 100);
                        const searchCriteria: PrescriberSearchCriteria = new PrescriberSearchCriteria();
                        searchCriteria.prescriberId = result.responses[0].prescriberNpiId;
                        searchCriteria.prescriberIdQualifier = prescriber.prescriberIdQualifier;
                        this.prescriberSearch.storePrescriberSearchCriteria(searchCriteria);
                        this.prescriberSearch.storePrescriberResults(prescriberList);
                        this.prescriberSearch.storeSelectedPrescriber(prescriberList[0]);

                        const selectedMedicineList: SelectedMedicine[] = [];
                        result.responses.forEach((claim: LstClaimHistory, index: number) => {
                          var observable = Observable.create((observer: any) => {
                            const drugSearchWebRequest: Drugsearchwebrequest = new Drugsearchwebrequest();
                            if(result.responses[index].drugName!=undefined)
                              drugSearchWebRequest.drugName=result.responses[index].drugName.substring(0,result.responses[index].drugName.indexOf(' '));
                            else
                              drugSearchWebRequest.drugName=result.responses[0].productNameExt;
                            drugSearchWebRequest.instanceId = result.responses[index].instanceId;
                            const qualifier: DRUG_QUALIFIER | undefined = DRUG_QUALIFIER_LABEL.get(result.responses[0].productServiceIdQualifier);
                            if (qualifier != undefined)
                              drugSearchWebRequest.productIdQldr = qualifier;
                            else
                              drugSearchWebRequest.productIdQldr = DRUG_QUALIFIER.NDC;

                            this.drugSearchHelper.getDrugSearchItems(drugSearchWebRequest).then((drgResponse: Drugsearchwebresponse) => {


                              if (drgResponse.response != undefined && drgResponse.response.drugSearchItems != undefined
                                && Array.isArray(drgResponse.response.drugSearchItems) && drgResponse.response.drugSearchItems.length > 0) {

                                if (claim.claimStsTypId != CLAIM_STATUS_TYPE.REMOVED) {
                                  const targetDrugScenarioClaim: ScenarioClaim | undefined = claim.trailClaimResponse.prefAlternativesWebResponse.scenarioClaims.find((item: ScenarioClaim) => {
                                    return item.scenarioDrug == 0 && item.scenarioPharmacy == 0;
                                  });
                                  if (targetDrugScenarioClaim != undefined) {
                                    const medicine: SelectedMedicine = new SelectedMedicine();
                                    medicine.clmgroupId = result.clmgroupId;
                                    medicine.claimDetailId = (claim.claimDetailId != undefined) ? claim.claimDetailId : 0;
                                    medicine.dateOfLastTrial = this.timeStampToStandardDate.transform(claim.claimDate);

                                    medicine.selectedMember = new MemberSearchItem();
                                    medicine.selectedMember.address = response.memberSearchV5Items[0].address;
                                    medicine.selectedMember.lob = claim.lob;
                                    medicine.selectedMember = JSON.parse(JSON.stringify(this.memberSearchHelper.getSelectedMember()));

                                    medicine.memberDetailResponse = mbrResponse;
                                    medicine.selectedPrescriber=prescriberList[0];

                                    // medicine.selectedPrescriberDetail = pbrResponse.response.prescriberDetailItem;
                                    ////debugger;
                                    drgResponse.response.drugSearchItems=drgResponse.response.drugSearchItems.filter((item:DrugSearchItem)=>item.productId==result.responses[index].productServiceId);
                                    medicine.drugSearchItem = drgResponse.response.drugSearchItems[0];
                                    medicine.drugSearchItem.breakableIndicator = claim.breakableIndicator;
                                    medicine.MedStrength=drgResponse.response.drugSearchItems[0].productId;
                                    medicine.MedStrengthName = drgResponse.response.drugSearchItems[0].detailedDrugName;
                                    medicine.Quantity = (claim.drgQty != undefined) ? claim.drgQty.toString() : "";
                                    const qtyCalculation: QtyCalculated = this.calculateQtyService.quantityCalculate(claim.drgQty, medicine.drugSearchItem);
                                    medicine.quantytyDesc = qtyCalculation.qtydesc;
                                    medicine.quantytyCalculate = parseInt(qtyCalculation.qtycaliculated);
                                    // //debugger;
                                    medicine.dateOfservice=this.timeStampToStandardDate.transform(claim.dateOfService);
                                    if (Array.isArray(claim.durSegment)) {
                                      claim.durSegment.forEach((durItem: DurSegment, durIndex: number) => {
                                        if (durItem.response == "LEH") {
                                          const durStr: string | undefined = DUR_LABEL.get(parseInt(durItem.levelOfEffort));
                                          if (durStr != undefined)
                                            medicine.DrugAdmin = durStr;
                                        }
                                      });
                                    }
                                    //medicine.DrugAdmin = tar

                                    medicine.trailadjudicationwebresponse = claim.trailClaimResponse;
                                    ////debugger;
                                    if (claim.trailClaimRequest == undefined) {
                                      medicine.trailadjudicationwebrequest = new Trailadjudicationwebrequest();
                                      medicine.trailadjudicationwebrequest.dateOfService = claim.dateOfService;

                                      medicine.trailadjudicationwebrequest.daysSupply = targetDrugScenarioClaim.responseClaimSegment.daysSupply;
                                      medicine.Days = medicine.trailadjudicationwebrequest.daysSupply;
                                      medicine.trailadjudicationwebrequest.diagnosisCode = claim.trailClaimResponse.response.diagnosisCode;
                                      medicine.trailadjudicationwebrequest.drugName = (claim.drugName != undefined) ? claim.drugName : medicine.drugSearchItem.detailedDrugName;
                                      medicine.trailadjudicationwebrequest.placeOfService = claim.trailClaimResponse.response.placeOfService;
                                      medicine.placeOfservice = claim.trailClaimResponse.response.placeOfService;
                                      medicine.trailadjudicationwebrequest.productServiceId = claim.productServiceId;
                                      medicine.trailadjudicationwebrequest.productServiceIdQualifier = claim.productServiceIdQualifier;
                                      medicine.trailadjudicationwebrequest.firstName = claim.firstName;
                                      medicine.trailadjudicationwebrequest.lastName = claim.lastName;
                                      medicine.trailadjudicationwebrequest.gender = claim.gender;
                                      medicine.trailadjudicationwebrequest.zipCode = claim.zipCode;
                                      medicine.trailadjudicationwebrequest.personCode = claim.personCode;
                                      medicine.trailadjudicationwebrequest.instanceId = claim.instanceId;
                                      medicine.trailadjudicationwebrequest.cardholderId = claim.mbrId;
                                      medicine.trailadjudicationwebrequest.dateOfBirth = claim.dateOfBirth.replace(/-/g, "");
                                      medicine.trailadjudicationwebrequest.cagAccountId = claim.cagAccountId;
                                      medicine.trailadjudicationwebrequest.cagCarrierId = claim.cagCarrierId;
                                      medicine.trailadjudicationwebrequest.cagGroupId = claim.cagGroupId;
                                      medicine.trailadjudicationwebrequest.datePrescriptionWritten = claim.datePrescriptionWritten;
                                      medicine.trailadjudicationwebrequest.dispenseAsWrittenCode = APPCONSTANTS.RTB_DAW;
                                      medicine.trailadjudicationwebrequest.gpi = claim.gpi;
                                      medicine.trailadjudicationwebrequest.prescriberId = claim.prescriberNpiId;
                                      medicine.trailadjudicationwebrequest.prescriberIdQualifier = claim.prescriberIdQualifier;
                                      medicine.trailadjudicationwebrequest.relationshipCode = claim.relationshipCode;
                                      medicine.trailadjudicationwebrequest.userId = claim.userId;


                                      medicine.trailadjudicationwebrequest.qtyCalculationOption1 = new QtyCalculationOption1();
                                      medicine.trailadjudicationwebrequest.qtyCalculationOption1.quantityDispensed = targetDrugScenarioClaim.responseClaimSegment.quantityDispensed;
                                      medicine.trailadjudicationwebrequest.qtyCalculationOption2 = new QtyCalculationOption2();
                                    } else {
                                      medicine.trailadjudicationwebrequest = claim.trailClaimRequest;
                                    }

                                    if (Array.isArray(targetDrugScenarioClaim.responseClinicalSegment.diagnosisInformation) && targetDrugScenarioClaim.responseClinicalSegment.diagnosisInformation.length > 0) {
                                      medicine.diagnosisCodeSearchItem = new DiagnosisCodeSearchItem();
                                      medicine.diagnosisCodeSearchItem.diagnosisCode = targetDrugScenarioClaim.responseClinicalSegment.diagnosisInformation[0].diagnosisCode;
                                      medicine.diagnosisCodeSearchItem.diagnosisDescription = targetDrugScenarioClaim.responseClinicalSegment.diagnosisInformation[0].diagnosisCodeDescription;
                                      medicine.diagnosisCodeSearchItem.diagnosisQualifier = targetDrugScenarioClaim.responseClinicalSegment.diagnosisInformation[0].diagnosisCodeQualifier;
                                      medicine.DiagnosisCode = medicine.diagnosisCodeSearchItem.diagnosisCode;
                                    }

                                    medicine.saveClaimResponseLstClaim = new Response();
                                    medicine.saveClaimResponseLstClaim.infusionFacility = claim.infusionFacility;
                                    medicine.saveClaimResponseLstClaim.claimDate = claim.claimDate;
                                    medicine.saveClaimResponseLstClaim.claimDetailId = claim.claimDetailId;
                                    medicine.saveClaimResponseLstClaim.claimGroupId = result.clmgroupId;
                                    medicine.saveClaimResponseLstClaim.claimStsTypId = claim.claimStsTypId;
                                    medicine.saveClaimResponseLstClaim.dateOfService = claim.dateOfService;
                                    medicine.saveClaimResponseLstClaim.productServiceId = claim.productServiceId;
                                    medicine.saveClaimResponseLstClaim.status = claim.status;
                                    medicine.saveClaimResponseLstClaim.durSegment = claim.durSegment;

                                    // Submited Claim Mapping
                                    if (claim.claimStsTypId == CLAIM_STATUS_TYPE.SUBMITTED_PAID || claim.claimStsTypId == CLAIM_STATUS_TYPE.SUBMITTED_DUPLICATE || claim.claimStsTypId == CLAIM_STATUS_TYPE.SUBMITTED_REJECTED || claim.claimStsTypId == CLAIM_STATUS_TYPE.REVERSED_PAID || claim.claimStsTypId == CLAIM_STATUS_TYPE.REVERSED_REJECTED) {
                                      let submitClaimResponseLstClaim = new SubmitClaimResponseDetail();
                                      let submitClaimResponse = new SubmitClaimResponse();
                                      let realClaimResponse = new RealClaimResponse();
                                      let responsePricing = new ResponsePricing();
                                      responsePricing.ingredientCost = claim.billingInformation.ingredientCost;
                                      responsePricing.dispensingFee = claim.billingInformation.dispensingFee;
                                      responsePricing.patientPayAmount = claim.billingInformation.patientPayAmount;
                                      responsePricing.totalAmountDue = claim.billingInformation.totalAmountDue;
                                      realClaimResponse.responsePricing = responsePricing;
                                      realClaimResponse.rxServiceReferenceNo = claim.rxServiceReferenceNo;
                                      realClaimResponse.uniqueClaimId = claim.trailClaimResponse.response.uniqueClaimId;
                                      realClaimResponse.message = claim.message;
                                      submitClaimResponse.realClaimResponse = realClaimResponse;
                                      submitClaimResponseLstClaim.claimStsTypId = claim.claimStsTypId;
                                      submitClaimResponseLstClaim.status = claim.status;
                                      submitClaimResponseLstClaim.submitClaimResponse = submitClaimResponse;
                                      medicine.submitClaimResponseLstClaim = submitClaimResponseLstClaim;
                                    }
                                    ////////////////////

                                    // Reversed Claim Mapping
                                    if (claim.claimStsTypId == CLAIM_STATUS_TYPE.REVERSED_PAID || claim.claimStsTypId == CLAIM_STATUS_TYPE.REVERSED_REJECTED) {
                                      let reverseClaimResponseLstClaim: ReverseClaimResponse = new ReverseClaimResponse();
                                      reverseClaimResponseLstClaim.status = claim.status;
                                      reverseClaimResponseLstClaim.claimStsTypId = claim.claimStsTypId;
                                      let claimReverseResponse: ClaimReverseResponse = new ClaimReverseResponse();
                                      claimReverseResponse.status = claim.status;
                                      let realClaimReversalResponse: RealClaimReversalResponse = new RealClaimReversalResponse();
                                      realClaimReversalResponse.message = claim.message;
                                      claimReverseResponse.realClaimReversalResponse = realClaimReversalResponse;
                                      reverseClaimResponseLstClaim.claimReverseResponse = claimReverseResponse;
                                      medicine.reverseClaimResponseLstClaim = reverseClaimResponseLstClaim;

                                    }
                                    ////////////////////

                                    observer.next(medicine);
                                    // selectedMedicineList.push(medicine);
                                  }
                                }

                              }
                              if (selectedMedicineList.length == result.responses.length) {
                                // return resolve(selectedMedicineList);
                                //console.log("Claim Data");
                                //console.log(selectedMedicineList);
                                observer.complete();
                              }

                            }, (error: any) => {
                              reject(APPCONSTANTS.API_RESPONSE_ERROR_TEXT);
                            }).catch((error: any) => {
                              reject(APPCONSTANTS.API_RESPONSE_ERROR_TEXT);
                            });
                          });

                          observable.subscribe(
                            (data: any) => {
                              selectedMedicineList.push(data);
                              if (selectedMedicineList.length == result.responses.length) {
                                //console.log("Claim Data2");
                                return resolve(selectedMedicineList);
                              }
                            });

                        });
                        // Tobe addedd
                      }else{
                        reject(APPCONSTANTS.API_RESPONSE_ERROR_TEXT);
                      }}, (error: any) => {
                        reject(APPCONSTANTS.API_RESPONSE_ERROR_TEXT);
                      });
                    } else {
                      reject(APPCONSTANTS.API_RESPONSE_ERROR_TEXT);
                  // }
                // }, (error: any) => {
                //   reject(APPCONSTANTS.API_RESPONSE_ERROR_TEXT);
                // }).catch((error: any) => {
                //   reject(APPCONSTANTS.API_RESPONSE_ERROR_TEXT);
                // });

              }
            }, (error: any) => {
              reject(APPCONSTANTS.API_RESPONSE_ERROR_TEXT);
            }).catch((error: any) => {
              reject(APPCONSTANTS.API_RESPONSE_ERROR_TEXT);
            });
          
      } else {
        setTimeout(() => {
          reject(APPCONSTANTS.API_RESPONSE_NO_RECORDS);
        }, 200);
      }
    });
    return promise;
  }


}
