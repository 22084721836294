import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { PrescriberSearchService }  from 'src/app/services/prescriberSearch/prescriber-search.service';
import { PrescriberSearchItem, PrescriberDetailWebResponse, PrescriberAlternateAddress, PrescriberDetailItem, PrescriberDemographs} from 'src/app/modals/prescriberSearch/prescriberSearchwebresponse';
import { PrescriberDetailWebRequest } from 'src/app/modals/prescriberSearch/prescriberSearchweberequest';
import { Loginuser } from 'src/app/modals/login/loginviewmodal';
import { APPCONSTANTS, PRESCRIBERDETAIL_STATUS, PRESCRIBER_QUALIFIER } from 'src/app/constants/appconstant';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'editprovider',
  templateUrl: './editprovider.component.html',
  styleUrls: ['./editprovider.component.css']
})
export class EditproviderComponent implements OnInit {


  @Input()
  prescriberDemographs!:PrescriberDemographs;

  loginuser:Loginuser;
  prescriber!:PrescriberSearchItem;
  prescriberDetail!:PrescriberDetailItem;
  blnShow:boolean;
  blnLoader:boolean; 
  alternativeAddresses: PrescriberAlternateAddress[];  

  selectedAddressPhone!:string;
  selectedAddressFax!:string;

  
  private _detailStatus !: PRESCRIBERDETAIL_STATUS;
  public get detailStatus() : PRESCRIBERDETAIL_STATUS {
    return this._detailStatus;
  }
  public set detailStatus(v : PRESCRIBERDETAIL_STATUS) {
    this._detailStatus = v;
    this.isValid.emit(this.detailStatus);
  }
  
  
  private _selectedAltAddressIndex!: number;
  public get selectedAltAddressIndex() : number {
    return this._selectedAltAddressIndex;
  }
  public set selectedAltAddressIndex(v : number) {
    this._selectedAltAddressIndex = v;
    this.onAddressChange();
  }
  
  @Input()
  internalEdit:boolean;
  
  @Output()
  isValid:EventEmitter<PRESCRIBERDETAIL_STATUS> = new EventEmitter<PRESCRIBERDETAIL_STATUS>();

  zeroRegExp:any=/[^0]+/i;
  
  blnEditingPanelSubmitted:boolean=false;
  editProviderPanel: FormGroup = new FormGroup({
    editedAddressPhone: new FormControl("",[Validators.required, Validators.pattern(/^[0-9]{1,10}$/i)]),
    editedAddressFax: new FormControl("",[Validators.required, Validators.pattern(/^[0-9]{1,10}$/i)])
  });

  private getEditedAddressPhone():string{
    return this.editProviderPanel.controls['editedAddressPhone'].value;
  }
  private setEditedAddressPhone(val:string):void{
    this.editProviderPanel.controls['editedAddressPhone'].setValue(val);
  }
  private getEditedAddressFax():string{
    return this.editProviderPanel.controls['editedAddressFax'].value;
  }
  private setEditedAddressFax(val:string):void{
    this.editProviderPanel.controls['editedAddressFax'].setValue(val);
  }

  constructor(private profileService:ProfileService,
    private prescriberService:PrescriberSearchService, private logger: LoggerService, private titleService:Title) {
    this.titleService.setTitle("SMAP - EDIT Provider EPA");  
    this.loginuser = this.profileService.loginuser;
    const presbr: PrescriberSearchItem | undefined = this.prescriberService.readSelectedPrescriber();
    this.blnShow=false;
    this.blnLoader=false;
    this.alternativeAddresses=[];
    this.internalEdit=true;
    this.detailStatus = PRESCRIBERDETAIL_STATUS.SUCCESS;
    
    if(presbr!=undefined)
    {
      this.prescriber = presbr;
      this.blnShow=true;
      this.selectedAltAddressIndex=-1;
    }
  }

  
  private _NPI !: string;
  public get NPI() : string {
    if(this.prescriber!=undefined)
      return this.prescriberService.findPrescriberNPI(this.prescriber.identifiers);
    else
    {
        return this.prescriberService.findPrescriberNPI(this.prescriberDemographs.identifiers);
    }
  }
  
  
  private _fullName !: string;
  public get fullName() : string {
    if(this.prescriber!=undefined)
      return this.prescriberService.getSelectedPrescriberFullName(this.prescriber);
    else
      return this.prescriberService.getSelectedPrescriberFullName(this.prescriberDemographs);
  }  

  
  private _instanceId !: string;
  public get instanceId() : string {
   if(this.prescriber!=undefined) 
    return this.prescriber.instanceId;
   else
    return this.prescriberDemographs.instanceId;
  }
  

  private onAddressChange():void{
    if(Array.isArray(this.alternativeAddresses) && this.alternativeAddresses.length>0){
      this.selectedAddressFax = this.alternativeAddresses[this.selectedAltAddressIndex].contact.fax;
      this.selectedAddressPhone = this.alternativeAddresses[this.selectedAltAddressIndex].contact.phone1;
    } else {
      this.selectedAddressPhone="0";
      this.selectedAddressFax="0";
    }
    if(this.detailStatus == PRESCRIBERDETAIL_STATUS.EDIT){
      this.edit();
    } else {
      this.updatePrescriberDetailIntoSession();
    }
  }

  private updatePrescriberDetailIntoSession():void{
    if(this.prescriberDetail!=undefined && Array.isArray(this.prescriberDetail.alternateAddresses) 
      && this.prescriberDetail.alternateAddresses.length>0 
      && this.prescriberDetail.alternateAddresses[this.selectedAltAddressIndex]!=undefined){
        const updatedPrescriberDetail:PrescriberDetailItem = JSON.parse(JSON.stringify(this.prescriberDetail));
        updatedPrescriberDetail.contact.phone1=this.selectedAddressPhone;
        updatedPrescriberDetail.contact.fax=this.selectedAddressFax;
        updatedPrescriberDetail.selectedAlternativeAddress = this.selectedAltAddressIndex;
        this.prescriberService.storeSelectedPrescriberDetail(updatedPrescriberDetail);
      }
  }

  ngOnInit(): void {
    debugger;
    if(this.prescriber==undefined && this.prescriberDemographs!=undefined){
      this.blnShow=true;
      this.selectedAltAddressIndex=-1;
    }
    if(this.blnShow){
      const presbrdetailswebrequest:PrescriberDetailWebRequest = new PrescriberDetailWebRequest();
      presbrdetailswebrequest.instanceId = this.instanceId;
      presbrdetailswebrequest.prescriberId = this.NPI;
      presbrdetailswebrequest.prescriberIdQualifier = PRESCRIBER_QUALIFIER.NPI;
      this.blnLoader=true;
      this.alternativeAddresses=[];
      this.prescriberService.clearSelectedPrescriberDetail();
      this.prescriberService.getPrescriberDetail(presbrdetailswebrequest).then((response:PrescriberDetailWebResponse)=>{
        this.blnLoader=false;
        debugger;
        if(response.status.respCode == APPCONSTANTS.API_RESPONSE_SUCCESS_CODE){
          this.prescriberDetail = response.response.prescriberDetailItem;
          this.alternativeAddresses=this.prescriberService.getPrescriberValidAlternativeAddresses(this.prescriberDetail.alternateAddresses);
          this.selectedAltAddressIndex=0;
          if(this.alternativeAddresses.length==0){
            this.prescriberService.clearSelectedPrescriberDetail();
            this.detailStatus=PRESCRIBERDETAIL_STATUS.INVALID_FAX_NUMBER;
          }else{
            this.detailStatus=PRESCRIBERDETAIL_STATUS.SUCCESS;
            this.updatePrescriberDetailIntoSession();
          }
          
        } else {
          this.prescriberService.clearSelectedPrescriberDetail();
          if(response.status.respCode == APPCONSTANTS.API_RESPONSE_NO_RECORDS)
            this.detailStatus=PRESCRIBERDETAIL_STATUS.NOT_FOUND;
          else
            this.detailStatus=PRESCRIBERDETAIL_STATUS.SERVER_ERROR;
        }
      },(error:any)=>{
        this.blnLoader=false;
        this.detailStatus=PRESCRIBERDETAIL_STATUS.SERVER_ERROR;
      }).catch((error:any)=>{
        this.blnLoader=false;
        this.detailStatus=PRESCRIBERDETAIL_STATUS.SERVER_ERROR;
      });
    }
  }
  getAddressLine3(prescriberAdd:PrescriberAlternateAddress):string{
    return this.prescriberService.getAlternativeAddressLine3(prescriberAdd);
  }

  edit():void{
    this.blnEditingPanelSubmitted=false;
    this.detailStatus = PRESCRIBERDETAIL_STATUS.EDIT;
    this.logger.userAction("Editing the Provider.");
    this.setEditedAddressFax(this.selectedAddressFax);
    this.setEditedAddressPhone(this.selectedAddressPhone);
  }
  discardEdit():void{
    this.logger.userAction("Discarded Editing the Provider.");
    this.detailStatus = PRESCRIBERDETAIL_STATUS.SUCCESS;
  }
  saveEdit():void{
    this.blnEditingPanelSubmitted=true;
    if(this.editProviderPanel.controls["editedAddressPhone"].valid && this.getEditedAddressPhone().length==10
    && this.editProviderPanel.controls["editedAddressFax"].valid && this.getEditedAddressFax().length==10){
      this.selectedAddressPhone = this.getEditedAddressPhone();
      this.selectedAddressFax = this.getEditedAddressFax();
      this.updatePrescriberDetailIntoSession();
      this.logger.userAction("Saving the Provider.");
      this.detailStatus = PRESCRIBERDETAIL_STATUS.SUCCESS;
    } else{
      if(this.getEditedAddressPhone().length==0 || this.getEditedAddressFax().length==0)
      this.logger.userAction("Saving the Provider failed due to missing fields.");
        this.detailStatus = PRESCRIBERDETAIL_STATUS.MISSING_FIELDS;
    } 
  }
}
