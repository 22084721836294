import {Prescriber,Infusion} from './loginwebresponse';
import { BehaviorSubject } from 'rxjs';

export class Loginuser {
    ohid!:string;
    UUID!:string;
    linkUuid!:string;
    email!:string;

    prescriber:Prescriber=new Prescriber();

    defaultPrescriber:Prescriber | undefined = new Prescriber();

    selectedInfusion:BehaviorSubject<Infusion> = new BehaviorSubject<Infusion>(new Infusion());

    infusionList:Infusion[] = [];

    
    private _username : string="";
    public get username() : string {
        
        if(this._username.length==0 && this.prescriber!=undefined){
            if(this.prescriber.firstName!=undefined){
                this._username=this.prescriber.firstName;
            }
            if(this.prescriber.lastName!=undefined){
                this._username += (this._username.length>0)?(", "+this.prescriber.lastName):this.prescriber.lastName;                
            }
        }
        return this._username;
    }
}