<!-- <p>profile works!</p>
<p>Token :{{tokenVal}}</p>
<p>User: {{userDetails}}
<p>profile works!</p> -->

<div class="profile row">
    <p><b>{{this.profileService.loginuser.ohid}}</b> logged in with
        <label *ngIf="infusionList.length<=1 || !blnswitchenabled"
            [innerHtml]="profileService.loginuser.selectedInfusion.getValue().pharmacyName"></label>
        <span *ngIf="infusionList.length>1 && blnswitchenabled">
            <select [(ngModel)]="selectedInfusionOrgId" (change)="infusionChangeConfirmation($event)" name="selectInfusion">
                <option *ngFor="let infusion of infusionList" [value]="infusion.organizationRelationId">
                    {{infusion.pharmacyName}}</option>
            </select>
        </span>
    </p>

</div>
<ng-template #infusionselectionmodal let-modal>
    <div class="container-fluid infusionSelection" aria-labelledby="infusionselectionmodal">
        <div class="row" *ngIf="profileService.appstatus!=1">
            <div class="col nopadding">
                <div class="alert alert-danger"> An error occurred. Please try again.</div>
            </div>
        </div>
        <div class="row">
            <div class="col paragraph">
                You have access to multiple infusion facilities. Please select one from the list below to proceed.
            </div>
        </div>
        <div class="row">
            <div class="col-6" *ngFor="let infusion of infusionList;index as i;">
                <input class="form-check-input" type="radio" id="infusion_{{i}}" name="infusionOption"
                    [value]="infusion?.organizationRelationId" [(ngModel)]="selectedInfusionOrgId" />
                <label for="infusion_{{i}}" class="form-check-label">
                    {{infusion.pharmacyName}}
                </label>
            </div>
        </div>
        <div class="row">
            <div class="col text-end">
                <button type="button" class="btn btn-primary text-white shadow rounded-pill" (click)="setSelectedInfusionFacility()">Continue</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #infusionchangeconfirmation let-modal>
    <div class="container-fluid infusionSelection" aria-labelledby="infusionchangeconfirmation">
        <div class="row">
            <div class="col paragraph">
                The infusion facility has changed to {{this.infusionName}}. Please confirm that you would like to
                proceed.
            </div>
        </div>
        <div class="row">
            <div class="col text-end">
                <button type="button" class="btn btn-primary btn-primary-link rounded-pill"
                    (click)="discardInfusionChange()">Cancel</button>
            </div>
            <div class="col text-start">
                <button type="button" class="btn btn-primary rounded-pill" (click)="setSelectedInfusionFacility()">Proceed</button>
            </div>
        </div>
    </div>
</ng-template>