import { Component, OnInit, OnDestroy, Input, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {EpaprocessorService} from 'src/app/services/epa/epaprocessor.service';
import {PrescriberSearchService} from 'src/app/services/prescriberSearch/prescriber-search.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import {PrescriberDetailItem} from 'src/app/modals/prescriberSearch/prescriberSearchwebresponse';
import { SelectedMedicine } from 'src/app/modals/drugSearch/drugsearchwebresponse';
import { PAInitiationWebRequest, EpaRunnerInitiationEvent } from 'src/app/modals/epa/priorauthrequest';
import {Subscription, timer} from 'rxjs';
import { EPA_STATUS, EPA_TRANSACTIon_STATUS } from 'src/app/constants/appconstant';
import { EpaInitiationAckWebResponse, EpaInitiationResponse, PARefIDWebResponse } from 'src/app/modals/epa/priorauthresponse';
import { QuestionsetViewmodal } from 'src/app/modals/epa/questionsetviewmodal';
import { environment } from 'src/environments/environment';
import { LoggerService } from 'src/app/services/logger/logger.service';


@Component({
  selector: 'eparunner',
  templateUrl: './eparunner.component.html',
  styleUrls: ['./eparunner.component.css']
})
export class EparunnerComponent implements OnInit, OnDestroy {

  epaState:EPA_STATUS = EPA_STATUS.PA_NOT_STARTED;
  loaderText:string="";
  loaderClass:string="bigloader nopadding";

  errorMessage:string="";
  epaTimerSubscription!:Subscription;
  epaHoursRemaining!:number;
  epaMinutesRemaining!:number;
  epaSecondsRemaining!:number;

  @ViewChild("paInitiationTemplate")
  private paInitiationTemplateRef!:TemplateRef<HTMLElement>;

  @ViewChild("paInitiationFaxFormTemplate")
  private paInitiationFaxFormTemplateRef!:TemplateRef<HTMLElement>;

  @ViewChild("paInitiationErrorTemplate")
  private paInitiationErrorTemplateRef!:TemplateRef<HTMLElement>;
  
  private subscription!:any;
  
  constructor(private epaProcessor:EpaprocessorService,
    private prescriberSearch:PrescriberSearchService,
    private modalService:NgbModal,
    private profileService:ProfileService,
    private router:Router,
    private logger: LoggerService) {
  }

  ngOnInit(): void {
    this.epaProcessor.epaSubscription.subscribe
    this.subscription = this.epaProcessor.epaSubscription.subscribe((value:EpaRunnerInitiationEvent)=>{
      if(value.status == EPA_STATUS.PA_INITIATION 
        && value.claimresponse!=undefined && value.paRefId!=undefined
        && this.paInitiationTemplateRef!=undefined){
        this.epaState = value.status;
        this.submitPAInitiationRequest(value.claimresponse, value.paRefId);
      }
    });
  }

  private handleAPIError(error:any):void{
    this.epaState = EPA_STATUS.PA_NOT_STARTED;
    this.modalService.dismissAll();
    if(typeof error == 'object'){
      if(typeof error=="object" && (error.status==-1 || error.status==0)){
        //errorhtmlmodal:
        this.errorMessage="The system cannot process your request at this time.<br/><br/>You must submit the prior authorization by fax <br/> or by contacting OptumRx at 800-711-4555.";
        this.modalService.open(this.paInitiationFaxFormTemplateRef,{size:'md'});
      } else {
        const message:string=(error.status.respMessage!=undefined && error.status.respMessage.length>0)?error.status.respMessage:error.status.statusDesc;
        if(typeof error.status =='object' && error.status.statusCode!=undefined 
        && (error.status.statusCode.toLowerCase()=='bx' || error.status.statusCode.toLowerCase()=='co')){
          //connectionerrormodal:faxMsg
          this.errorMessage=message;
          this.modalService.open(this.paInitiationFaxFormTemplateRef,{size:'md'});
        } else {
          if(message.indexOf(EPA_TRANSACTIon_STATUS.PlanNotSupported)!=-1 && message.indexOf(EPA_TRANSACTIon_STATUS.ContactRx)!=-1){
            //connectionerrormodal: 
            this.errorMessage="Electronic prior authorization cannot be initiated for this <br/> member because the member's plan does not support it.<br/><br/>You must submit the prior authorization by fax <br/> or by contacting OptumRx at 800-711-4555.";
            this.modalService.open(this.paInitiationFaxFormTemplateRef,{size:'md'});
          } else {
            //errormodal:message
            this.errorMessage=message;
            this.modalService.open(this.paInitiationErrorTemplateRef, {size:'md'});
          }
        }
      }
    } else {
      //errormodal:message
      if(error == EPA_TRANSACTIon_STATUS.INTERNAL_SERVER_ERROR){
        //errorhtmlmodal:
        this.errorMessage="An error occurred. Please call customer service at<br/>1-866-842-3278.";
        this.modalService.open(this.paInitiationErrorTemplateRef, {size:'md'});
      } else {
        if(error == EPA_TRANSACTIon_STATUS.POOLING_TIME_EXCEEDED){
          this.errorMessage="The system cannot process your request at this time.<br/><br/>You must submit the prior authorization by fax <br/> or by contacting OptumRx at 800-711-4555.";
          this.modalService.open(this.paInitiationFaxFormTemplateRef,{size:'md'});
        } else {
          //errormodal: show error
          this.errorMessage=error;
          this.modalService.open(this.paInitiationErrorTemplateRef, {size:'md'});
        }
      }
    }
  }

  private handleAPIException(error:any):void{
    this.epaState = EPA_STATUS.PA_NOT_STARTED;
    if(typeof error == 'object' &&  (error.status==-1 || error.status==0)){
      //errorhtmlmodal:
      this.errorMessage="The system cannot process your request at this time.<br/><br/>You must submit the prior authorization by fax <br/> or by contacting OptumRx at 800-711-4555.";
      this.modalService.open(this.paInitiationFaxFormTemplateRef,{size:'md'});
    } else {
      //errorhtmlmodal:
      this.errorMessage="An error occurred. Please call customer service at<br/>1-866-842-3278.";
      this.modalService.open(this.paInitiationErrorTemplateRef, {size:'md'});
    }
  }

  private startPoolingQuestionSet(paRef:PARefIDWebResponse):void{
    this.epaState = EPA_STATUS.PA_QUESTIONNARIE_CHECK;
    this.epaProcessor.poolPAInitiationResponse(paRef.paRefID).then((response:EpaInitiationResponse)=>{
      this.epaState = EPA_STATUS.PA_QUESTIONNARIE_RETRIVED;
      const epaQuestions: QuestionsetViewmodal = this.epaProcessor.extactQuestionSetModalFromSavedPA(response.paInitiationResponseWeb.body);
      epaQuestions.paReferenceID.completePaRefId = paRef.paRefPrefix+paRef.paRefID;
      this.epaProcessor.storeQuestionSetViewModal(epaQuestions);
      const $timer:any = timer(0,1000);
      this.epaTimerSubscription = $timer.subscribe((value:any)=>{
        this.showEpaTimeline(epaQuestions.deadlineForReply);
      });
    },(error:any)=>this.handleAPIError(error)).catch((error:any)=>this.handleAPIException(error));
  }
  private showEpaTimeline(deadlineForReply:number):void{
    this.epaState = EPA_STATUS.PA_QUESTIONNARIE_TIMELINE;
    const deadLine: Date = new Date(deadlineForReply);
    //Removed 1 minute grace period to submit the questionset.
    deadLine.setMinutes(deadLine.getMinutes()-1);
    const today: Date = new Date();
    
    if(deadLine instanceof Date && !isNaN(deadLine.valueOf())){
      //valid date.
      let differenceinmilliseconds:number= deadLine.getTime() - today.getTime();
      
      this.epaHoursRemaining = parseInt((differenceinmilliseconds/(1000*60*60)).toString());
      differenceinmilliseconds = differenceinmilliseconds - (this.epaHoursRemaining * 60 * 60 * 1000);

      this.epaMinutesRemaining = parseInt((differenceinmilliseconds/(1000*60)).toString());
      differenceinmilliseconds = differenceinmilliseconds - (this.epaMinutesRemaining * 60 * 1000);

      this.epaSecondsRemaining = parseInt((differenceinmilliseconds/1000).toString());
    }

  }
  private submitPAInitiationRequest(claimresponse:SelectedMedicine, paRef:PARefIDWebResponse):void{
    const prescriberDetail: PrescriberDetailItem | undefined = this.prescriberSearch.readSelectedPrescriberDetail();
    if(claimresponse !=undefined && prescriberDetail !=undefined){
      claimresponse.selectedPrescriberDetail = prescriberDetail;
      const webrequest:PAInitiationWebRequest = this.epaProcessor.extractPAInitiationRequestFromSelectMadicine(claimresponse);
      debugger;
      webrequest.infsnInfoId = ""+parseInt(this.profileService.loginuser.selectedInfusion.getValue().infsnId);
      webrequest.pAReferenceID = paRef.paRefID;
      
      this.modalService.open(this.paInitiationTemplateRef,{size:'md'});
      this.epaProcessor.submitPAInitiationRequest(webrequest).then((response:EpaInitiationAckWebResponse)=>{
        this.epaState = EPA_STATUS.PA_INITIATION_SUCCESS;
        //Below timeout is for initial delay before start pooling the DB for Questionset availability.
        setTimeout(()=>{
          this.startPoolingQuestionSet(paRef);
        },1000);
      },(error:any)=>this.handleAPIError(error)).catch((error:any)=>this.handleAPIException(error));
    }
  }
  openfaxforms():void{
    this.logger.userAction("View Fax Forms clicked.");
    window.open(environment.faxForm, "_blank");
  }
  goToEPAQuestions():void{
    if(this.epaState == EPA_STATUS.PA_QUESTIONNARIE_TIMELINE){
      this.logger.userAction("View EPA Question Clicked");
      this.modalService.dismissAll();
      this.router.navigateByUrl("/ePA/questions");
    }
  }
  goToHome():void{
    this.logger.userAction("Routing to Home Page");
    this.router.navigateByUrl("/home");
  }
  ngOnDestroy():void{
    if(this.epaTimerSubscription!=undefined){
      this.epaTimerSubscription.unsubscribe();
    }
    this.epaProcessor.cancelAllSubscriptions();
    this.subscription.unsubscribe();
  }
}
