import { Component, OnInit, ViewChild } from '@angular/core';
import {Router} from '@angular/router';

import { ViewAllRouteState, PAGE_TYPE } from 'src/app/constants/routeconstants';
import { VIEWALL_DATERANGE_LEBELS, VIEWALL_DATERANGE, VIEWALL_SUBMITTEDPA_STATUS, VIEWALL_SUBMITTEDPA_STATUS_LABELS, VIEWALL_SAVEDPA_STATUS_LABELS , VIEWALL_SAVEDPA_STATUS} from 'src/app/constants/appconstant';
import { DateFieldEvent, DateFieldResultType, DateFieldValidationMode } from 'src/app/modals/datefield/datefield';

import { SubmittedpahistoryComponent } from 'src/app/components/submittedpahistory/submittedpahistory.component';
import { SavedpahistoryComponent } from 'src/app/components/savedpahistory/savedpahistory.component';
import { DatefieldComponent } from 'src/app/components/datefield/datefield.component';
import { MembersearchService } from 'src/app/services/membersearch/membersearch.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { Title } from '@angular/platform-browser';

class SubmittedPAHistory{
  
  blnShowFromDate:boolean;
  private _fromDateValue : string="";
  public get fromDateValue() : string {
    return this._fromDateValue;
  }
  public set fromDateValue(v : string) {
    this._fromDateValue = v;
    if((new Date(v)).toString()!="Invalid Date")
      this.fromDate = new Date(v);
  }
  fromDate:Date=new Date();//Different from fromDateValue. This fromDate is used to bind to history component where fromDateValue is used for validation on datefield.
  fromDateResult: DateFieldResultType = DateFieldResultType.EMPTY;
  fromDateValidationMode: DateFieldValidationMode = DateFieldValidationMode.ONBLUR;
  onFromDateUpdate(event: DateFieldEvent): void {
    this.fromDateValue = event.value;
    this.fromDateResult = event.valid;
  }

  blnCustomDateFormSubmitted:boolean=false;
  dateFormatPlaceHolder:string="MM/DD/YYYY";

  
  private _toDateValue : string="";
  public get toDateValue() : string {
    return this._toDateValue;
  }
  public set toDateValue(v : string) {
    this._toDateValue = v;
    if((new Date(v)).toString()!="Invalid Date")
      this.toDate = new Date(v);
  }
  toDate:Date = new Date();//Different from toDateValue. This toDate is used to bind to history component where toDateValue is used for validation on datefield.
  toDateResult: DateFieldResultType = DateFieldResultType.EMPTY;
  toDateValidationMode: DateFieldValidationMode = DateFieldValidationMode.ONBLUR;
  onToDateUpdate(event: DateFieldEvent): void {
    this.toDateValue = event.value;
    this.toDateResult = event.valid;
  }

  dateRange:Map<number,string> = VIEWALL_DATERANGE_LEBELS;
  
  blnDateChanged:boolean;
  private _selectedDate !: number;
  public get selectedDate() : number {
    return this._selectedDate;
  }
  public set selectedDate(v : number) {
    this.blnShowFromDate=false;
    this._selectedDate = v;
    this.blnDateChanged=true;
    if(parseInt(v.toString())!=VIEWALL_DATERANGE.CUSTOM_DATE){
      //Today should represent either 11:59:59PM of today or 12AM of next day - 12AM of next day would be easy to code.
      let today:Date = new Date();
      today.setDate(today.getDate()+1);
      this.toDate = today;

      this.fromDate = new Date();
      switch(parseInt(v.toString())){
        case VIEWALL_DATERANGE.DAYS_THIRTY:
          this.fromDate.setDate(this.fromDate.getDate()- 30);
          break;
        case VIEWALL_DATERANGE.DAYS_SIXTY:
          this.fromDate.setDate(this.fromDate.getDate()-60);
          break;
        case VIEWALL_DATERANGE.MONTHS_SIX:
          this.fromDate.setMonth(this.fromDate.getMonth()-6);
          break;
        case VIEWALL_DATERANGE.YEARS_ONE:
          this.fromDate.setFullYear(this.fromDate.getFullYear()-1);
          break;
      }
      
    } else {
      this.fromDateValue="";
      this.toDateValue="";
      this.fromDateResult= DateFieldResultType.EMPTY;
      this.toDateResult = DateFieldResultType.EMPTY;
      this.blnCustomDateFormSubmitted=false;
      setTimeout(()=>{
        this.updateCustomSelectedDate();
        this.blnShowFromDate=true;
      },200);
      
    }
  }
  updateCustomSelectedDate():void{
    if(this.fromDateResult == DateFieldResultType.SUCCESS && this.fromDateValue!=undefined && this.fromDateValue.length>0){
      this.fromDate = new Date(this.fromDateValue);
    }
    if(this.toDateResult == DateFieldResultType.SUCCESS &&  this.toDateValue!=undefined && this.toDateValue.length>0){
      this.toDate = new Date(this.toDateValue);
    }
  }

  statuses:Map<number, string> = VIEWALL_SUBMITTEDPA_STATUS_LABELS;
  selectedStatus:number = VIEWALL_SUBMITTEDPA_STATUS.ALL;

  searchText:string="";

  constructor(){
    this.selectedDate = VIEWALL_DATERANGE.DAYS_THIRTY;
    this.blnDateChanged=false;
    this.blnShowFromDate=false;
  }
}

class SavedPAHistory{

  searchText:string="";

  savedStatus: Map<number,string>=VIEWALL_SAVEDPA_STATUS_LABELS;
  blnStatusChanged:boolean=false;
  
  private _selectedStatus : number = VIEWALL_SAVEDPA_STATUS.VIEW_ALL;
  public get selectedStatus() : number {
    return this._selectedStatus;
  }
  public set selectedStatus(v : number) {
    this.blnStatusChanged=true;
    this._selectedStatus = v;
  }
  

}

@Component({
  selector: 'app-viewaall',
  templateUrl: './viewaall.component.html',
  styleUrls: ['./viewaall.component.css']
})
export class ViewaallComponent implements OnInit {

  state!:ViewAllRouteState;
  memberId!:any;

  memberSpecific!:any;

  submittedHistory:SubmittedPAHistory;
  savedHistory:SavedPAHistory;

  @ViewChild(SubmittedpahistoryComponent,{static:false})
  private submittedHistoryRef!:SubmittedpahistoryComponent;

  @ViewChild(SavedpahistoryComponent, {static:false})
  private savedHistoryRef!:SavedpahistoryComponent;

  @ViewChild("FromDate",{static:false})
  private FromDateComponent!: DatefieldComponent;

  @ViewChild("ToDate",{static:false})
  ToDateComponent!: DatefieldComponent;


  constructor(private router:Router,private memberSearchHelper: MembersearchService,private logger: LoggerService,private titleService:Title) {
    this.submittedHistory = new SubmittedPAHistory();
    this.savedHistory = new SavedPAHistory();
  }

  ngOnInit(): void {

    try{
      if (this.memberSearchHelper.getSelectedMember() != undefined) {
        this.memberSpecific="MemberSpecificResults"
      }

      this.state = history.state;
      if(this.state.pageType==PAGE_TYPE.SAVEDPAS){
        const savedState:ViewAllRouteState = {pageType:PAGE_TYPE.SAVEDPAS};
        sessionStorage.setItem("state",JSON.stringify(savedState));

      }else if(this.state.pageType==PAGE_TYPE.SUBMITTEDPAS){
        const submittedState:ViewAllRouteState = { pageType: PAGE_TYPE.SUBMITTEDPAS };
        sessionStorage.setItem("state",JSON.stringify(submittedState));
      }
      sessionStorage.setItem("lastPageBeforeReload", this.router.url);
      
      if(this.memberSearchHelper.getSelectedMember()!=undefined){
        this.memberId=this.memberSearchHelper.getSelectedMember()?.memberId;
      }
    } catch(error){

    }
  }

  goto(routerUrl:string){
    this.logger.userAction("Goto Home Page");
    this.router.navigateByUrl("/"+ routerUrl);
  }

  filterHistory():void{
    // //debugger;
   // if(this.state.pageType == PAGE_TYPE.SUBMITTEDPAS){
      this.submittedHistory.blnCustomDateFormSubmitted=false;
      if(parseInt(this.submittedHistory.selectedDate.toString())==VIEWALL_DATERANGE.CUSTOM_DATE){
        this.submittedHistory.blnCustomDateFormSubmitted=true;
        this.ToDateComponent.onFocusOut();
        this.FromDateComponent.onFocusOut();
      setTimeout(()=>{
        if(this.submittedHistory.fromDateResult == DateFieldResultType.SUCCESS
          && this.submittedHistory.toDateResult == DateFieldResultType.SUCCESS){
            this.submittedHistoryRef.reload(this.submittedHistory.blnDateChanged);
            this.submittedHistory.blnDateChanged=false;
          }
      },250);
    } 
  else {
     if(this.savedHistory.blnStatusChanged==false){
      this.submittedHistoryRef.reload(this.savedHistory.blnStatusChanged);
      this.savedHistory.blnStatusChanged=false;
     }
     else{
      this.savedHistoryRef.reload(this.savedHistory.blnStatusChanged);
       this.savedHistory.blnStatusChanged=true;
     }
    }  
  }
}
