import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {

  translation: Object = {};

  constructor(protected router: Router) {
    sessionStorage.setItem("lastPageBeforeReload", this.router.url);

  }

  ngOnInit(): void {
  }
  goToPrivacyLink(url: string) {
    window.open(url, "_blank","noopener noreferrer");
  }
  goToTermsLink(url: string) {
    // //debugger;
    window.open(url, "_blank","noopener noreferrer");
  }
  goToContactusLink(url: string) {
    // //debugger;
    window.open(url, "_blank");
  }

}
