import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DateFieldEvent, DateFieldValidationMode, DateFieldResultType } from '../../modals/datefield/datefield';
import  { TimeremaininghoursPipe } from '../../pipes/timeremaining/timeremaining.pipe';

@Component({
  selector: 'datefield',
  templateUrl: './datefield.component.html',
  styleUrls: ['./datefield.component.css']
})
export class DatefieldComponent implements OnInit {

  @Input()
  content:string="";
  @Input()
  placeHolder:string="";
  @Input()
  futureDaysAllowed:number=0;
  @Input()
  futureMonthsAllowed:number=0;
  @Input()
  validationMode!: DateFieldValidationMode;

  @Input()
  dateFieldName!:string;

  @Input()
  toDateComponent!:DatefieldComponent;

  private _emptyValid !: boolean;
  public get emptyValid() : boolean {
    return this._emptyValid;
  }
  @Input()
  public set emptyValid(v : boolean) {
    this._emptyValid = v;
    this.onFocusOut(undefined);
  }

  @Output()
  onUpdate:EventEmitter<DateFieldEvent>=new EventEmitter<DateFieldEvent>();

  show:boolean=true;
  valid:DateFieldResultType=DateFieldResultType.SUCCESS;
  datepattern:RegExp = /^(0[1-9]|1[0-2]){1,2}\/(0[1-9]|1\d|2\d|3[01]){1,2}\/(19|[2-9][0-9])\d{2}$/;
  datevalidCharPattern:RegExp = /^([0-9\/]+)$/i;

  constructor(private timeremaining:TimeremaininghoursPipe) { 
    this.dateFieldName="Date of birth";
    this.validationMode = DateFieldValidationMode.ONBLUR;
  }

  ngOnInit(): void {
    this.checkRequiredFields([this.validationMode,this.emptyValid]);
    if(this.show){
      if(this.futureDaysAllowed!=0 && this.futureMonthsAllowed!=0){
        this.show=false;
        this.onUpdate.emit({value:this.content,valid: DateFieldResultType.ERROR});
        throw new Error("Either one of future days or future months on date field is allowed.");
      }
    }
  }
  private checkRequiredFields(inputs:any[]) {    
    inputs.forEach( (element:any)=>{
      if(element === null || element === undefined){
        this.show=false;
      }
    });
    if(!this.show){
       this.onUpdate.emit({value:this.content,valid: DateFieldResultType.ERROR});
       throw new Error("One of the madatory input property in the list [validationMode, emptyInvalid] on date picker component is missing.");
    }
  }

  /*
   * This method performs below additional validations which are not possible by regular expression
   * Returns 0 or 1 or 2 or 3
   * 0. Means all these custom validations against given date are passed.
   * 1. Represents the day entered by the user not in the range of 0-28, 0-29, 0-30 or 0-31 based on given month and year. 
   * 2. User entered future date but the component is not configured to allow any future date.
   * 3. User entered future date but it is beyond the range accepted by the component.
   */
  private isDateBounded(formattedDate:string):number{
    let errorType:number=DateFieldResultType.SUCCESS;////debugger
    if(formattedDate!=undefined && formattedDate.length>0){
        //Entered date would be in the format of mm/dd/yyyy
        let givenDate:string[] = formattedDate.split("/");
        let month:number = parseInt(givenDate[0]);
        let day:number = parseInt(givenDate[1]);
        let year:number = parseInt(givenDate[2]);
        switch(month){
          case 1:
          case 3:
          case 5:
          case 7:
          case 8:
          case 10:
          case 12:
            errorType = (day<=31)?DateFieldResultType.SUCCESS:DateFieldResultType.INVALID_DAY_IN_MONTH;
            break;
          case 2:
            let possibledays = ( (year%400)==0 || (year%4)==0)?29:28;
            errorType = (day<=possibledays)?DateFieldResultType.SUCCESS:DateFieldResultType.INVALID_DAY_IN_MONTH;
            break;
          default:
            errorType = (day<=30)?DateFieldResultType.SUCCESS:DateFieldResultType.INVALID_DAY_IN_MONTH;
            break;
        }
        if(errorType==0){
          //No error found so far.
          let timedifferenceinhours:number = this.timeremaining.transform(new Date(formattedDate).getTime().toString(),new Date().getTime().toString());
          if(timedifferenceinhours>0){
            if(this.futureDaysAllowed>0){
              let timedifferenceindays:number = parseFloat((timedifferenceinhours/24).toFixed(3));
              errorType = timedifferenceindays<=this.futureDaysAllowed?DateFieldResultType.SUCCESS:DateFieldResultType.FUTURE_DATE_BEYOND_GIVENDAYS;
            } else {
              if(this.futureMonthsAllowed>0){
                let allowedFutureDate = new Date();
                allowedFutureDate.setMonth(allowedFutureDate.getMonth()+this.futureMonthsAllowed);
                const givenFutureDate:Date = new Date(formattedDate);
                if(givenFutureDate>allowedFutureDate)
                  errorType = DateFieldResultType.FUTURE_DATE_BEYOND_GIVENMONTHS;
                else
                  errorType = DateFieldResultType.SUCCESS;
              } else {
                errorType = DateFieldResultType.FUTURE_DATE_NOT_ALLOWED;
              }
            }
          } else {
              //Not it surely a valid past or current date.
              errorType = DateFieldResultType.SUCCESS;
          }
          if(errorType==DateFieldResultType.SUCCESS && this.toDateComponent!=undefined && this.toDateComponent.valid==DateFieldResultType.SUCCESS){
            //still no error found so far but found secondary date component whose date always has to be greater than this component date.
            if(new Date(this.getFormatedDate())>new Date(this.toDateComponent.getFormatedDate())){
              errorType = DateFieldResultType.FROM_DATE_INVALID;
            }
          }
        }
    }
    return errorType;
  }

  getFormatedDate():string{
    if(this.content!=undefined && this.content.length>0 && this.content.split("/").length==3){
        let givenDate:string[] = this.content.split("/");
        let month:string = givenDate[0];
        let day:string = givenDate[1];
        let year:string = givenDate[2];
        
        if(parseInt(day)<10 && day.length==1)
          day = "0"+day;
        
        if(parseInt(month)<10 && month.length==1)
          month = "0"+month;
        
        return month+"/"+day+"/"+year;
    } else {
      return this.content;
    }
  }

  onFocusOut(event?:any):void{
    let formattedDate = this.getFormatedDate();
    if(formattedDate!=undefined && formattedDate.length>0){
      if(!this.datevalidCharPattern.test(formattedDate)){
        this.valid = DateFieldResultType.INVALID_CHAR;
      } else {
        if(!this.datepattern.test(formattedDate)){
          this.valid = DateFieldResultType.INVALID_DATE
        } else {
          this.valid=this.isDateBounded(formattedDate);
        }
      }
    } else {
      this.valid = (this.emptyValid)? DateFieldResultType.SUCCESS: DateFieldResultType.EMPTY;
    }
    this.onUpdate.emit({
      value:formattedDate, 
      validationMode:DateFieldValidationMode.ONBLUR,
      valid: this.valid
    });    
  }

  private autoFormatContentOnKeyUp() : void{
    if(this.content!=undefined && this.content.length>0){
      let month:string="";
      let year:string="";
      let day:string="";
      let blnAppendForMonth:boolean=false;
      let blnAppendForDay:boolean=false;
      if(this.content.indexOf("/")!=-1){
        const currentValues:Array<string> = this.content.split("/");
        if(currentValues.length>=3){
          month = currentValues[0];
          
          if(month.length>0 && parseInt(month)<10)
            month="0"+parseInt(month);
          
          day=currentValues[1];
          if(day.length>0 && parseInt(day)<10)
            day="0"+parseInt(day);
          
          year = currentValues[2];

          this.content=month+"/"+day+"/"+year;
        } else {
          if(currentValues[0].length>2){
            month = currentValues[0].substring(0,2);
            if(parseInt(month)<10)
              month = "0"+parseInt(month);
            
            day = currentValues[0].substring(2, currentValues[0].length);
            if(day.length>0 && parseInt(day)<10)
              day="0"+parseInt(day);
            
            year = currentValues[1];

            this.content = month+"/"+day+"/"+year;
          } else {
            if(currentValues[1].length>2){
              month = currentValues[0];
              if(parseInt(month)<10)
                month="0"+parseInt(month);
              
              day = currentValues[1].substring(0,2);
              if(parseInt(day)<10)
                day="0"+parseInt(day);
              
              year = currentValues[1].substring(2,currentValues[1].length);
              
              this.content = month+"/"+day+"/"+year;
            } else {
              month=currentValues[0];
              if(month.length>0 && parseInt(month)<10)
                month="0"+parseInt(month);
              
              day=currentValues[1];
              
              this.content = month+"/"+day;
              if(day.length==2)
                this.content+="/";
            }
          }
        }
      } else {
        var currentValue = this.content;
        if(currentValue.length==2){
          if(parseInt(currentValue)<10)
            this.content ="0"+parseInt(currentValue);
          this.content+="/";
        } else{
          if(currentValue.length>2){
            this.content=currentValue.substring(0,2)+"/";
            if(currentValue.length>=4){
              this.content += currentValue.substring(2,4)+"/"+currentValue.substring(4,currentValue.length);
            } else {
              this.content += currentValue.substring(2,currentValue.length);
            }
          }
        }
      }
    }
  }

  /**
   * This method validate the format and add slashes automatically to date.
   * @param event 
   */
  onChange(event:any):void{
    
    // Adding slashes automatically
    // if(this.content.length==2 || this.content.length==5){
    //   this.content=this.content+"/";
    // }
    
    if (event.key === 'Backspace' || event.keyCode === 8) {
      console.log('Backspace was pressed');
    }else{
      this.autoFormatContentOnKeyUp();
    }

    let formattedDate = this.getFormatedDate();
    let blnValid:boolean=true;
    if(this.emptyValid){
      if(this.datevalidCharPattern.test(formattedDate) || (formattedDate==undefined || formattedDate.length==0))
        this.valid = DateFieldResultType.SUCCESS;
      else {
        this.valid = DateFieldResultType.INVALID_CHAR;
      }
    } else {
      if(this.datevalidCharPattern.test(formattedDate) && (formattedDate!=undefined && formattedDate.length>0))
        this.valid = DateFieldResultType.SUCCESS;
      else {
        if(formattedDate!=undefined && formattedDate.length>0)
          this.valid = DateFieldResultType.INVALID_CHAR;
        else
          this.valid = DateFieldResultType.EMPTY;
      }
    }

    

    this.onUpdate.emit({
      valid:this.valid,
      value:this.content,
      validationMode:DateFieldValidationMode.ONCHANGE
    });
  }

}
