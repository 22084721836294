import { Component, OnInit, Input, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { DateFieldEvent, DateFieldResultType, DateFieldValidationMode } from 'src/app/modals/datefield/datefield';
import { VIEWALL_DATERANGE_LEBELS, VIEWALL_DATERANGE } from 'src/app/constants/appconstant';
import { DatefieldComponent } from 'src/app/components/datefield/datefield.component';
import { TableCellData, TableRowData, TableHeaderCellData, TableClickEvent, DataTableType } from 'src/app/modals/datatable/datatable';
import { Claimhistorywebrequest, SavedClaimHistory, ClaimBookmarkWebRequest, ClaimUpdateWebRequest } from 'src/app/modals/claimhistory/claimhistorywebrequest';
import { ClaimDetailsHistory, Claimhistorywebresponse, ClaimTransactionHistoryResponse } from 'src/app/modals/claimhistory/claimhistorywebresponse';
import { DatetoDisplayString } from 'src/app/pipes/dateconversion/dateconversion.pipe';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { ClaimhistoryService } from 'src/app/services/claimhistory/claimhistory.service';
import { CheckCoveragePageService } from 'src/app/services/drugSearch/check-coverage-page.service';
import { Infusion } from 'src/app/modals/login/loginwebresponse';
import { CLAIM_STATUS_TYPE, BOOLEAN_FLAG, APPCONSTANTS } from 'src/app/constants/appconstant';
import { FilterobjectsByTextPipe } from 'src/app/pipes/filterobjects/filterobjects.pipe';
import { TimeStampToStandardDate } from 'src/app/pipes/dateconversion/dateconversion.pipe';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { PAGE_TYPE, ViewAllRouteState } from 'src/app/constants/routeconstants';
import { Status } from 'src/app/modals/webresponsestatus';
import { Router, RouterModule } from '@angular/router';
import { SelectedMedicine } from 'src/app/modals/drugSearch/drugsearchwebresponse';
import { LstClaim } from 'src/app/modals/drugSearch/SaveTrailClaimRequest';
import { RefreshRequest, ClaimAdvanceDetailWebRequest } from 'src/app/modals/claimhistory/claimrefreshwebrequest';
import { RefreshResponse, ClaimAdvanceDetailWebResponse } from 'src/app/modals/claimhistory/claimrefreshwebresponse';
import { trigger, state, transition, animate, style } from '@angular/animations';
import { LoggerService } from 'src/app/services/logger/logger.service';


class SavedClaimHistoryViewModal {

  blnShowFromDate: boolean;
  private _fromDateValue: string = "";
  public get fromDateValue(): string {
    return this._fromDateValue;
  }
  public set fromDateValue(v: string) {
    this._fromDateValue = v;
    if ((new Date(v)).toString() != "Invalid Date")
      this.fromDate = new Date(v);
  }
  fromDate: Date = new Date();//Different from fromDateValue. This fromDate is used to bind to history component where fromDateValue is used for validation on datefield.
  fromDateResult: DateFieldResultType = DateFieldResultType.EMPTY;
  fromDateValidationMode: DateFieldValidationMode = DateFieldValidationMode.ONBLUR;
  onFromDateUpdate(event: DateFieldEvent): void {
    this.fromDateValue = event.value;
    this.fromDateResult = event.valid;
    this.blnDateChanged = true;
  }

  blnCustomDateFormSubmitted: boolean = false;
  dateFormatPlaceHolder: string = "MM/DD/YYYY";
  searchText: string = "";

  private _toDateValue: string = "";
  public get toDateValue(): string {
    return this._toDateValue;
  }
  public set toDateValue(v: string) {
    this._toDateValue = v;
    if ((new Date(v)).toString() != "Invalid Date")
      this.toDate = new Date(v);
  }
  toDate: Date = new Date();//Different from toDateValue. This toDate is used to bind to history component where toDateValue is used for validation on datefield.
  toDateResult: DateFieldResultType = DateFieldResultType.EMPTY;
  toDateValidationMode: DateFieldValidationMode = DateFieldValidationMode.ONBLUR;
  onToDateUpdate(event: DateFieldEvent): void {
    this.toDateValue = event.value;
    this.toDateResult = event.valid;
    this.blnDateChanged = true;
  }

  dateRange: Map<number, string> = VIEWALL_DATERANGE_LEBELS;

  blnDateChanged: boolean;
  private _selectedDate !: number;
  public get selectedDate(): number {
    return this._selectedDate;
  }
  public set selectedDate(v: number) {
    this._selectedDate = v;
    this.blnDateChanged = true;
    if (parseInt(v.toString()) != VIEWALL_DATERANGE.CUSTOM_DATE) {
      //Today should represent either 11:59:59PM of today or 12AM of next day - 12AM of next day would be easy to code.
      let today: Date = new Date();
      today.setDate(today.getDate() + 1);
      this.toDate = today;

      this.fromDate = new Date();
      switch (parseInt(v.toString())) {
        case VIEWALL_DATERANGE.DAYS_THIRTY:
          this.fromDate.setDate(this.fromDate.getDate() - 30);
          break;
        case VIEWALL_DATERANGE.DAYS_SIXTY:
          this.fromDate.setDate(this.fromDate.getDate() - 60);
          break;
        case VIEWALL_DATERANGE.MONTHS_SIX:
          this.fromDate.setMonth(this.fromDate.getMonth() - 6);
          break;
        case VIEWALL_DATERANGE.YEARS_ONE:
          this.fromDate.setFullYear(this.fromDate.getFullYear() - 1);
          break;
      }

    } else {
      this.fromDateValue = "";
      this.toDateValue = "";
      this.fromDateResult = DateFieldResultType.EMPTY;
      this.toDateResult = DateFieldResultType.EMPTY;
      this.blnCustomDateFormSubmitted = false;
      setTimeout(() => {
        this.blnShowFromDate = true;
        this.updateCustomSelectedDate();
      }, 200);
    }
  }
  updateCustomSelectedDate(): void {
    if (this.fromDateResult == DateFieldResultType.SUCCESS && this.fromDateValue != undefined && this.fromDateValue.length > 0) {
      this.fromDate = new Date(this.fromDateValue);
    }
    if (this.toDateResult == DateFieldResultType.SUCCESS && this.toDateValue != undefined && this.toDateValue.length > 0) {
      this.toDate = new Date(this.toDateValue);
    }
  }

  constructor() {
    this.selectedDate = VIEWALL_DATERANGE.DAYS_THIRTY;
    this.blnDateChanged = false;
    this.blnShowFromDate = false;
  }
}



@Component({
  selector: 'savedclaimhistory',
  templateUrl: './savedclaimhistory.component.html',
  styleUrls: ['./savedclaimhistory.component.css']
})
export class SavedclaimhistoryComponent implements OnInit {

  private lastOperation!: BOOLEAN_FLAG;
  sourceData: SavedClaimHistory[];

  private fieldsToConsiderForFilteration: string[];
  private firstHeader: TableHeaderCellData = new TableHeaderCellData();
  private secondHeader: TableHeaderCellData = new TableHeaderCellData();
  private thirdHeader: TableHeaderCellData = new TableHeaderCellData();
  private forthHeader: TableHeaderCellData = new TableHeaderCellData();
  private fifthHeader: TableHeaderCellData = new TableHeaderCellData();
  private sixthHeader: TableHeaderCellData = new TableHeaderCellData();
  private seventHeader: TableHeaderCellData = new TableHeaderCellData();
  private eightHeader: TableHeaderCellData = new TableHeaderCellData();


  itemToBeDeleted!: SavedClaimHistory;

  @Input()
  pageType!: PAGE_TYPE;

  @Input()
  isHomePage: boolean = false;

  @Input()
  showStatus: boolean = false;

  @Input()
  memberId!: string;

  @Input()
  bookmarked: boolean = false;

  @ViewChild("errorPopup", { static: true })
  errorPopupTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("dropdown", { static: true })
  dropdownTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("memberNameTemplate", { static: true })
  memberNameTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("drugNameTemplate", { static: true })
  drugNameTemplateRef?: TemplateRef<HTMLElement>

  @ViewChild("prescriberNameTemplate", { static: true })
  prescriberNameTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("dateTemplate", { static: true })
  dateTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("userNameTemplate", { static: true })
  userNameTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("selectIcon", { static: true })
  selectIconTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("bookmarkIconTemplate", { static: false })
  bookmarkIconTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("refreshIcon", { static: true })
  refreshIconTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("bookmarkDeleteTemplate", { static: false })
  bookmarkDeleteTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("historyServerErrorTemplate", { static: true })
  historyServerErrorTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("bookmarkError", { static: false })
  bookmarkErrorElementRef?: ElementRef<HTMLElement>;

  @ViewChild("FromDate", { static: false })
  private FromDateComponent!: DatefieldComponent;

  @ViewChild("ToDate", { static: false })
  ToDateComponent!: DatefieldComponent;

  @ViewChild("secondRowTemplate", { static: true })
  secondRowTemplate?: TemplateRef<any>

  @ViewChild("removeMedicationConfirmPopup", { static: true })
  removeMedicationConfirmPopup?: TemplateRef<any>

  @ViewChild("removeTransactionConfirmPopup", { static: true })
  removeTransactionConfirmPopup?: TemplateRef<any>

  @ViewChild("LoadingContent")
  loadingContent?: ElementRef<HTMLElement>;


  savedClaimHeaders: TableHeaderCellData[];
  savedClaimHistory: TableRowData<SavedClaimHistory>[];
  savedClaimsHistoryViewModal: SavedClaimHistoryViewModal;
  selectedClaim!: SavedClaimHistory;
  claimreqObj!: ClaimAdvanceDetailWebRequest[];

  datatableType: DataTableType = DataTableType.TRANSPARENT_COLLAPSABLE;
  blnLoading: boolean;
  blnServerError: boolean;

  totalPages: number;
  totalEntries: number;
  currentPage: number;
  recordsPerPage: number = environment.paginationRecordsPerPage;
  refreshStatusTxt: string = "";
  previousItemClicked!: TableClickEvent<SavedClaimHistory>;

  //this currentPageURL variable is to modify the table as per page needs.
  @Input() currentPageURL: string = "";

  constructor(private loginProfile: ProfileService,
    private trailClaimServiceHelper: CheckCoveragePageService,
    private claimHistoryService: ClaimhistoryService,
    private filterobjectsByTextPipe: FilterobjectsByTextPipe,
    private timeStampToStandardDate: TimeStampToStandardDate,
    private modalService: NgbModal,
    private datetoDisplayString: DatetoDisplayString,
    private router: Router,
    private logger: LoggerService) {
    this.savedClaimHeaders = [];
    this.savedClaimHistory = [];
    this.sourceData = [];
    this.savedClaimsHistoryViewModal = new SavedClaimHistoryViewModal();
    this.blnLoading = false;
    this.blnServerError = false;
    this.totalPages = 1;
    this.totalEntries = 0;
    this.currentPage = 1;

    this.firstHeader.content = "More";
    this.secondHeader.content = "Member";
    this.secondHeader.cssClass = "paddingleft";
    this.thirdHeader.content = "Transaction ID";
    this.forthHeader.content = "Prescriber";
    this.fifthHeader.content = "Date";
    this.sixthHeader.content = "User";
    // this.seventHeader.cssClass="tablesmallheader25";

    if (this.pageType == PAGE_TYPE.SUBMITTEDCLAIMS) {
      this.seventHeader.content = "Action";
      this.seventHeader.cssClass = "text-center";
    } else {
      this.pageType = PAGE_TYPE.SAVEDCLAIMS;
      this.seventHeader.content = "Action";
      this.seventHeader.cssClass = "text-center";
    }
    this.eightHeader.content = "";
    this.eightHeader.cssClass = "tablesmallheader25";


    this.savedClaimHeaders.push(this.firstHeader);
    this.savedClaimHeaders.push(this.secondHeader);
    this.savedClaimHeaders.push(this.thirdHeader);
    this.savedClaimHeaders.push(this.forthHeader);
    this.savedClaimHeaders.push(this.fifthHeader);
    this.savedClaimHeaders.push(this.sixthHeader);
    this.savedClaimHeaders.push(this.seventHeader);
    this.savedClaimHeaders.push(this.eightHeader);


    this.fieldsToConsiderForFilteration = [];
    this.fieldsToConsiderForFilteration.push("memberLastName");
    this.fieldsToConsiderForFilteration.push("memberFirstName");
    this.fieldsToConsiderForFilteration.push("productName");
    this.fieldsToConsiderForFilteration.push("prescriberLastName");
    this.fieldsToConsiderForFilteration.push("prescriberFirstName");
    this.fieldsToConsiderForFilteration.push("standardDate");
    this.fieldsToConsiderForFilteration.push("userLastName");
    this.fieldsToConsiderForFilteration.push("userFirstName");

  }

  private getCurrentPageSavedClaimHistory(): SavedClaimHistory[] {
    // //debugger;
    const startIndex: number = (this.currentPage - 1) * this.recordsPerPage;
    const endIndex: number = (this.currentPage * this.recordsPerPage);
    const filteredSourceData: SavedClaimHistory[] = this.filterobjectsByTextPipe.transform(this.sourceData, this.savedClaimsHistoryViewModal.searchText, this.fieldsToConsiderForFilteration);
    this.totalEntries = filteredSourceData.length;

    this.totalPages = (filteredSourceData.length % this.recordsPerPage) != 0 ? Math.floor(filteredSourceData.length / this.recordsPerPage) + 1 : Math.floor(filteredSourceData.length / this.recordsPerPage);

    return filteredSourceData.slice(startIndex, endIndex);

  }

  private generateRows(): void {
    // //debugger;
    this.savedClaimHistory = [];
    this.blnLoading = false;
    if (!this.blnServerError) {

      this.getCurrentPageSavedClaimHistory().forEach((history: SavedClaimHistory) => {
        const row: TableRowData<SavedClaimHistory> = new TableRowData<SavedClaimHistory>();
        const seventhCell: TableCellData<SavedClaimHistory> = new TableCellData<SavedClaimHistory>();
        seventhCell.data = history;
        seventhCell.cssClass = "border border-1 paddingleft textcapitalize";
        seventhCell.template = this.dropdownTemplateRef;
        row.cells.push(seventhCell);

        const firstCell: TableCellData<SavedClaimHistory> = new TableCellData<SavedClaimHistory>();
        firstCell.data = history;
        firstCell.cssClass = "paddingleft textcapitalize";
        firstCell.template = this.memberNameTemplateRef;
        row.cells.push(firstCell);

        const secondCell: TableCellData<SavedClaimHistory> = new TableCellData<SavedClaimHistory>();
        secondCell.data = history;
        secondCell.cssClass = "paddingleft textcapitalize";
        secondCell.template = this.drugNameTemplateRef;
        row.cells.push(secondCell);

        const thirdCell: TableCellData<SavedClaimHistory> = new TableCellData<SavedClaimHistory>();
        thirdCell.data = history;
        thirdCell.cssClass = "paddingleft textcapitalize";
        thirdCell.template = this.prescriberNameTemplateRef;
        row.cells.push(thirdCell);

        const forthCell: TableCellData<SavedClaimHistory> = new TableCellData<SavedClaimHistory>();
        forthCell.data = history;
        forthCell.cssClass = "paddingleft textcapitalize";
        forthCell.template = this.dateTemplateRef;
        row.cells.push(forthCell);

        const fifthCell: TableCellData<SavedClaimHistory> = new TableCellData<SavedClaimHistory>();
        fifthCell.data = history;
        fifthCell.cssClass = "paddingleft textcapitalize";
        fifthCell.template = this.userNameTemplateRef;
        row.cells.push(fifthCell);

        if (this.pageType == PAGE_TYPE.BOOKMARKCLAIMS) {
          ////debugger;
          const sixthCell: TableCellData<SavedClaimHistory> = new TableCellData<SavedClaimHistory>();
          sixthCell.data = history;
          sixthCell.cssClass = "float-end";
          sixthCell.template = this.bookmarkDeleteTemplateRef;
          sixthCell.clickable = false;
          row.cells.push(sixthCell);
          const eightCell: TableCellData<SavedClaimHistory> = new TableCellData<SavedClaimHistory>();
          eightCell.data = history;
          eightCell.template = this.selectIconTemplateRef;
          row.cells.push(eightCell);

        } if (this.pageType == PAGE_TYPE.SUBMITTEDCLAIMS) {
          const sixthCell: TableCellData<SavedClaimHistory> = new TableCellData<SavedClaimHistory>();
          sixthCell.data = history;
          sixthCell.cssClass = "float-end";
          sixthCell.rowspan = 1;
          sixthCell.template = this.refreshIconTemplateRef;
          sixthCell.clickable = false;
          row.cells.push(sixthCell);
          const eightCell: TableCellData<SavedClaimHistory> = new TableCellData<SavedClaimHistory>();
          eightCell.data = history;
          eightCell.template = this.selectIconTemplateRef;
          row.cells.push(eightCell);

        } else {
          const sixthCell: TableCellData<SavedClaimHistory> = new TableCellData<SavedClaimHistory>();
          sixthCell.data = history;
          sixthCell.cssClass = "float-end";
          sixthCell.rowspan = 1;
          sixthCell.template = this.bookmarkIconTemplateRef;
          sixthCell.clickable = false;
          row.cells.push(sixthCell);
        }

        this.savedClaimHistory.push(row);

        const secondRow = new TableRowData<SavedClaimHistory>();
        const secondRowCell = new TableCellData<SavedClaimHistory>();
        secondRow.disabled = true;
        secondRow.blnShow = false;
        secondRowCell.clickable = false;
        secondRowCell.data = history;
        secondRowCell.colspan = row.cells.length;
        secondRowCell.cssClass = 'drugDetailSection';
        secondRowCell.template = this.secondRowTemplate;
        secondRow.cssClass = 'border border-1 drugDetailSection';
        secondRow.cells.push(secondRowCell);
        this.savedClaimHistory.push(secondRow);
      });
    } else {
      this.totalPages = 1;
      this.currentPage = 1;

      const row: TableRowData<SavedClaimHistory> = new TableRowData<SavedClaimHistory>();
      row.disabled = true;
      row.cssClass = "norow";

      const firstCell: TableCellData<SavedClaimHistory> = new TableCellData<SavedClaimHistory>();
      firstCell.template = this.historyServerErrorTemplateRef;
      firstCell.clickable = false;
      firstCell.colspan = this.savedClaimHeaders.length;
      row.cells.push(firstCell);
      this.savedClaimHistory.push(row);
    }
  }
  private dismissBookmarkMenus(claim: SavedClaimHistory, blnAll: boolean = false): void {
    this.savedClaimHistory.forEach((row: TableRowData<SavedClaimHistory>) => {
      if (row.cells[5] != undefined && row.cells[5].data != undefined) {
        if (blnAll || row.cells[5].data.claimDetailId != claim.claimDetailId) {
          row.cells[5].data.showBookmarkOptions = false;
        }
      }
    });
  }

  private deleteClaimFromBookmark(claim: SavedClaimHistory, blnDelete: BOOLEAN_FLAG): void {
    const webrequest: ClaimBookmarkWebRequest = new ClaimBookmarkWebRequest();
    webrequest.claimDetailId = claim.claimDetailId;
    webrequest.presbrId = this.loginProfile.loginuser.prescriber.presbrId;
    webrequest.isDelete = blnDelete;

    this.claimHistoryService.addOrRemoveClaimBookmark(webrequest).then((response: Claimhistorywebresponse) => {
      if (response.status != undefined
        && response.status.dbStatusCode != undefined
        && response.status.dbStatusCode.toString() == APPCONSTANTS.API_RESPONSE_DB_SUCCESS_CODE) {
        const claimIndex: number = this.sourceData.findIndex((item: SavedClaimHistory) => {
          return item.claimDetailId == claim.claimDetailId;
        });
        if (claimIndex != -1) {
          this.sourceData.splice(claimIndex, 1);
          this.generateRows();
        } else {
          this.reload(true);
        }
      } else {
        this.blnServerError = true;
      }
    }, (error: any) => {
      this.blnServerError = true;
    }).catch((reason: any) => {
      this.blnServerError = true;
    });
  }

  private addClaimToBookmark(claim: SavedClaimHistory, blnDelete: BOOLEAN_FLAG): void {
    const webrequest: ClaimBookmarkWebRequest = new ClaimBookmarkWebRequest();
    webrequest.claimDetailId = claim.claimDetailId;
    webrequest.presbrId = this.loginProfile.loginuser.prescriber.presbrId;
    webrequest.isDelete = blnDelete;

    this.claimHistoryService.addOrRemoveClaimBookmark(webrequest).then((response: Claimhistorywebresponse) => {
      if (response.status != undefined && response.status.dbStatusCode != undefined) {
        if (response.status.dbStatusCode.toString() == APPCONSTANTS.API_RESPONSE_DB_SUCCESS_CODE) {
          claim.showAnimationAfterBookmark = true;
          claim.bookmarkAnimationStatusTxt = "Added to Bookmarked Claims.";

        } else {
          if (response.status.dbStatusCode.toString() == APPCONSTANTS.API_RESPONSE_DB_ALREADY_EXIST_SAVEDCLAIM) {
            claim.showAnimationAfterBookmark = true;
            claim.bookmarkAnimationStatusTxt = "Already added to Bookmarked Claims.";
          }
        }
        setTimeout(() => {
          claim.showAnimationAfterBookmark = false;
          claim.bookmarkAnimationStatusTxt = "";
        }, 5000)
      }
    }, (error: any) => {
      this.modalService.open(this.bookmarkErrorElementRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
    }).catch((reason: any) => {
      this.modalService.open(this.bookmarkErrorElementRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
    });
  }

  private removeClaimFromSavedHistory(claim: SavedClaimHistory, blnDelete: BOOLEAN_FLAG, blnDeleteTranscation: boolean = false): void {
    const webrequest: ClaimUpdateWebRequest = new ClaimUpdateWebRequest();
    webrequest.claimDetailId = claim.claimDetailId;
    webrequest.claimStsTypId = CLAIM_STATUS_TYPE.REMOVED;

    this.claimHistoryService.updateClaimStatus(webrequest).then((response: Status) => {
      if (response != undefined
        && response.dbStatusCode != undefined
        && response.dbStatusCode.toString() == APPCONSTANTS.API_RESPONSE_DB_SUCCESS_CODE) {
        if (!blnDeleteTranscation) {
          claim.showAnimationAfterBookmark = true;
          claim.bookmarkAnimationStatusTxt = "Trail was successfully removed.";
        }

        setTimeout(() => {
          if (!blnDeleteTranscation) {
            claim.showAnimationAfterBookmark = false;
            claim.bookmarkAnimationStatusTxt = "";
          }

          const claimIndex: number = this.sourceData.findIndex((item: SavedClaimHistory) => {
            return item.claimDetailId == claim.claimDetailId;
          });
          if (claimIndex != -1) {
            this.sourceData.splice(claimIndex, 1);
            this.generateRows();
          } else {
            this.reload(true);
          }
        }, 5000);
      } else {
        this.modalService.open(this.bookmarkErrorElementRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
      }
    }, (error: any) => {
      this.modalService.open(this.bookmarkErrorElementRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
    }).catch((reason: any) => {
      this.modalService.open(this.bookmarkErrorElementRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
    });
  }

  private executeOperationOnClaim(claim: SavedClaimHistory, blnDelete: BOOLEAN_FLAG): void {

    this.selectedClaim = claim;
    this.lastOperation = blnDelete;
    this.blnServerError = false;

    if (this.pageType == PAGE_TYPE.BOOKMARKCLAIMS) {
      this.deleteClaimFromBookmark(claim, blnDelete);
    } else {
      this.modalService.dismissAll("Add or remove bookmark operation is started.");

      if (blnDelete == BOOLEAN_FLAG.NO) {
        claim.claimDetailsHistory.forEach((item: ClaimDetailsHistory) => {
          this.addClaimToBookmark(item, blnDelete);
        })
        //this.addClaimToBookmark(claim, blnDelete);
      } else {
        this.removeClaimFromSavedHistory(claim, blnDelete);
      }
    }
  }

  ngOnInit(): void {
   
    // this.loginProfile.loginuser.selectedInfusion.subscribe((value: Infusion) => {
    this.reload();
    // });
  }

  reload(blnHardReload: boolean = true) {
    if (blnHardReload) {

      this.savedClaimHistory = [];
      this.sourceData = [];
      this.blnLoading = true;
      this.blnServerError = false;
      this.totalPages = 1;
      this.currentPage = 1;

      if (this.pageType == PAGE_TYPE.BOOKMARKCLAIMS) {

        const webrequest: Claimhistorywebrequest = new Claimhistorywebrequest();
        webrequest.fromDate = this.datetoDisplayString.transform(this.savedClaimsHistoryViewModal.fromDate);
        webrequest.toDate = this.datetoDisplayString.transform(this.savedClaimsHistoryViewModal.toDate);
        webrequest.presbrId = this.loginProfile.loginuser.prescriber.presbrId;

        this.claimHistoryService.getBookmarkedClaims(webrequest).then((response: Claimhistorywebresponse) => {
          this.sourceData = [];
          if (typeof response.bookmarkClaims != 'undefined') {
            let group: ClaimDetailsHistory[] = [];
            this.sourceData = response.bookmarkClaims;
            this.sourceData.forEach((history: SavedClaimHistory) => {
              history.standardDate = this.timeStampToStandardDate.transform(history.date);
              if (history.claimDetailsHistory == undefined) {
                if (history.claimDetailsHistory == undefined) {
                  history.claimDetailsHistory = [];
                }
                if (group.find((data2) => data2.claimGroupId == history.claimGroupId) != undefined) {
                  //console.log(group);
                  let index = group.findIndex((data2) => data2.claimGroupId == history.claimGroupId);
                  group[index].claimDetailsHistory.push(history);

                } else {
                  history.claimDetailsHistory.push(history);
                  group.push(history);

                  //console.log("Not found");
                }
              }
              this.sourceData = group;
            });
            //console.log("",this.sourceData);
          }
          this.generateRows();
        }, (error: any) => {
          this.blnServerError = true;
          this.generateRows();
        }).catch((reason: any) => {
          this.blnServerError = true;
          this.generateRows();
        });
      } else {
       // //debugger;
        const webrequest: Claimhistorywebrequest = new Claimhistorywebrequest();
        webrequest.fromDate = this.savedClaimsHistoryViewModal.fromDate.getTime().toString();
        webrequest.toDate = this.savedClaimsHistoryViewModal.toDate.getTime().toString();
        webrequest.presbrId = this.loginProfile.loginuser.prescriber.presbrId;
        webrequest.infsnPharmacyNPI = JSON.parse(""+sessionStorage.getItem('SelectedInfsnId')).pharmacyNPI;
        webrequest.claimStsTypList = [];
        webrequest.claimStsTypList.push(CLAIM_STATUS_TYPE.SAVED);
        webrequest.claimStsTypList.push(CLAIM_STATUS_TYPE.RE_EXECUTE);
        webrequest.claimStsTypList.push(CLAIM_STATUS_TYPE.SUBMITTED_REJECTED);
        webrequest.claimStsTypList.push(CLAIM_STATUS_TYPE.SUBMITTED_DUPLICATE);

        // webrequest.claimStsTypList.push(CLAIM_STATUS_TYPE.REMOVED);
        webrequest.claimStsTypList.push(CLAIM_STATUS_TYPE.REVERSED_PAID);
        webrequest.claimStsTypList.push(CLAIM_STATUS_TYPE.REVERSED_REJECTED);
        webrequest.claimStsTypList.push(CLAIM_STATUS_TYPE.SUBMITTED_PAID);

        this.claimHistoryService.getSavedClaimHistory(webrequest).then((response: Claimhistorywebresponse) => {
          ////debugger;
          this.sourceData = [];
          if (typeof response.claimDetailsHistory != 'undefined') {
            //Grouping the Claims based on claimGroupId
            let group: ClaimDetailsHistory[] = [];
            response.claimDetailsHistory.forEach((data) => {
              if (data.claimDetailsHistory == undefined) {
                data.claimDetailsHistory = [];
              }
              if (group.find((data2) => data2.claimGroupId == data.claimGroupId) != undefined) {
                //console.log(group);
                let index = group.findIndex((data2) => data2.claimGroupId == data.claimGroupId);
                group[index].claimDetailsHistory.push(data);

              } else {
                data.claimDetailsHistory.push(data);
                group.push(data);

                //console.log("Not found");
              }
            });
            // console.log("Group"+this.memberId);
            // console.log(group);

            if (this.memberId != undefined) {
              group = group.filter((item) => item.memberId == this.memberId);
              // console.log("Group value with member");
              // console.log(group);

            } else if (this.pageType == PAGE_TYPE.SUBMITTEDCLAIMS && this.isHomePage != undefined && this.isHomePage == true) {
              console.log(group);
              group = group.filter((item)=>[4,7,3].indexOf(item.claimStsTypId)!=-1);
              console.log(group);

            } else if (this.pageType == PAGE_TYPE.SUBMITTEDCLAIMS && this.isHomePage == false) {
              // console.log(group);
              // group=group.filter((item)=>[3].indexOf(item.claimStsTypId)!=-1);
              group = group.filter((item) => item.claimDetailsHistory.filter((claim) => claim.claimStsTypId == 3).length > 0 && item.claimGroupId != undefined);
              // console.log("Group of Paid");
              // console.log(group);
            } else {
              group = group.filter((item) => [1, 2, 6, 8].indexOf(item.claimStsTypId) != -1);
            }
            this.sourceData = group;

            this.sourceData.forEach((history: SavedClaimHistory) => {
              history.standardDate = this.timeStampToStandardDate.transform(history.date);
            });
          }
          this.generateRows();
        }, (error: any) => {
          this.blnServerError = true;
          this.generateRows();
        }).catch((reason: any) => {
          this.blnServerError = true;
          this.generateRows();
        });
      }

    } else {
      this.blnServerError = false;
      this.blnLoading = false;
      this.currentPage = 1;
      this.totalPages = 1;

      this.generateRows();
    }
  }
  onPageChange(pageNumber: any) {
    this.currentPage = pageNumber;
    this.generateRows();
  }

  onChangeEntriesPerPage(entriesPerPage: any) {
    this.recordsPerPage = entriesPerPage;
    this.currentPage = 1;
    this.generateRows();
  }

  onSavedClaimSelected(tableEvent: TableClickEvent<SavedClaimHistory>): void {
    if (tableEvent.column != undefined && tableEvent.cell != undefined
      && tableEvent.cell.data != undefined && (parseInt(tableEvent.column.toString()) == 6 || parseInt(tableEvent.column.toString()) == 0)) {
      switch (tableEvent.event.target.getAttribute('name')) {
        case 'bookmark':
          this.logger.userAction("BookMark Claim");
          this.executeOperationOnClaim(tableEvent.cell.data, BOOLEAN_FLAG.NO);
          this.dismissBookmarkMenus(tableEvent.cell.data, true);
          break;
        case 'delete':
          this.itemToBeDeleted = tableEvent.cell.data;
          this.logger.userAction("Delete Claim");
          this.modalService.dismissAll();
          this.modalService.open(this.removeTransactionConfirmPopup, { modalDialogClass: 'removeTransactionConfirmPopup', size: 'xs' });
          // this.executeOperationOnClaim(tableEvent.cell.data, BOOLEAN_FLAG.YES);
          this.dismissBookmarkMenus(tableEvent.cell.data, true);
          break;
        case 'change':
          this.logger.userAction("Change Claim Medication");
          this.changeClaimTransaction(tableEvent.cell.data.claimGroupId);
          break;
        case 'showDetails':
          tableEvent.cell.data.showDetails = true;
          this.savedClaimHistory[tableEvent.row + 1].blnShow = true;
          break;
        case 'hideDetails':
          tableEvent.cell.data.showDetails = false;
          this.savedClaimHistory[tableEvent.row + 1].blnShow = false;
          break;
        case 'refresh':
          // //debugger;
          this.logger.userAction("Refresh Claim");
          tableEvent.cell.data.animate = true;
          if (this.previousItemClicked != undefined) {
            this.previousItemClicked.cell!.data!.refresh = false;
          }
          //  tableEvent.cell.data.refresh = true;
          let webrequest: RefreshRequest = new RefreshRequest();
          webrequest.claimAdvanceDetailWebRequests = [];
          tableEvent.cell.data.claimDetailsHistory.forEach((item: ClaimDetailsHistory) => {
            let claimreqObj: ClaimAdvanceDetailWebRequest = new ClaimAdvanceDetailWebRequest();
            claimreqObj.claimDetailId = item.claimDetailId;
            webrequest.claimAdvanceDetailWebRequests.push(claimreqObj);
          });
          //console.log("webreqest",webrequest);
          this.claimHistoryService.getStatusAfterRefresh(webrequest).then((data: any) => {
            // //debugger;
            console.log(data);
            this.previousItemClicked = tableEvent;
            data.claimAdvanceDetailWebResponses.forEach((element: ClaimAdvanceDetailWebResponse) => {
              //  //debugger;
              if ((element.claimStatus == 'P' && element.claimStsTypId == 3) || (element.claimStatus == 'X' && element.claimStsTypId == 4)) {
                tableEvent.cell!.data!.refresh = true;
                this.refreshStatusTxt = "This claim status not changed.";
              }
              else {
                tableEvent.cell!.data!.refresh = true;
                this.refreshStatusTxt = "This claim status changed."
              }
            });
            console.log(this.refreshStatusTxt);
            tableEvent.cell!.data!.animate = false;
            // tableEvent.cell!.data!.refresh=false;
          });
          setTimeout(() => {
            tableEvent.cell!.data!.refresh = false;
            this.refreshStatusTxt = '';
          }, 5000);

          break;

        case 'resume':
          this.logger.userAction("Resuming Claim.");
          this.resumeClaimTransaction(tableEvent.cell.data.claimGroupId);
          break;
        // this.itemToBeDeleted = tableEvent.cell.data;
        // //console.log("TobeDeleted" + tableEvent.cell.data);
        // // this.executeOperationOnClaim(tableEvent.cell.data, BOOLEAN_FLAG.YES);
        // this.dismissBookmarkMenus(tableEvent.cell.data, true);
        // this.modalService.dismissAll();
        // this.modalService.open(this.removeMedicationConfirmPopup, { modalDialogClass: 'removeMedicationConfirmPopup', size: 'xs' });
        default:
          if (parseInt(tableEvent.column.toString()) == 6 || tableEvent.event.target.getAttribute('name')=="toggle") {
            this.dismissBookmarkMenus(tableEvent.cell.data);
            tableEvent.cell.data.showBookmarkOptions = !tableEvent.cell.data.showBookmarkOptions;
          } else {
            if (tableEvent.cell != undefined && tableEvent.cell.data != undefined) {
              this.logger.userAction("Resuming Claim.");
              this.resumeClaimTransaction(tableEvent.cell.data.claimGroupId);
            }
          }
          break;
      }
    } else {
      if (tableEvent.event.target.getAttribute('name') == 'tryagain') {
        this.reload(true);
      } else {
        if (tableEvent.cell != undefined && tableEvent.cell.data != undefined) {
          this.logger.userAction("Resuming Claim.");
          this.resumeClaimTransaction(tableEvent.cell.data.claimGroupId);
        }
      }
    }
  }

  private resumeClaimTransaction(claimGroupId: string): void {
    // //debugger;
    this.modalService.dismissAll();
    this.modalService.open(this.loadingContent, { modalDialogClass: 'content' });

    this.claimHistoryService.getSmapClaimTransactionByGrpId(claimGroupId).then((result: ClaimTransactionHistoryResponse) => {
      this.trailClaimServiceHelper.generateSelectedMedicinesFromLstClaims(result).then((medicines: SelectedMedicine[]) => {
        const selectedMedicineList: SelectedMedicine[] = medicines;
        sessionStorage.setItem("selectedMedicineList", JSON.stringify(selectedMedicineList));
        this.modalService.dismissAll();
        this.router.navigateByUrl("/coverage/summary");
      }, (error: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
      }).catch((error: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
      });
    }, (error: any) => {
      this.modalService.dismissAll();
      this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
    }).catch((error: any) => {
      this.modalService.dismissAll();
      this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
    });
  }

  private changeClaimTransaction(claimGroupId: string): void {
    this.modalService.dismissAll();
    this.modalService.open(this.loadingContent, { modalDialogClass: 'content' });

    this.claimHistoryService.getSmapClaimTransactionByGrpId(claimGroupId).then((result: ClaimTransactionHistoryResponse) => {
      this.trailClaimServiceHelper.generateSelectedMedicinesFromLstClaims(result).then((medicines: SelectedMedicine[]) => {
        const selectedMedicineList: SelectedMedicine[] = medicines;
        sessionStorage.setItem("selectedMedicineList", JSON.stringify(selectedMedicineList));
        this.modalService.dismissAll();
        this.router.navigateByUrl("/coverage/drug");
      }, (error: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
      }).catch((error: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
      });
    }, (error: any) => {
      this.modalService.dismissAll();
      this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
    }).catch((error: any) => {
      this.modalService.dismissAll();
      this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
    });
  }

  retryOperation(): void {
    this.executeOperationOnClaim(this.selectedClaim, this.lastOperation);
  }

  filterHistory(): void {
    this.logger.userAction("Search clicked");
    this.savedClaimsHistoryViewModal.blnCustomDateFormSubmitted = false;
    if (parseInt(this.savedClaimsHistoryViewModal.selectedDate.toString()) == VIEWALL_DATERANGE.CUSTOM_DATE) {
      this.savedClaimsHistoryViewModal.blnCustomDateFormSubmitted = true;
      this.ToDateComponent.onFocusOut();
      this.FromDateComponent.onFocusOut();
      setTimeout(() => {
        if (this.savedClaimsHistoryViewModal.fromDateResult == DateFieldResultType.SUCCESS
          && this.savedClaimsHistoryViewModal.toDateResult == DateFieldResultType.SUCCESS) {
          this.reload(this.savedClaimsHistoryViewModal.blnDateChanged);
          this.savedClaimsHistoryViewModal.blnDateChanged = false;
        }
      }, 250);
    } else {
      this.reload(this.savedClaimsHistoryViewModal.blnDateChanged);
      this.savedClaimsHistoryViewModal.blnDateChanged = false;
    }
  }



  removeMedicationConfirm(event: any, blnDelete: boolean) {
    if (blnDelete) {
      this.executeOperationOnClaim(event, BOOLEAN_FLAG.YES);
    } else {
      this.itemToBeDeleted = event;
      //console.log("TobeDeleted" + event);
      // this.executeOperationOnClaim(tableEvent.cell.data, BOOLEAN_FLAG.YES);
      this.modalService.dismissAll();
      this.logger.userAction("Removing Medication");
      this.modalService.open(this.removeMedicationConfirmPopup, { modalDialogClass: 'removeMedicationConfirmPopup', size: 'xs' });
    }
  }


  removeTransactionConfirm() {
    this.logger.userAction("Removing claim.");

    for (let i = 0; i < this.itemToBeDeleted.claimDetailsHistory.length; i++) {
      this.executeOperationOnClaim(this.itemToBeDeleted.claimDetailsHistory[i], BOOLEAN_FLAG.YES);
    }
    // this.executeOperationOnClaim(this.itemToBeDeleted, BOOLEAN_FLAG.YES);
    this.modalService.dismissAll();
  }
}
