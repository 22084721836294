import { Component, OnInit, ViewChild, TemplateRef, ElementRef, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { FormGroup, Validators } from '@angular/forms';

import { DateFieldEvent, DateFieldResultType, DateFieldValidationMode } from 'src/app/modals/datefield/datefield';
import { StandardDateToServertime, ServertimeToStandardDate } from 'src/app/pipes/dateconversion/dateconversion.pipe';
import { Membersearchwebrequest } from 'src/app/modals/membersearch/membersearchwebrequest';
import { Membersearchwebresponse, MemberSearchV5Item, FindMemberResultType, FavouriteMember } from 'src/app/modals/membersearch/membersearchwebresponse';

import { MembersearchService } from 'src/app/services/membersearch/membersearch.service';
import { environment } from 'src/environments/environment';
import { TableClickEvent, TableHeaderCellData, TableRowData, TableCellData } from 'src/app/modals/datatable/datatable';
import { PrescriberSearchItem } from 'src/app/modals/prescriberSearch/prescriberSearchwebresponse';
import { ngbPositioning } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-findmember',
  templateUrl: './findmember.component.html',
  styleUrls: ['./findmember.component.css']
})
export class FindmemberComponent implements OnInit {
  
  blnFormSubmitted: boolean = false;
  blnValidFormSubmitted: boolean = false;
  blnZipCodeFocus: boolean = false;
  blnLastNameFocus: boolean = false;
  blnMemberIdFocus: boolean = false;
  blnSearchPanelOpened: boolean = true;
  blnShowResultsPannel: boolean = false;
  blnSearchedMember: FavouriteMember = new FavouriteMember();

  blnInSuffientSearchCriteria: boolean = false;
  memberResults: MemberSearchV5Item[] = [];
  memberResultType: FindMemberResultType = FindMemberResultType.SUCCESS;
  modalContent = "";
  message = "";
  
  recentMembersArry: string[] = new Array();
  recentSearches: MemberSearchV5Item[] = new Array();

  @Input() pageType!:string;

  dobValue: string = "";
  dobResult: DateFieldResultType = DateFieldResultType.EMPTY;
  dobValidationMode: DateFieldValidationMode = DateFieldValidationMode.ONBLUR;
  onDobUpdate(event: DateFieldEvent): void {
    this.dobValue = event.value;
    this.dobResult = event.valid;
  }

  membersearchform: FormGroup = new FormGroup({
    Lastname: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(25), Validators.pattern(/^(?!\d+$)([a-z0-9 .\-']+)$/i)]),
    inputZip: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(5), Validators.pattern(/^([0-9]+)$/i)]),
    MemberId: new FormControl('', [Validators.minLength(6), Validators.maxLength(18), Validators.pattern(/^[A-Za-z0-9 ]+$/)]),
    Firstname: new FormControl('', [Validators.maxLength(15), Validators.pattern(/^(?!\d+$)([a-z0-9 .\-']+)$/i)])
  });

  @ViewChild("savedmembersmodaltemplate", { static: true })
  savedMembersModalTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("LoadingContent")
  loadingContent?: ElementRef<HTMLElement>;

  @ViewChild("recentMembers")
  recentMembers?: ElementRef<HTMLElement>;

  @ViewChild("NameTemplate", { static: true })
  NameTemplate?: TemplateRef<any>

  @ViewChild("AddressTemplate", { static: true })
  AddressTemplate?: TemplateRef<any>

  @ViewChild("DateofBirthTemplate", { static: true })
  DateofBirthTemplate?: TemplateRef<any>

  @ViewChild("MemberIdTemplate", { static: true })
  MemberIdTemplate?: TemplateRef<any>



  private firstHeader: TableHeaderCellData = new TableHeaderCellData();
  private secondHeader: TableHeaderCellData = new TableHeaderCellData();
  private thirdHeader: TableHeaderCellData = new TableHeaderCellData();
  private forthHeader: TableHeaderCellData = new TableHeaderCellData();
  private fifthHeader: TableHeaderCellData = new TableHeaderCellData();

  recentMembersHeaders!: TableHeaderCellData[];
  recentMembersHistory!: TableRowData<MemberSearchV5Item>[];
  constructor(private memberSearchHelper: MembersearchService,
    private standardDateToServerTime: StandardDateToServertime,
    private servertimeToStandardDate: ServertimeToStandardDate,
    private modalService: NgbModal,
    private router: Router,
    private logger: LoggerService,private titleService:Title) {
    sessionStorage.setItem("lastPageBeforeReload", this.router.url);
    this.titleService.setTitle("SMAP - Check Prescription Coverage - Select Member");
    this.firstHeader.content = "Date of birth";
    this.firstHeader.sortable = true;
    this.firstHeader.sortAscending = true;
    this.firstHeader.selectedForSort = true;
    this.secondHeader.content = "Member";
    this.thirdHeader.content = "Address";
    this.forthHeader.content = "Member ID";
    

    this.recentMembersHeaders = [];
    this.recentMembersHeaders.push(this.firstHeader);
    this.recentMembersHeaders.push(this.secondHeader);
    this.recentMembersHeaders.push(this.thirdHeader);
    this.recentMembersHeaders.push(this.forthHeader);


    this.recentMembersHistory = [];
  }


  getLastName(): string {
    return this.membersearchform.controls['Lastname'].value;
  }
  getFirstName(): string {
    return this.membersearchform.controls['Firstname'].value || "";
  }
  getZipcode(): string {
    return this.membersearchform.controls['inputZip'].value;
  }
  getMemberId(): string {
    return this.membersearchform.controls['MemberId'].value || "";
  }


  ngOnInit(): void {
    // //debugger;
    sessionStorage.removeItem("pageType");
    if (sessionStorage.getItem('memberResults') != undefined) {
      //console.log("Session found");
      this.memberResults = JSON.parse("" + sessionStorage.getItem('memberResults'));
      this.blnShowResultsPannel = true;
      this.memberResultType = (this.memberResults.length > 0) ? FindMemberResultType.SUCCESS : FindMemberResultType.NO_RESULTS;
      this.modalService.dismissAll();
      this.blnSearchedMember=JSON.parse("" + sessionStorage.getItem('blnSearchedMember'));
      this.blnSearchPanelOpened = false;
      this.membersearchform.controls['Lastname'].setValue(this.blnSearchedMember.lastName);
      this.membersearchform.controls['Firstname'].setValue(this.blnSearchedMember.firstName);
      this.membersearchform.controls['inputZip'].setValue(this.blnSearchedMember.zipCode);
      this.membersearchform.controls['MemberId'].setValue(this.blnSearchedMember.memberId);
      
      this.dobValue = this.blnSearchedMember.dateOfBirth;
      //console.log("",this.dobValue);
    } else {
      //console.log("Session Not found");
    }
    if(sessionStorage.getItem("recentMemberSearches")!=undefined){
      this.recentSearches=JSON.parse(""+sessionStorage.getItem("recentMemberSearches"));
    }
    sessionStorage.removeItem('prescribersearchresults');
    sessionStorage.removeItem('prescribersearchcriteria');
    sessionStorage.removeItem('selectedPrescriber');
    sessionStorage.removeItem('prescriberdetail');
    sessionStorage.removeItem('selectedMedicine');
  }
  findFavouriteMember(event: TableClickEvent<FavouriteMember>): void {
    
    this.logger.userAction("Finding Favourtie Member.")
    this.dobValue = (event.cell?.data?.dateOfBirth != undefined) ? this.servertimeToStandardDate.transform(event.cell?.data?.dateOfBirth) : "";
    this.blnFormSubmitted = false;
    setTimeout(() => {
      this.blnFormSubmitted = true;
      this.membersearchform.controls['Lastname'].setValue(event.cell?.data?.lastName);
      this.membersearchform.controls['Firstname'].setValue(event.cell?.data?.firstName);
      this.membersearchform.controls['inputZip'].setValue(event.cell?.data?.zipCode);
      this.modalService.dismissAll("A favourite member selected for seach");
      this.findMember(0);
    }, 500);
  }
  findMember(millisecond: number = 500): void {
    // //debugger;
    this.logger.userAction("Refreshing the find Member Page to load members");
    if (this.blnValidFormSubmitted) {
      this.modalContent = "Updating...";
    } else {
      this.modalContent = "Searching..."
    }
    this.blnFormSubmitted = true;
    // console.log("test",+this.memberResultType);

    // setTimeout(() => {
      this.memberResultType = FindMemberResultType.SUCCESS;
      if (this.membersearchform.valid) {
        this.blnValidFormSubmitted = true;
        if (this.dobResult == DateFieldResultType.SUCCESS) {
          console.log("LastName:"+this.membersearchform.controls['Lastname'].value);
          this.blnSearchedMember.lastName = this.membersearchform.controls['Lastname'].value;
          this.blnSearchedMember.firstName = this.membersearchform.controls['Firstname'].value;
          this.blnSearchedMember.dateOfBirth = this.dobValue;
          this.blnSearchedMember.zipCode = this.membersearchform.controls['inputZip'].value;
          this.blnSearchedMember.memberId = this.membersearchform.controls['MemberId'].value;

          this.blnInSuffientSearchCriteria = false;
          this.blnShowResultsPannel = false;
          let webrequest: Membersearchwebrequest = new Membersearchwebrequest();
          webrequest.lastName = this.getLastName();
          webrequest.firstName = this.getFirstName();
          webrequest.dateOfBirth = this.standardDateToServerTime.transform(this.dobValue);
          webrequest.zipCode = this.getZipcode();
          webrequest.memberId = this.getMemberId();
          webrequest.includeLob = environment.membersearchIncludeLob;
          webrequest.includeExcludeListIndicator = environment.membersearchIncludeExcludeIndicator;
          webrequest.groupList = environment.membersearchGroupList;
          this.modalService.open(this.loadingContent, { modalDialogClass: 'content' });
          this.memberSearchHelper.searchForMember(webrequest).then((response: Membersearchwebresponse) => {
            ////debugger;
            this.modalService.open(this.loadingContent, { modalDialogClass: 'content' });
            this.memberResults = (response.memberSearchV5Items != undefined) ? response.memberSearchV5Items : [];
            // console.log("test",+this.memberResultType);

            // Adding members result to session
            sessionStorage.setItem('memberResults', JSON.stringify(this.memberResults));
            sessionStorage.setItem('blnSearchedMember', JSON.stringify(this.blnSearchedMember));

            if (response.status.respCode != undefined) {
              ////debugger;
              switch (parseInt(response.status.respCode)) {
                case 400:
                  this.memberResultType = FindMemberResultType.BAD_REQUEST;
                  this.modalService.dismissAll();
                  // console.log("test",+this.memberResultType);

                  break;
                case 504:
                  this.memberResultType = FindMemberResultType.TIMEOUT;
                  this.modalService.dismissAll();
                  // console.log("test",+this.memberResultType);

                  break;
                default:
                  this.blnSearchPanelOpened = (this.memberResults.length > 0) ? false : true;
                  this.blnShowResultsPannel = true;
                  this.memberResultType = (this.memberResults.length > 0) ? FindMemberResultType.SUCCESS : FindMemberResultType.NO_RESULTS;
                  this.modalService.dismissAll();
                  // console.log("test",+this.memberResultType);

                  break;
              }
              if (this.recentSearches.length == 0 && this.memberResults.length>0) {
                let memberObj = this.memberResults[0];
                memberObj.dateOfBirth = (memberObj.dateOfBirth != undefined) ? this.servertimeToStandardDate.transform(memberObj.dateOfBirth) : "";
                this.recentSearches.push(memberObj);
                //console.log("",this.memberResults);
                //console.log("recentsearches",this.recentSearches);
                sessionStorage.setItem("recentMemberSearches",JSON.stringify(this.recentSearches));
              } else if (this.memberResults.length>0 && this.recentSearches.map((data) => data.memberId).indexOf(this.memberResults[0].memberId) == -1) {
                let memberObj = this.memberResults[0];
                memberObj.dateOfBirth = (memberObj.dateOfBirth != undefined) ? this.servertimeToStandardDate.transform(memberObj.dateOfBirth) : "";
                this.recentSearches.push(memberObj);
                //console.log("recentsearches",this.recentSearches);
                sessionStorage.setItem("recentMemberSearches",JSON.stringify(this.recentSearches));
                // console.log("test",+this.memberResultType);
              }
              
            } else {
              this.blnSearchPanelOpened = (this.memberResults.length > 0) ? false : true;
              this.blnShowResultsPannel = true;
              this.memberResultType = (this.memberResults.length > 0) ? FindMemberResultType.SUCCESS : FindMemberResultType.NO_RESULTS;
              this.modalService.dismissAll();
              // console.log("test",+this.memberResultType);

            }
          }, (error: any) => {
            this.memberResults = [];
            this.memberResultType = FindMemberResultType.INTERNAL_SERVER_ERROR;
            this.modalService.dismissAll();
            // console.log("test",+this.memberResultType);

          // }).catch((reason: any) => {
          //   this.memberResults = [];
          //   this.memberResultType = FindMemberResultType.INTERNAL_SERVER_ERROR;
          //   this.modalService.dismissAll();
          //   console.log("test",+this.memberResultType);

          });
        } else {
          this.blnInSuffientSearchCriteria = true;
          this.modalService.dismissAll();
        }
      } else {
        this.blnInSuffientSearchCriteria = true;
        this.modalService.dismissAll();
      }
    // }, millisecond)
    // console.log("test",+this.memberResultType);
  }

  openSavedMembers(event: any) {
    this.logger.userAction("Opening Saved Searches");
    event.preventDefault();
    this.modalService.open(this.savedMembersModalTemplateRef, { modalDialogClass: 'savedMembersModal', size: 'lg', windowClass: 'savedMembersModal',backdrop: 'static', keyboard: true });
  }
  openRecentMembers($event: any) {
    this.logger.userAction("Opening Recent Searches.");
    event?.preventDefault();
    this.recentMembersHistory = [];
    this.loadRecentSearchHistory(this.recentSearches);
    this.modalService.open(this.recentMembers, { modalDialogClass: 'content', size: 'lg',backdrop: 'static', keyboard: true});
    if(this.recentMembersHistory.length==0){
      
      this.message="No recent members";
      //console.log(this.message);
    }
  }
  clearAll(event: any): void {
    event.preventDefault();
    this.logger.userAction("Clear All clicked.");
    this.resetSearch();
  }
  back(event: any): void {
    this.logger.userAction("Back clicked.");
    event.preventDefault();
    this.router.navigateByUrl("/home");
  }
  close() {
    this.logger.userAction("Close clicked.");
    this.blnSearchPanelOpened = false;
    this.membersearchform.controls['Lastname'].setValue(this.blnSearchedMember.lastName);
    this.membersearchform.controls['Firstname'].setValue(this.blnSearchedMember.firstName);
    this.membersearchform.controls['inputZip'].setValue(this.blnSearchedMember.zipCode);
    this.dobValue = this.blnSearchedMember.dateOfBirth;
  }
  private resetSearch(): void {
    this.membersearchform.controls['Lastname'].setValue("");
    this.membersearchform.controls['Firstname'].setValue("");
    this.membersearchform.controls['inputZip'].setValue("");
    this.membersearchform.controls['MemberId'].setValue("");
    this.dobValue = "";
    this.blnFormSubmitted = false;
  }

  private loadRecentSearchHistory(rawData: MemberSearchV5Item[]): void {
    
    rawData.forEach((history: MemberSearchV5Item) => {
      const row: TableRowData<MemberSearchV5Item> = new TableRowData<MemberSearchV5Item>();
      this.recentMembersArry.push(history.memberId);
      const firstCell: TableCellData<MemberSearchV5Item> = new TableCellData();
      firstCell.data = history;
      firstCell.template = this.DateofBirthTemplate;
      row.cells.push(firstCell);

      const secondCell: TableCellData<MemberSearchV5Item> = new TableCellData();
      secondCell.data = history;
      secondCell.template = this.NameTemplate;
      row.cells.push(secondCell);

      const thirdCell: TableCellData<MemberSearchV5Item> = new TableCellData();
      thirdCell.data = history;
      thirdCell.template = this.AddressTemplate;
      row.cells.push(thirdCell);

      const forthCell: TableCellData<MemberSearchV5Item> = new TableCellData();
      forthCell.data = history;
      forthCell.template = this.MemberIdTemplate;
      row.cells.push(forthCell);


      this.recentMembersHistory.push(row);
    });

    //console.log(this.recentMembersHistory);

  }
  

  onRecentMembersHeaderClick(headerData: TableClickEvent<TableHeaderCellData>) {
    
    this.logger.userAction("Sorting Recent Member.");
    if (headerData.column == 0) {
      if (headerData.cell?.sortAscending == false) {
        this.recentMembersHistory = [];
        this.loadRecentSearchHistory(this.recentSearches.sort((a, b) => {
          return parseInt(a.memberId) - parseInt(b.memberId);
        }));
      } else {
        this.recentMembersHistory = [];
        this.loadRecentSearchHistory(this.recentSearches.sort((a, b) => {
          return parseInt(b.memberId) - parseInt(a.memberId);
        }));
      }
    }
  }
  onRecentMembersCellClick(cellData: TableClickEvent<MemberSearchV5Item>) {
    this.logger.userAction("Selecting Recent Member.");
    if (cellData.column == 0 || cellData.column == 1 || cellData.column == 2 || cellData.column == 3) {
      this.membersearchform.reset();
      let name= this.membersearchform.get('Lastname');
      let zip = this.membersearchform.get('inputZip');
     var Lastname: string | undefined = cellData.cell?.data?.lastName; 
     var inputzip: string | undefined = cellData.cell?.data?.address.zip1;
      name?.setValue(Lastname);
      zip?.setValue(inputzip);
      this.dobValue = ""+cellData.cell?.data?.dateOfBirth;
      this.modalService.dismissAll();
      this.findMember();
    }
  }
}