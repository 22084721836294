import { Component, OnInit } from '@angular/core';
import { APPCONSTANTS } from 'src/app/constants/appconstant';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.css']
})

export class SsoComponent implements OnInit {
  public env:any = environment;
  public action:number=0;
  constructor(public profileService:ProfileService){

  }


  ngOnInit(): void {
    if(this.profileService.appLogin == APPCONSTANTS.APP_NOT_INITIALIZED)
    {
      this.action=1;
      setTimeout(()=>document.forms[0].submit(),200);
    }
    else{
      if(this.profileService.appLogin == APPCONSTANTS.APP_ACCESS_CODE_RECEIVED)
        this.action=2;
      else{
        if(this.profileService.appLogin == APPCONSTANTS.APP_TOKEN_RECEIVED)
          this.action=3;
        else
        {
          this.action==0;
        }
      }
    }
  }
}
