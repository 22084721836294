import { Component, ElementRef, OnInit, TemplateRef, ViewChild, EventEmitter, Output, HostListener } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TableHeaderCellData, TableRowData, TableCellData, TableClickEvent, DataTableType } from 'src/app/modals/datatable/datatable';
import { State, PrescriberSearchCriteria } from 'src/app/modals/prescriberSearch/prescriberviewmodal';
import { PrescriberSearchWebRequest, PrescriberFavoriteWebRequest } from 'src/app/modals/prescriberSearch/prescriberSearchweberequest';
import { PrescriberSearchItem, PrescriberSearchRootObject, PrescriberFavoriteResponse, PrescriberFavoriteWebResponse } from 'src/app/modals/prescriberSearch/prescriberSearchwebresponse';
import { PrescriberSearchService } from 'src/app/services/prescriberSearch/prescriber-search.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { APPCONSTANTS, EPA_STATUS, KEY_CODE, PRESCRIBER_QUALIFIER } from 'src/app/constants/appconstant';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { ResponseRootObj, Status } from 'src/app/modals/webresponsestatus';
import { DrugSearchItem, SelectedMedicine } from 'src/app/modals/drugSearch/drugsearchwebresponse';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { PAInitiationWebRequest } from 'src/app/modals/epa/priorauthrequest';
import { EpaprocessorService } from 'src/app/services/epa/epaprocessor.service';
import { EpaInitiationAckWebResponse, ResubmitSavedPA } from 'src/app/modals/epa/priorauthresponse';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';

enum FavouriteActionErrorType {
  NO_ERROR = 0,
  RETRIEVE_ERROR = 1,
  UNFAVOURITE_ERROR = 2
}

@Component({
  selector: 'app-select-prescriber',
  templateUrl: './select-prescriber.component.html',
  styleUrls: ['./select-prescriber.component.css']
})

export class SelectPrescriberComponent implements OnInit {

  @Output() intiateEPA = new EventEmitter<PrescriberSearchItem | undefined>();
  favouriteActionError: FavouriteActionErrorType = FavouriteActionErrorType.NO_ERROR;
  private _prescribers !: PrescriberFavoriteWebResponse[];

  public get prescribers(): PrescriberFavoriteWebResponse[] {
    return this._prescribers;
  }
  public set prescribers(v: PrescriberFavoriteWebResponse[]) {
    this._prescribers = v;
    this.currentPageNumber = 1;
    this.loadPrescriberSearchItems(this.getCurrentPageMembers());
  }

  @ViewChild("favouriteErrorTemplate")
  favouriteErrorTemplateRef?: TemplateRef<HTMLElement>;


  blnFavouriteActionError: boolean = false;

  private firstHeader: TableHeaderCellData = new TableHeaderCellData();
  private secondHeader: TableHeaderCellData = new TableHeaderCellData();
  private thirdHeader: TableHeaderCellData = new TableHeaderCellData();
  private forthHeader: TableHeaderCellData = new TableHeaderCellData();
  private fifthHeader: TableHeaderCellData = new TableHeaderCellData();
  private sixthHeader: TableHeaderCellData = new TableHeaderCellData();
  private seventhHeader: TableHeaderCellData = new TableHeaderCellData();
  private eighthHeader: TableHeaderCellData = new TableHeaderCellData();
  private ninthHeader: TableHeaderCellData = new TableHeaderCellData();
  private tenthHeader: TableHeaderCellData = new TableHeaderCellData();


  savedPrescribersHeaders!: TableHeaderCellData[];
  savedPrescribersHistory: TableRowData<PrescriberFavoriteWebResponse>[];

  recentPrescribersHeaders!: TableHeaderCellData[];
  recentPrescribersHistory: TableRowData<PrescriberSearchItem>[];

  constructor(private titleService: Title, private router: Router,
    private prescriberSearchService: PrescriberSearchService, private logger: LoggerService,
    private modalService: NgbModal, private profileService: ProfileService, private epaProcessor: EpaprocessorService,) {
    this.titleService.setTitle("SMAP - Check Prescription Coverage - Verify Provider");



    this.firstHeader.content = "Name";
    this.firstHeader.sortable = true;
    this.firstHeader.sortAscending = true;
    this.firstHeader.selectedForSort = true;
    this.secondHeader.content = "Address";
    this.thirdHeader.content = "NPI";
    this.forthHeader.content = "Save";
    this.fifthHeader.content = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";
    this.fifthHeader.cssClass = "tablesmallheader25";
    this.sixthHeader.content = "Name";
    this.sixthHeader.sortable = true;
    this.sixthHeader.sortAscending = true;
    this.sixthHeader.selectedForSort = true;
    this.seventhHeader.content = "Primary address";
    this.eighthHeader.content = "NPI";
    this.ninthHeader.content = "Actions";
    this.tenthHeader.content = "&nbsp;&nbsp;&nbsp";
    this.tenthHeader.cssClass = "tablesmallheader25";

    this.savedPrescribersHeaders = [];
    this.savedPrescribersHeaders.push(this.sixthHeader);
    this.savedPrescribersHeaders.push(this.seventhHeader);
    this.savedPrescribersHeaders.push(this.eighthHeader);
    this.savedPrescribersHeaders.push(this.ninthHeader);
    this.savedPrescribersHeaders.push(this.tenthHeader);

    this.savedPrescribersHistory = [];


    this.recentPrescribersHeaders = [];
    this.recentPrescribersHeaders.push(this.sixthHeader);
    this.recentPrescribersHeaders.push(this.seventhHeader);
    this.recentPrescribersHeaders.push(this.eighthHeader);


    this.recentPrescribersHistory = [];



    this.prescriberSearchHeaders = [];
    this.prescriberSearchHistory = [];

    this.prescriberSearchHeaders.push(this.firstHeader);
    this.prescriberSearchHeaders.push(this.secondHeader);
    this.prescriberSearchHeaders.push(this.thirdHeader);
    this.prescriberSearchHeaders.push(this.forthHeader);
    this.prescriberSearchHeaders.push(this.fifthHeader);

    this.states = this.prescriberSearchService.states;


  }

  states: State[];

  // Data Table variables and functions
  prescriberSearchHeaders: TableHeaderCellData[];
  prescriberSearchHistory: TableRowData<PrescriberSearchItem>[];

  datatableType: DataTableType = DataTableType.HIGHLIGHT_ROWS;
  activeTab: number = 1;
  submittedNPIForm: boolean = false;
  submittedNPINameForm: boolean = false;
  prescriberList: PrescriberSearchItem[] = [];
  savedPrescrbersArry: string[] = new Array();
  recentPrescrbersArry: string[] = new Array();
  recentSearches: PrescriberSearchItem[] = new Array();
  savedSearches: PrescriberFavoriteWebResponse[] = new Array();

  currentPageNumber: number = 1;
  recordsPerPage: number = environment.paginationRecordsPerPage;
  totalEntries: number = 0;
  totalPagesCount: number = -1;
  errorText = "";
  message = "";
  savedMessage = "";
  favoritePrescriberdetail: PrescriberFavoriteWebResponse[] = [];
  prescriberFavoriteList: PrescriberFavoriteWebResponse[] = [];

  memberDetails!: any;



  @ViewChild("LoadingContent")
  loadingContent?: ElementRef<HTMLElement>;

  @ViewChild("recentPrescribers")
  recentPrescribers?: ElementRef<HTMLElement>;

  @ViewChild("savedPrescribers")
  savedPrescribers?: ElementRef<HTMLElement>;

  @ViewChild("actionRemove", { static: true })
  actionRemove?: TemplateRef<any>

  @ViewChild("actionSelect", { static: true })
  actionSelect?: TemplateRef<any>

  @ViewChild("errorPopup", { static: true })
  errorPopupTemplateRef?: TemplateRef<HTMLElement>;


  searchPrescriberNPIForm = new FormGroup({
    NPI: new FormControl('', { validators: [Validators.required, Validators.minLength(10)], updateOn: 'blur' })
  });

  searchPrescriberNameForm = new FormGroup({
    lastName: new FormControl('', { validators: [Validators.required, Validators.minLength(2)], updateOn: 'blur' }),
    firstName: new FormControl('', { validators: [], updateOn: 'blur' }),
    city: new FormControl('', { validators: [], updateOn: 'blur' }),
    state: new FormControl('', { validators: [Validators.required], updateOn: 'blur' })
  });

  get NPI() {
    return this.searchPrescriberNPIForm.get('NPI');
  }

  get lastName() {
    return this.searchPrescriberNameForm.get('lastName');
  }

  get firstName() {
    return this.searchPrescriberNameForm.get('firstName');
  }

  get city() {
    return this.searchPrescriberNameForm.get('city');
  }

  get state() {
    return this.searchPrescriberNameForm.get('state');
  }

  ngOnInit(): void {
    sessionStorage.setItem("lastPageBeforeReload", this.router.url);
    sessionStorage.removeItem("selectedMedicineList");
    //console.log("Rerouting URL:" + sessionStorage.getItem("lastPageBeforeReload"));
    if (this.profileService.loginuser.prescriber.presbrId != undefined && this.profileService.loginuser.UUID != undefined) {
      this.prescriberSearchService.getFavouritePrescribers(this.profileService.loginuser.prescriber.presbrId, this.profileService.loginuser.UUID).then((data: PrescriberFavoriteResponse) => {

        if (data.prescriberFavoriteList != undefined && data.prescriberFavoriteList.length > 0) {
          this.prescriberFavoriteList = data.prescriberFavoriteList;
          //console.log("FavSearch:" + this.prescriberFavoriteList);
          this.loadPrescriberSearchItems(this.getCurrentPageMembers());
          this.loadSavedSearchHistory(data.prescriberFavoriteList);
        }
      }, (error: any) => {
        //console.log("ErrorBlock:");
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
      }).catch((reason: any) => {
        //console.log("exceotion:");
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
      });

      if (sessionStorage.getItem("recentPrescriberSearches") != undefined) {
        this.recentSearches = JSON.parse("" + sessionStorage.getItem("recentPrescriberSearches"));
      }

    }



    const prescriberresultsinsession: PrescriberSearchItem[] | undefined = this.prescriberSearchService.readPrescriberResults();

    if (prescriberresultsinsession != undefined) {

      this.prescriberList = prescriberresultsinsession;
      this.currentPageNumber = 1;

      const searchCriteria: PrescriberSearchCriteria | undefined = this.prescriberSearchService.readPrescriberSearchCriteria();
      if (searchCriteria != undefined) {
        if (searchCriteria.prescriberId.length > 0) {
          //Last search is NPI search.
          this.activeTab = 1;
          this.NPI?.setValue(searchCriteria.prescriberId.toString());
        } else {
          this.activeTab = 2;
          this.lastName?.setValue(searchCriteria.lastName);
          this.firstName?.setValue(searchCriteria.firstName);
          this.city?.setValue(searchCriteria.prescriberCity);
          if (this.states.findIndex((state: State) => state.code == searchCriteria.prescriberState) != -1) {
            this.state?.setValue(this.states.filter((state: State) => state.code == searchCriteria.prescriberState)[0].code);
          }
        }
      }

      this.prescriberSearchService.savedPrescribers.subscribe(() => {
        this.updateTotalPagesCount();
        this.loadPrescriberSearchItems(this.getCurrentPageMembers());
      });
      // this.updateTotalPagesCount();
      // this.loadPrescriberSearchItems(this.getCurrentPageMembers());
      this.modalService.dismissAll();
    }
    this.reloadSavedPrescriberSearches();

    sessionStorage.removeItem('selectedMedicine');

    let memberJson: string | null = sessionStorage.getItem('memberdetails');
    if (memberJson != null && memberJson != undefined) {
      this.memberDetails = JSON.parse(memberJson);
      this.memberDetails.firstName = this.memberDetails.firstName.toLocaleLowerCase();
      this.memberDetails.lastName = this.memberDetails.lastName.toLocaleLowerCase();
      this.memberDetails.address.address1 = this.memberDetails.address.address1.toLocaleLowerCase();
      this.memberDetails.address.address2 = this.memberDetails.address.address2.toLocaleLowerCase();
      this.memberDetails.address.address3 = this.memberDetails.address.address3.toLocaleLowerCase();
      this.memberDetails.address.city = this.memberDetails.address.city.toLocaleLowerCase();
      this.memberDetails.address.state = this.memberDetails.address.state.toLocaleLowerCase();
      this.memberDetails.address.zip = this.memberDetails.address.zip.toLocaleLowerCase();
      this.memberDetails.address.country = this.memberDetails.address.country.toLocaleLowerCase();
    }


  }

  submitNPIForm() {
    this.submittedNPIForm = true;

    this.logger.userAction("Search Prescriber via NPI.");

    if (this.searchPrescriberNPIForm.valid) {
      var prescriber = new PrescriberSearchWebRequest();
      prescriber.prescriberId = "" + this.NPI?.value;
      //console.log("NPI", this.NPI?.value);
      prescriber.prescriberIdQualifier = PRESCRIBER_QUALIFIER.NPI;

      this.prescriberSearchHistory = [];
      this.modalService.open(this.loadingContent, { modalDialogClass: 'content' });

      this.prescriberSearchService.clearSession();

      this.prescriberSearchService.getPrescriberSearch(prescriber).subscribe((data: PrescriberSearchRootObject) => {
        //console.log(data);
        if (data.status.respCode == APPCONSTANTS.API_RESPONSE_SUCCESS_CODE) {
          this.prescriberList = data.response.prescriberSearchItems;

          //code to remove npis which are not of qualifier "01"
          for (let i = 0; i < this.prescriberList.length; i++) {
            {
              for (let j = 0; j < this.prescriberList[i].identifiers.length; j++) {
                if (this.prescriberList[i].identifiers[j].qualifier != '01') {
                  this.prescriberList[i].identifiers.splice(j, 1);
                  j = j - 1;
                }
              }
            }
          }

          this.sortPrescriber();
          this.prescriberList = this.prescriberList.slice(0, 100);
          this.currentPageNumber = 1;
          //console.log("Prescriber Length:" + this.prescriberList.length);
          this.updateTotalPagesCount();
          this.loadPrescriberSearchItems(this.getCurrentPageMembers());
          this.modalService.dismissAll();

          const searchCriteria: PrescriberSearchCriteria = new PrescriberSearchCriteria();
          searchCriteria.prescriberId = "" + this.NPI?.value;
          searchCriteria.prescriberIdQualifier = prescriber.prescriberIdQualifier;

          this.prescriberSearchService.storePrescriberSearchCriteria(searchCriteria);
          this.prescriberSearchService.storePrescriberResults(this.prescriberList);
          if (this.recentSearches.length == 0) {
            this.recentSearches.push(this.prescriberList[0]);
            sessionStorage.setItem("recentPrescriberSearches", JSON.stringify(this.recentSearches));
            //console.log("recentsearches",this.recentSearches);
          } else if (this.recentSearches.map((data) => data.identifiers[0].id).indexOf(this.prescriberList[0].identifiers[0].id) == -1) {
            this.recentSearches.push(this.prescriberList[0]);
            sessionStorage.setItem("recentPrescriberSearches", JSON.stringify(this.recentSearches));
            //console.log("recentsearches",this.recentSearches);
          }
        } else {
          this.modalService.dismissAll();
          this.prescriberList = [];
          this.currentPageNumber = 1;
          this.totalPagesCount = 0;
          //console.log("Results Not found");
        }
      }, (error: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
      });
    }
  }

  submitNPINameForm() {
    //console.log("name search");
    this.logger.userAction("Search Prescriber via Presciber Details.");
    this.submittedNPINameForm = true;
    if (this.searchPrescriberNameForm.valid) {
      this.prescriberSearchHistory = [];
      const prescriber: PrescriberSearchWebRequest = new PrescriberSearchWebRequest();
      prescriber.lastName = "" + this.lastName?.value;
      prescriber.firstName = "" + this.firstName?.value;
      prescriber.prescriberState = "" + this.state?.value;
      prescriber.prescriberCity = "" + this.city?.value;
      prescriber.prescriberState = "" + this.state?.value;
      this.modalService.open(this.loadingContent, { modalDialogClass: 'content' });
      this.prescriberSearchService.getPrescriberSearch(prescriber).subscribe((data: PrescriberSearchRootObject) => {
        //console.log(data);
        if (data.status.respCode == APPCONSTANTS.API_RESPONSE_SUCCESS_CODE) {
          this.prescriberList = data.response.prescriberSearchItems;

          //code to remove npis which are not of qualifier "01"
          for (let i = 0; i < this.prescriberList.length; i++) {
            {
              for (let j = 0; j < this.prescriberList[i].identifiers.length; j++) {
                if (this.prescriberList[i].identifiers[j].qualifier != '01') {
                  this.prescriberList[i].identifiers.splice(j, 1);
                  j = j - 1;
                }
              }
            }

          }
          this.sortPrescriber();
          this.currentPageNumber = 1;
          this.prescriberList = this.prescriberList.slice(0, 100);
          //console.log("Prescriber Length:" + this.prescriberList.length);
          this.updateTotalPagesCount();
          this.loadPrescriberSearchItems(this.getCurrentPageMembers());
          this.modalService.dismissAll();

          const searchCriteria: PrescriberSearchCriteria = new PrescriberSearchCriteria();
          searchCriteria.firstName = "" + this.firstName?.value;
          searchCriteria.lastName = "" + this.lastName?.value;
          searchCriteria.prescriberCity = "" + this.city?.value;
          searchCriteria.prescriberState = "" + this.state?.value;

          this.prescriberSearchService.storePrescriberSearchCriteria(searchCriteria);
          this.prescriberSearchService.storePrescriberResults(this.prescriberList);

        } else {
          this.modalService.dismissAll();
          this.prescriberList = [];
          this.currentPageNumber = 1;
          this.totalPagesCount = 0;
          //console.log("Results Not found");
        }
      }, (error: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
      });
    }
  }

  clearNPIForm() {
    //console.log(" clear");
    this.searchPrescriberNPIForm.reset();
    this.submittedNPINameForm = false;
    this.submittedNPIForm = false;
  }

  clearNPINameForm() {
    //console.log(" clear");
    this.searchPrescriberNameForm.reset();
    this.submittedNPINameForm = false;
    this.submittedNPIForm = false;
  }

  focussedNPI = false;
  onFocusNPI() {
    this.focussedNPI = !this.focussedNPI;
  }

  focussedLastName = false;
  onFocusLastName() {
    this.focussedLastName = !this.focussedLastName;
  }

  focussedState = false;
  onFocussedState() {
    this.focussedState = !this.focussedState;
  }
  @ViewChild("errorContent")
  content?: ElementRef<HTMLElement>;

  @ViewChild("NameTemplate", { static: true })
  NameTemplate?: TemplateRef<any>

  @ViewChild("AddressTemplate", { static: true })
  AddressTemplate?: TemplateRef<any>

  @ViewChild("NPITemplate", { static: true })
  NPITemplate?: TemplateRef<any>

  @ViewChild("SNameTemplate", { static: true })
  SNameTemplate?: TemplateRef<any>

  @ViewChild("SAddressTemplate", { static: true })
  SAddressTemplate?: TemplateRef<any>

  @ViewChild("SNPITemplate", { static: true })
  SNPITemplate?: TemplateRef<any>

  @ViewChild("prescriberSaveTemplate", { static: true })
  prescriberSaveTemplateRef?: TemplateRef<any>;

  @ViewChild("actionPrescriberSearchSelect", { static: true })
  actionPrescriberSearchSelect?: TemplateRef<any>

  @ViewChild("RNameTemplate", { static: true })
  RNameTemplate?: TemplateRef<any>

  @ViewChild("RAddressTemplate", { static: true })
  RAddressTemplate?: TemplateRef<any>

  @ViewChild("RNPITemplate", { static: true })
  RNPITemplate?: TemplateRef<any>


  private loadPrescriberSearchItems(rawData: PrescriberSearchItem[]): void {
    this.prescriberSearchHistory = [];
    rawData.forEach((item: PrescriberSearchItem) => {
      const row: TableRowData<PrescriberSearchItem> = new TableRowData<PrescriberSearchItem>();

      const firstCell: TableCellData<PrescriberSearchItem> = new TableCellData();
      firstCell.data = item;
      firstCell.template = this.NameTemplate;
      row.cells.push(firstCell);

      const secondCell: TableCellData<PrescriberSearchItem> = new TableCellData();
      secondCell.data = item;
      secondCell.template = this.AddressTemplate;
      row.cells.push(secondCell);

      const thirdCell: TableCellData<PrescriberSearchItem> = new TableCellData();
      thirdCell.data = item;
      thirdCell.template = this.NPITemplate;
      row.cells.push(thirdCell);

      const forthCell: TableCellData<PrescriberSearchItem> = new TableCellData();

      item.isFavorite = this.prescriberSearchService.isFavouritePrescriber(item.identifiers[0].id);
      forthCell.data = item;
      forthCell.clickable = true;
      forthCell.template = this.prescriberSaveTemplateRef;
      row.cells.push(forthCell);

      const fifthCell: TableCellData<PrescriberSearchItem> = new TableCellData();
      fifthCell.data = item;
      fifthCell.template = this.actionPrescriberSearchSelect;
      row.cells.push(fifthCell);

      this.prescriberSearchHistory.push(row);

    });
    //console.log("prescriber array");
    //console.log(this.prescriberSearchHistory);
  }

  onPrescriberHeaderClick(headerClickEvent: TableClickEvent<TableHeaderCellData>) {
    if (headerClickEvent.cell != undefined) {
      this.sortPrescriber();
      this.loadPrescriberSearchItems(this.getCurrentPageMembers());
    }
  }

  onPrescriberCellClick(cellData: TableClickEvent<PrescriberSearchItem>) {
    if (cellData.column == 3 || cellData.event.target.getAttribute('name') == "favorite") {
      // //debugger;
      //console.log("NO Redirect");
      //favourite or unfavourite column
      if (cellData.cell != undefined && cellData.cell.data != undefined) {
        if (cellData.cell.data.isFavorite) {
          //unfavourite
          this.logger.userAction("Removing Favourite Prescriber");
          this.removeFavouritePrescriber(cellData.cell.data);
        } else {
          //favourite
          this.logger.userAction("Saving Prescriber");
          this.savefavourite(cellData.cell.data);
        }
      }
    } else if (sessionStorage.getItem('pageType') != undefined && sessionStorage.getItem('pageType') == 'resubmitEPA') {
      this.intiateEPA.emit(cellData.cell?.data);
    } else {
      //console.log("Redirect");

      this.prescriberSearchService.storeSelectedPrescriber(cellData.cell?.data);
      //console.log(cellData.cell?.data);
      this.goto();
    }
  }

  goto() {
    //console.log("GOto")
    this.router.navigateByUrl("/coverage/drug");
  }

  onPageChange(pageNumber: any) {
    this.currentPageNumber = pageNumber;
    this.loadPrescriberSearchItems(this.getCurrentPageMembers());
  }

  onChangeEntriesPerPage(entriesPerPage: any) {
    this.recordsPerPage = entriesPerPage;
    this.currentPageNumber = 1;
    this.updateTotalPagesCount();
    this.loadPrescriberSearchItems(this.getCurrentPageMembers());
  }

  private getCurrentPageMembers(): PrescriberSearchItem[] {
    this.totalEntries = this.prescriberList.length;
    if (this.prescriberList.length <= this.recordsPerPage) {
      return this.prescriberList;
    }
    else {
      const startIndex: number = (this.currentPageNumber - 1) * this.recordsPerPage;
      const endIndex: number = (this.currentPageNumber * this.recordsPerPage);
      let result = this.prescriberList.slice(startIndex, endIndex);
      return result;
    }

  }

  private updateTotalPagesCount(): void {
    this.totalPagesCount = (this.prescriberList.length % this.recordsPerPage) != 0 ? Math.floor(this.prescriberList.length / this.recordsPerPage) + 1 : Math.floor(this.prescriberList.length / this.recordsPerPage);
  }

  private sortPrescriber(): void {
    this.prescriberList.sort((member1: PrescriberSearchItem, member2: PrescriberSearchItem) => {
      if (this.firstHeader.sortAscending) {
        const result = member1.firstName.localeCompare(member2.firstName);
        return result !== 0 ? result : member1.lastName.localeCompare(member2.lastName);
      } else {
        const result = member2.firstName.localeCompare(member2.firstName);
        return result !== 0 ? result : member1.lastName.localeCompare(member2.lastName);
      }
    });
  }

  removeFavouritePrescriber(prescriber: PrescriberSearchItem) {

    const savedPrescriber: PrescriberFavoriteWebResponse | undefined = this.prescriberSearchService.getFavouritePrescriber(prescriber.identifiers[0].id);
    if (savedPrescriber != undefined) {
      this.prescriberSearchService.removeFavouritePrescriber(savedPrescriber.presbrFavId).then((response: Status) => {
        prescriber.isFavorite = false;
        this.blnFavouriteActionError = false;
        this.ngOnInit();
      }, (error: any) => {
        prescriber.isFavorite = true;
        this.blnFavouriteActionError = true;
        this.modalService.open(this.favouriteErrorTemplateRef, { modalDialogClass: 'favouriteActionErrorModal', size: 'sm' });
      });
    } else {
      this.blnFavouriteActionError = false;
      prescriber.isFavorite = false;
    }
  }


  // Adding a prescriber to favourites list
  savefavourite(prescriberSearchItem: PrescriberSearchItem) {
    let prescriber: PrescriberFavoriteWebRequest = new PrescriberFavoriteWebRequest();
    prescriber.lastName = prescriberSearchItem.lastName;
    prescriber.firstName = prescriberSearchItem.firstName;
    prescriber.address = prescriberSearchItem.address.address1;
    prescriber.instcId = prescriberSearchItem.instanceId;
    prescriber.npi = prescriberSearchItem.identifiers[0].id;
    prescriber.presbrId = this.profileService.loginuser.prescriber.presbrId;
    prescriber.uuid = this.profileService.loginuser.prescriber.uuid;
    this.prescriberSearchService.addFavouritePrescriber(prescriber).then((data: ResponseRootObj) => {
      if (data.status.statusCode != undefined && data.status.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
        && data.status.dbStatusCode != undefined) {
        switch (data.status.dbStatusCode.toString()) {
          case APPCONSTANTS.API_RESPONSE_DB_SUCCESS_CODE:
            this.blnFavouriteActionError = false;
            prescriberSearchItem.isFavorite = true;
            this.ngOnInit();
            break;
          case APPCONSTANTS.API_RESPONSE_FAVOURITEMEMBER_LIMIT_EXCEEDED:
            //Favourite member limit exceeded.
            this.blnFavouriteActionError = false;
            prescriberSearchItem.isFavorite = false;
            this.modalService.open(this.favouriteErrorTemplateRef, { modalDialogClass: 'favouriteActionErrorModal', size: 'sm' });
            break;
          default:
            //Error while saving member into favourite list.
            this.blnFavouriteActionError = true;
            prescriberSearchItem.isFavorite = false;
            break;
        }
      }
    }, (error: any) => {
      //Error while saving member into favourite list.
      this.blnFavouriteActionError = true;
      prescriberSearchItem.isFavorite = false;
      this.modalService.open(this.favouriteErrorTemplateRef, { modalDialogClass: 'favouriteActionErrorModal', size: 'sm' });
    });
  }

  // Removing a prescriber from favourites list
  private removeFavourite(prescriber: PrescriberSearchItem): void {

    const savedPres: PrescriberFavoriteWebResponse | undefined = this.prescriberSearchService.getFavouritePrescriber(prescriber.identifiers[0].id);
    if (savedPres != undefined) {
      this.prescriberSearchService.removeFavouritePrescriber(savedPres.presbrFavId).then((response: Status) => {
        prescriber.isFavorite = false;
        this.blnFavouriteActionError = false;
        this.ngOnInit();
      }, (error: any) => {
        prescriber.isFavorite = true;
        this.blnFavouriteActionError = true;
        this.modalService.open(this.favouriteErrorTemplateRef, { modalDialogClass: 'favouriteActionErrorModal', size: 'sm' });
      });
    } else {
      this.blnFavouriteActionError = false;
      prescriber.isFavorite = false;
    }
  }

  checkFavourites(npi: string) {
    //console.log("fav" + this.savedPrescrbersArry.indexOf(npi) + "" + this.savedPrescrbersArry.length);
    return this.savedPrescrbersArry.indexOf(npi);

  }

  reloadSavedPrescriberSearches() {
    if (this.profileService.loginuser.prescriber.presbrId != undefined && this.profileService.loginuser.UUID != undefined) {
      this.prescriberSearchService.getFavouritePrescribers(this.profileService.loginuser.prescriber.presbrId, this.profileService.loginuser.UUID).then((data: PrescriberFavoriteResponse) => {
        if (data.prescriberFavoriteList != undefined && data.prescriberFavoriteList.length > 0) {
          this.prescriberFavoriteList = data.prescriberFavoriteList;
          //console.log("FavSearch:" + this.prescriberFavoriteList);
          this.loadSavedSearchHistory(data.prescriberFavoriteList);
        }
      }, (error: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
      }).catch((reason: any) => {
        this.modalService.dismissAll();
        this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' });
      });
    }
  }

  // To load savedSearch items into DataTable
  private loadSavedSearchHistory(rawData: PrescriberFavoriteWebResponse[]): void {
    rawData.forEach((history: PrescriberFavoriteWebResponse) => {
      const row: TableRowData<PrescriberFavoriteWebResponse> = new TableRowData<PrescriberFavoriteWebResponse>();
      this.savedPrescrbersArry.push(history.presbrId);
      const firstCell: TableCellData<PrescriberFavoriteWebResponse> = new TableCellData();
      firstCell.data = history;
      firstCell.template = this.SNameTemplate;
      row.cells.push(firstCell);

      const secondCell: TableCellData<PrescriberFavoriteWebResponse> = new TableCellData();
      secondCell.data = history;
      secondCell.template = this.SAddressTemplate;
      row.cells.push(secondCell);

      const thirdCell: TableCellData<PrescriberFavoriteWebResponse> = new TableCellData();
      thirdCell.data = history;
      thirdCell.template = this.SNPITemplate;
      row.cells.push(thirdCell);

      const forthCell: TableCellData<PrescriberFavoriteWebResponse> = new TableCellData();
      forthCell.data = history;
      forthCell.template = this.actionRemove;
      forthCell.clickable = false;
      row.cells.push(forthCell);

      const fifthCell: TableCellData<PrescriberFavoriteWebResponse> = new TableCellData();
      fifthCell.data = history;
      fifthCell.template = this.actionSelect;
      row.cells.push(fifthCell);


      this.savedPrescribersHistory.push(row);
    });

    //console.log(this.savedPrescribersHistory);

  }

  private loadRecentSearchHistory(rawData: PrescriberSearchItem[]): void {

    rawData.forEach((history: PrescriberSearchItem) => {
      const row: TableRowData<PrescriberSearchItem> = new TableRowData<PrescriberSearchItem>();
      this.recentPrescrbersArry.push(history.identifiers[0].id);
      const firstCell: TableCellData<PrescriberSearchItem> = new TableCellData();
      firstCell.data = history;
      firstCell.template = this.RNameTemplate;
      row.cells.push(firstCell);

      const secondCell: TableCellData<PrescriberSearchItem> = new TableCellData();
      secondCell.data = history;
      secondCell.template = this.RAddressTemplate;
      row.cells.push(secondCell);

      const thirdCell: TableCellData<PrescriberSearchItem> = new TableCellData();
      thirdCell.data = history;
      thirdCell.template = this.RNPITemplate;
      row.cells.push(thirdCell);


      this.recentPrescribersHistory.push(row);
    });

    //console.log(this.recentPrescribersHistory);

  }

  //Show Saved Searches 
  showSavedPrescribers() {
    this.logger.userAction("Show Saved Prescriber Clicked.");
    this.modalService.open(this.savedPrescribers, { modalDialogClass: 'content', size: 'lg', backdrop: 'static', keyboard: true });
    this.savedPrescribersHistory = [];
    this.reloadSavedPrescriberSearches();
    if (this.savedPrescribersHistory.length == 0) {

      this.savedMessage = "No saved prescribers";
      //console.log(this.savedMessage);
    }
    if (this.profileService.loginuser.prescriber.presbrId != undefined) {
      this.favouriteActionError = FavouriteActionErrorType.NO_ERROR;
      this.prescriberSearchService.getFavouritePrescribers(this.profileService.loginuser.prescriber.presbrId, this.profileService.loginuser.UUID).then((response: PrescriberFavoriteResponse) => {
        if (response.prescriberFavoriteList != undefined) {
          this.firstHeader.sortAscending = true;
          this.prescribers = response.prescriberFavoriteList;
        } else {
          this.favouriteActionError = FavouriteActionErrorType.RETRIEVE_ERROR;
        }
      }, (error: any) => {
        this.favouriteActionError = FavouriteActionErrorType.RETRIEVE_ERROR;
      }).catch((reason: any) => {
        this.favouriteActionError = FavouriteActionErrorType.RETRIEVE_ERROR;
      });
    }
  }

  // Show Recent Searches
  showRecentPrescribers() {
    this.logger.userAction("Show Recent Prescribers Clicked.");
    this.recentPrescribersHistory = [];
    this.loadRecentSearchHistory(this.recentSearches);
    this.modalService.open(this.recentPrescribers, { modalDialogClass: 'content', size: 'lg', backdrop: 'static', keyboard: true });
    if (this.recentPrescribersHistory.length == 0) {

      this.message = "No recent prescribers";
      //console.log(this.message);
    }
  }

  onRecentPrescribersHeaderClick(headerData: TableClickEvent<TableHeaderCellData>) {

    if (headerData.column == 0) {
      if (headerData.cell?.sortAscending == false) {
        this.recentPrescribersHistory = [];
        this.loadRecentSearchHistory(this.recentSearches.sort((a, b) => {
          return parseInt(a.identifiers[0].id) - parseInt(b.identifiers[0].id);
        }));
      } else {
        this.recentPrescribersHistory = [];
        this.loadRecentSearchHistory(this.recentSearches.sort((a, b) => {
          return parseInt(b.identifiers[0].id) - parseInt(a.identifiers[0].id);
        }));
      }
    }
  }
  onRecentPrescribersCellClick(cellData: TableClickEvent<PrescriberSearchItem>) {

    if (cellData.column == 0 || cellData.column == 1 || cellData.column == 2) {
      this.searchPrescriberNPIForm.reset();
      var npi: string | undefined = cellData.cell?.data?.identifiers[0].id;
      this.NPI?.setValue("" + npi);
      this.modalService.dismissAll();
      this.logger.userAction("Selecting Recent Prescriber");
      this.submitNPIForm();

    }
  }

  onSavedPrescribersHeaderClick(headerData: TableClickEvent<TableHeaderCellData>) {
    this.savedSearches = this.prescribers;
    if (headerData.cell != undefined) {
      this.loadPrescriberSearchItems(this.getCurrentPageMembers())
    }
    if (headerData.column == 0) {
      if (headerData.cell?.sortAscending == false) {
        this.savedPrescribersHistory = [];
        this.loadSavedSearchHistory(this.savedSearches.sort((a, b) => {
          return (a.lastName.toLowerCase() + " " + a.firstName.toLowerCase()).localeCompare(b.lastName.toLowerCase() + " " + b.firstName.toLowerCase());

        }));
      } else {
        this.savedPrescribersHistory = [];
        this.loadSavedSearchHistory(this.savedSearches.sort((a, b) => {
          //return parseInt(b.lastName) - parseInt(a.lastName);
          return (b.lastName.toLowerCase() + " " + b.firstName.toLowerCase()).localeCompare(a.lastName.toLowerCase() + " " + a.firstName.toLowerCase());

        }));
      }
    }
  }

  private unfavourite(prescriber: PrescriberFavoriteWebResponse): void {
    this.prescriberSearchService.removeFavouritePrescriber(prescriber.presbrFavId).then((response: Status) => {
      if (response.statusCode != undefined && response.statusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_SUCCESS_TXT
        && response.dbStatusCode != undefined && response.dbStatusCode.toLowerCase() == APPCONSTANTS.API_RESPONSE_DB_SUCCESS_CODE) {
        this.showSavedPrescribers();
      } else {
        this.favouriteActionError = FavouriteActionErrorType.UNFAVOURITE_ERROR;
      }

    }, (error: any) => {
      this.favouriteActionError = FavouriteActionErrorType.UNFAVOURITE_ERROR;
    })
    this.modalService.dismissAll();
  }

  onSavedPrescribersCellClick(event: TableClickEvent<PrescriberFavoriteWebResponse>): void {

    if (event.column == 3 || event.event.target.getAttribute('name') == "Remove") {
      //remove member from favourites list
      if (event.cell != undefined && event.cell.data != undefined) {
        this.unfavourite(event.cell?.data)
        this.logger.userAction("Removing Favourite Prescriber");
      }
    } else {

      this.logger.userAction("Selecting Favourite Prescriber");
      this.searchPrescriberNPIForm.reset();
      var npi: string | undefined = event.cell?.data?.npi;
      var name: string | undefined = event.cell?.data?.lastName;
      //console.log("name" + event.cell?.data?.lastName);
      //console.log("lastname" + name);
      //console.log("npi:" + npi);
      this.NPI?.setValue("" + npi);
      this.lastName?.setValue("" + name);
      this.modalService.dismissAll();
      this.submitNPIForm();
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    // const ClickEvent = new MouseEvent('click', {
    //   view: window,
    //   bubbles: true,
    //   cancelable: true
    // });

    // if(event.keyCode == KEY_CODE.SPACE_BAR || event.keyCode == KEY_CODE.ENTER){
    //   if(this.activeTab==1)
    //     this.submitNPIForm();
    //   else
    //     this.submitNPINameForm();
    // }

    if (event.keyCode == KEY_CODE.RIGHT_ARROW) {
      window.scroll(0, 800);
      if (this.activeTab < 2)
        this.activeTab = this.activeTab + 1;
      else
        this.activeTab = 1;
    }

    if (event.keyCode == KEY_CODE.LEFT_ARROW) {
      window.scroll(0, 800);
      if (this.activeTab > 1)
        this.activeTab = this.activeTab - 1;
      else
        this.activeTab = 2;
    }
  }
} 
