
<div class="row justify-content-center">
    <div class="col-md-8 col-sm-8 col-xs-12" style="background-color: rgba(242, 242, 242, 1); ">
    <div class="col">

        <!-- Progress bar  -->
        <!-- <app-progress-tabs></app-progress-tabs> -->

        <!-- Search medication Heading and form  -->
        <div class=" container-fluid shadow" style="padding: 20px; background-color: white;">
            <div class="row">

                <div class="col-md-7 col-sm-7 col-xs-12">
                    <h2 class="text-sm-left lh-base font-normal text-none text-decoration-none text-reset">
                        <strong style="color:#002677">Step 3 of 3: Medication request information</strong>
                    </h2>
                    <p class="text-sm-left lh-base font-normal text-none text-decoration-none text-reset">
                       <span class="fs-7" style="color: #636363;"> All fields required unless otherwise indicated.</span>
                    </p>
                </div>

                <!-- Saved and Recent Searches Buttons  -->
                <div class="col-md-5 col-sm-5 col-xs-12">
                    <ul class="list-inline float-end">
                        <button (click)="showRecentSeraches()" class="list-inline-item underline fw-bold">Recent medications</button>
                        |
                        <button (click)="showSavedSeraches()" class="list-inline-item underline fw-bold">Saved medications</button>
                    </ul>
                </div>
            </div>
        </div>

<!--testing-->
<div class="container-fluid bg-white closedsearchcriteria content-card" >
    <div class="row">
        <div class="col-2">
          <div style="font-weight: bold;">NAME</div>
          <div style="text-transform: capitalize;">{{memberDetails.firstName}} {{memberDetails.lastName}}</div>          
        </div>
        <div class="col-4">
          <div style="font-weight: bold;">Address</div>
          <div  style="text-transform: capitalize;">
            <span *ngIf="memberDetails.address.address1 !=''">{{memberDetails.address.address1}},&nbsp;</span>
            <span *ngIf="memberDetails.address.address2 !=''">{{memberDetails.address.address2}},&nbsp;</span>
            <span *ngIf="memberDetails.address.city !=''">{{memberDetails.address.city}},&nbsp;</span>
            <span *ngIf="memberDetails.address.state !=''">{{memberDetails.address.state}},&nbsp;</span>
            <span *ngIf="memberDetails.address.country !=''">{{memberDetails.address.country}},&nbsp;</span>
            <span *ngIf="memberDetails.address.zip1 !=''">{{memberDetails.address.zip1}}.</span>
          </div>          
        </div>
        <div class="col-2">
          <div style="font-weight: bold;">Member ID</div>
          <div>{{memberDetails.memberId}}</div>          
        </div>
        <div class="col-2">
          <div style="font-weight: bold;">Group ID</div>
          <div>{{memberDetails.groupId}}</div>          
        </div>  
    </div>
  </div>
  <!--testing-->

        <!-- Error message if CheckBoxes are not selected on form submition -->
        <div *ngIf="!submitedValidClaimList && drugQuestions=='Y' && payOption==-1" class="mt-1 container-fluid shadow"
            style="padding: 15px 25px; background-color:#fff4f4;">
            <label style="color:#c60f13;"><strong>
                    One of the checkboxes needs to be selected.
                </strong></label>
        </div>

        <!-- Add Claim form (Select NDC and Strength)-->
        <form [formGroup]="addMedicationForm">
            <div class="container-fluid shadow"
                style="padding: 20px; background-color: white;  margin-top: 3px; margin-bottom: 20px;">
                <div class="row" @slideDown *ngIf="drugQuestions=='Y'"
                    [ngClass]="{'inpError': (!submitedValidClaimList && drugQuestions=='Y' && payOption==-1)}">
                    <div class="row" style="margin-bottom: 15px;">
                        <label class="inputFieldLabel" for="text" style="text-transform: none;">Contracted Network
                            Provider: {{this.profileService.loginuser.selectedInfusion.getValue().pharmacyName}}</label>
                    </div>
                    <div class="row">
                        <div style="margin-left: 25px; margin-bottom: 15px;">
                            <input type="checkbox" name="payBoth" (click)="updateDrugAdmin($event)" (keydown.enter)="updateDrugAdmin($event)"
                                [checked]="payOption==0">&nbsp;
                            <label class="inputFieldLabel" for="feetext" style="text-transform: none;">Provider
                                requires
                                payment for both drug and administration fees.</label>
                        </div>
                        <div style="margin-left: 25px; margin-top: 5px;white-space:wrap;">
                            <input type="checkbox" name="payDrugOnly" [checked]="payOption==1"
                                (click)="updateDrugAdmin($event)" (keydown.enter)="updateDrugAdmin($event)" style="vertical-align:top ;">&nbsp;
                            <label class="inputFieldLabel" for="admintext"
                                style="text-transform:none;white-space:normal">Provider requires payment for drug
                                administration only</label>
                        </div>
                        <div style="margin-left: 25px; margin-top: 5px; margin-bottom: 25px;white-space:wrap;">
                            <label class="inputFieldLabel1" for="nocosttext" style="text-transform: none;">(product is
                                being
                                provided directly to provider via Optum Specialty Pharmacy at no cost.)</label>
                        </div>
                    </div>
                </div>
                <div class="row" style=" margin-top: 5px;">
                    <div class="col-sm-6">
                        <div class="mb-3">
                            <label for="ndc" class="form-label float-left">Medication name or NDC
                                <div *ngIf="searching" class="spinner-border spinner-border-sm" role="status"
                                    aria-hidden="true"></div>
                            </label>

                            <div class="search" placement="bottom" ngbTooltip="{{drugSearchModel?.value}}"
                                triggers="hover:blur" aria-hidden="true" aria-required="true" >
                                <i *ngIf="searchByNDC.length>0" class="fa fa-check"></i>
                                <input
                                    [ngClass]="{'inpError': (drugSearchModel && !drugSearchModel.valid && !drugSearchModel.pristine),'validInput':(searchByNDC.length>0)}"
                                    id="ndcname" type="text" class="inp rounded-1 form-control inp"
                                    [ngbTypeahead]="searchNDC" (selectItem)="itemSelected($event)"
                                    (change)="this.onChangeNDC()" formControlName="drugSearchModel">
                            </div>

                            <div *ngIf="drugSearchModel && drugSearchModel.invalid && drugSearchModel.dirty">
                                <div class="text-danger"
                                    *ngIf="drugSearchModel.errors && drugSearchModel.errors?.['minlength']">
                                    <small>Please enter min 3 characters.</small>
                                </div>
                                <!-- <div class="text-danger" *ngIf="drugSearchModel.errors && !drugSearchModel.errors?.['minlength']"><small>No available NDC found matching the value entered.</small></div> -->
                                <div class="text-danger no-data shadow p-2"
                                    *ngIf="drugSearchModel.errors && !drugSearchModel.errors?.['minlength'] && drugSearchModel.value==null && drugSearchModel">
                                    <br>
                                    <h3>Medication not found.</h3><span class="paragraph">
                                        No available NDC found matching the value entered.
                                        <!-- Please check the spelling.
                                        Medication
                                        may not be eligible for billing through Specialty Medication Access
                                        Portal. -->
                                    </span><br>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6" [ngClass]="{'AddOpacity': (dropdown.length==0)}">
                        <label for="MedStrength" class="form-label">Medication strength
                            <div *ngIf="formLoading" class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true">
                            </div>
                        </label>
                        <select *ngIf="!(searchByNDC.length>0) && dropdown.length!=1" class="rounded-1 inp form-select"
                            type="text" (change)="onChangeMediactionStrenth()" aria-label="Default select example"
                            formControlName="MedStrength" placement="bottom" ngbTooltip="{{medStrengthTitle}}"
                            triggers="hover:blur" aria-hidden="true" aria-required="true">
                            <option value="">Select strength</option>
                            <option *ngFor="let item of dropdown| drugnamesort; let i = index"
                                [value]="dropdown[i].productId">
                                ({{item.productId}}) {{item.detailedDrugName}}
                            </option>
                        </select>

                        <input *ngIf="searchByNDC.length>0 || dropdown.length==1"  aria-hidden="true" type="text"
                         class=" rounded-1 form-control inp"
                            value="{{dropdown[0].detailedDrugName}}" disabled>
                    </div>
                </div>
            </div>

            <!-- Quantity values form (Quantity, Days, Drug Administration and Diagnosis Code)-->
            <div class="col nopadding" *ngIf="!submitedValidAddMedicationForm" class="container-fluid shadow"
                style="padding: 15px 25px; background-color:#fff4f4; border-bottom: 2px solid #c60f13" >
                <i class="fa fa-exclamation-circle" aria-hidden="true" style="color: #c60f13; padding-right: 5px;"></i>
                <label style="color: #222222;"><span>
                        Please enter all required fields.
                    </span></label>
            </div>
            <div @fade
                *ngIf="MedStrength && MedStrength.valid && drugSearchModel && drugSearchModel.valid && qtyPageToggle"
                class="container-fluid shadow" style="padding: 20px; background-color: white;  ">
                <h5 *ngIf="!formLoading" style="padding-bottom: 5px; border-bottom: 1px solid lightgray;">
                    {{currentSelectedDrug[0].detailedDrugName}}</h5>
                <p *ngIf="!formLoading">Product ID: {{currentSelectedDrug[0].productId}}</p>

                <div class="row" style=" margin-top: 5px;">
                    <!-- Quantity field  -->
                    <div class="col-sm-4">
                        <div class="mb-3">
                            <label for="Quantity" class="form-label">Quantity / Vials<i
                                    class="fa fa-question-circle-o fafa-Question-Cirile" placement="top"
                                    ngbTooltip="Quantity dispensed must reflect the exact metric decimal quantity, without rounding representative of the package, such as a vial or each <i>(e.g. using 5 - 10ml vials entered as 5)</i>"
                                    aria-hidden="true" aria-required="true" ></i></label>
                            <input
                                [ngClass]="{'inpError': (addMedicationForm.controls['Quantity'].invalid && addMedicationForm.controls['Quantity'].dirty && addMedicationForm.controls['Quantity'].value!=''),  'inpSubmittedError': (!submitedValidAddMedicationForm && addMedicationForm.controls['Quantity'].invalid)}"
                                type="text" class="inp rounded-1 form-control inp" id="Quantity"
                                aria-describedby="emailHelp" name="Quantity" formControlName="Quantity" maxlength="11"
                                (keyup)="calculateQuantity()">
                            <div class="text-danger"
                                *ngIf="Quantity && Quantity.invalid  && Quantity.dirty && Quantity.value!=''">
                                <small>Invalid
                                    character</small>
                            </div>
                            <!-- <div class="text-danger" *ngIf="Quantity && Quantity.dirty && Quantity.errors && Quantity.errors?.['min']"><small>Min length</small></div> -->
                            <!-- <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
                        </div>
                    </div>

                    <div class="col-sm-4">
                        <!-- Days of Supply field -->
                        <div class="mb-3">
                            <label for="DaySupply" class="form-label">Days supply / Frequency in days<i
                                    class="fa fa-question-circle-o fafa-Question-Cirile" placement="top"
                                    ngbTooltip="Days supply should accurately reflect the documented utilization and quantity dispensed as a therapeutic duration <i>(e.g. medication provided in a single dosage/treatment yet therapeutic duration/entry is 28 days)</i>."
                                    aria-hidden="true" aria-required="true" ></i></label>
                            <input type="text"
                                [ngClass]="{'inpError': (addMedicationForm.controls['Days'].invalid && !addMedicationForm.controls['Days'].pristine && addMedicationForm.controls['Days'].value!=''), 'inpSubmittedError': (!submitedValidAddMedicationForm && addMedicationForm.controls['Days'].invalid)}"
                                class=" inp rounded-1 form-control inp" id="DaySupply" aria-describedby="emailHelp"
                                name="Days" formControlName="Days" maxlength="3" (keyup)="calculateQuantity()">
                            <div class="text-danger" *ngIf="Days && Days.invalid && Days.dirty && Days.value!=''">
                                <small>Invalid
                                    character</small>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-7  mt-2 mb-2" @fade *ngIf="quantityCalculated!=''">
                        <label class="form-label rounded p-3"
                            style="background-color: #dce9f3; color: #0470c5;"><strong>{{quantityCalculated}}</strong></label>
                    </div>
                </div>

                <!-- Drug Administration field -->
                <div class="col-sm-6" style="margin-bottom: 20px;">
                    <label for="drugadmin" class="form-label" style="margin-bottom: 0px;">Drug
                        administration</label>
                    <div id="emailHelp" class="form-text" style="margin-bottom: 8px;">Level of effort to administer
                        the
                        drug
                    </div>
                    <select class="rounded-1 inp form-select" aria-label="Default select example" type="text" aria-required="true"  name="DrugAdmin"
                        [ngClass]="{'inpError': (!addMedicationForm.controls['DrugAdmin'].valid && !addMedicationForm.controls['DrugAdmin'].pristine), 'inpSubmittedError': (!submitedValidAddMedicationForm && addMedicationForm.controls['DrugAdmin'].invalid)}"
                        formControlName="DrugAdmin">
                        <option selected value="">Select drug administration</option>
                        <option value="00">DUR-PPS 00 - No administration</option>
                        <option value="11">DUR-PPS 11 - &lt; 1 hour</option>
                        <option value="12">DUR-PPS 12 - 1-2 hours</option>
                        <option value="13">DUR-PPS 13 - 2-3 hours</option>
                        <option value="14">DUR-PPS 14 - 3-4 hours</option>
                        <option value="15">DUR-PPS 15 - &gt; 4 hours</option>
                    </select>
                </div>

                <div class="row">
                    <!-- Diagnosis Code Field -->
                    <div class="col">
                        <label for="icdcode" class="form-label float-start mt-2">ICD-10
                            Diagnosis
                            code</label>
                    </div>

                    <div class="col">
                        <button (click)="showDiagnosisCode()" class="btn invisiblebtn btn-link float-end">Diagnosis code
                            keyword
                            search</button>
                    </div>

                    <div class="col-12">
                        <!-- <input type="text" class="form-control" id="DaySupply" aria-describedby="emailHelp"> -->
                        <!-- <input id="typeahead-http" type="text" class="form-control" [class.is-invalid]="searchFailed" [(ngModel)]="diagnosesModel" [ngbTypeahead]="diagnosisSearch"  placeholder="Wikipedia search" #val/> -->
                        <input ng-minLength="3"
                            [ngClass]="{'inpError': (!addMedicationForm.controls['DiagnosisCode'].valid && !addMedicationForm.controls['DiagnosisCode'].pristine), 'inpSubmittedError': (!submitedValidAddMedicationForm && addMedicationForm.controls['DiagnosisCode'].invalid)}"
                            id="diagCode" type="text" class="inp rounded-1 form-control inp"
                            [ngbTypeahead]="diagnosisSearch" (keyup)="onChangeDiagnosisInput($event,val.value)" #val
                            formControlName="DiagnosisCode" aria-required="true" >
                        <small *ngIf="searchingDiagnosisCode" class="form-text text-muted">searching...</small>
                        <div *ngIf="DiagnosisCode && DiagnosisCode.invalid && DiagnosisCode.dirty">
                            <div class="text-danger" *ngIf="DiagnosisCode.errors && !DiagnosisCode.errors['minLength']">
                                <small>Diagnosis code must be entered in ICD-10 format.</small>
                            </div>
                            <div class="text-danger" *ngIf="DiagnosisCode.errors && DiagnosisCode.errors['minLength']">
                                <small>Please enter min 3 char.</small>
                            </div>
                        </div>
                        <div><small>To potentially avoid a PA, enter the diagnosis code related to the medication
                                prescribed.</small></div>
                        <!-- <small class="invalid-feedback" *ngIf="searchFailed">Sorry, suggestions could not be loaded.</small> -->
                    </div>
                </div>
            </div>

            <!-- Close and Add medication Buttons -->
            <div @fade *ngIf="qtyPageToggle && drugSearchModel && drugSearchModel.valid && MedStrength && MedStrength.value!=''" class="container p-2 mx-auto">
                
                <div class="col card-footer text-end pb-2">
                    <a class="btn btn-link" (click)="resetForm()" class="underline m-2" tabindex="0">Close</a>
                    <button (click)="add()" class="btn btn-primary text-white shadow rounded-pill "
                    type="submit" role="button">Add</button>
                
                </div>
            </div>
        </form>

        <!-- End of Add claim form -->


        <!-- Start of Claims List View and Edit claims -->
        <!-- Edit Claims -->
        <div [formGroup]="form" [@listAnimation]="claimsList.length">
            <!-- <div formArrayName="claimsList" *ngFor="let claimForm of claimsList.controls;  index as i"> -->
            <div formArrayName="claimsList" *ngFor="let claimForm of selectedMedicine;  index as i">
                <div class="container-fluid shadow" style="padding: 20px; background-color: white;  margin-top: 20px;">

                    <!-- Headings for each claim and Edit/Remove buttons -->
                    <div class="row" style="border-bottom: 1px solid lightgray;">
                        <div class="col-9">
                            <label class="h5 float-start"
                                style="padding-bottom: 5px; ">{{selectedMedicine[i].MedStrengthName}} </label>
                            <div class=" float-start ms-2" *ngIf="checkFavourites(selectedMedicine[i].MedStrength)==-1">
                                <button
                                    (click)="savefavourite('('+selectedMedicine[i].MedStrength+') '+selectedMedicine[i].MedStrengthName , selectedMedicine[i].MedStrength,i)" (keyup.enter)="savefavourite('('+selectedMedicine[i].MedStrength+') '+selectedMedicine[i].MedStrengthName , selectedMedicine[i].MedStrength,i)"
                                    class="btn notfavorite" aria-label="Make your default medication"></button>
                            </div>
                            <div class=" float-start ms-2" *ngIf="checkFavourites(selectedMedicine[i].MedStrength)!=-1">
                                <button (click)="removefavourite(selectedMedicine[i].MedStrength)" (keyup.enter)="removefavourite(selectedMedicine[i].MedStrength)"
                                    class="btn favorite" aria-label="Remove your default medication"></button>
                            </div>
                        </div>

                        <div class="col-3">
                            <div class="float-end">
                                <button class="btn btn-link underline" (keydown.enter)="remove(i)"><label class="col float-start text-danger" tabindex="0" style="font-weight: 700;" tabindex="0"
                                    (click)="remove(i)" (keydown.enter)="remove(i)">Remove</label></button>
                                <button class="btn btn-link edit-remove"
                                [class.disabled]="claimsList.controls[i].invalid && this.editButtonList[i]=='Save'" (keydown.enter)="edit(i)"><a
                                    class="col float-end underline" tabindex="0"
                                    (click)="edit(i)" (keydown.enter)="edit(i)">{{this.editButtonList[i]}}</a></button>
                            </div>
                        </div>
                    </div>
                    <!-- Claim Details View headers -->
                    <div class="row mt-3">
                        <p>Product ID: {{selectedMedicine[i].MedStrength}}</p>
                    </div>
                    <ng-container *ngIf="this.editButtonList[i]!='Save'">
                        <div class="row text-muted">
                            <div class="col-2">
                                <label for="quantity" class="float-start">Quantity <i
                                        class="fa fa-question-circle-o fafa-Question-Cirile" placement="top"
                                        ngbTooltip="Quantity dispensed must reflect the exact metric decimal quantity, without rounding representative of the package, such as a vial or each <i>(e.g. using 5 - 10ml vials entered as 5)</i>"
                                        aria-hidden="true"></i></label>
                            </div>
                            <div class="col-2">
                                <label for="dayssupply" class="float-start">Days supply <i
                                        class="fa fa-question-circle-o fafa-Question-Cirile" placement="top"
                                        ngbTooltip="Days supply should accurately reflect the documented utilization and quantity dispensed as a therapeutic duration <i>(e.g. medication provided in a single dosage/treatment yet therapeutic duration/entry is 28 days)</i>."
                                        aria-hidden="true"></i></label>
                            </div>
                            <div class="col-5">
                                <label for="loe" class="float-start">Drug administration level of effort to
                                    administer the
                                    drug</label>
                            </div>
                            <div class="col-3">
                                <label for="diagnosiscode" class="float-start">ICD-10 Diagnosis code</label>
                            </div>
                        </div>

                        <!-- Claim Details View Values -->
                        <div class="row">
                            <div class="col-2">
                                <label for="quantityValue" class="float-start">{{selectedMedicine[i].Quantity}}</label>
                            </div>
                            <div class="col-2">
                                <label for="DaySupplyValue" class="float-start">{{selectedMedicine[i].Days}}</label>
                            </div>
                            <div class="col-5">
                                <label for="DrugadminValue" class="float-start">{{selectedMedicine[i].DrugAdmin}}</label>
                            </div>
                            <div class="col-3">
                                <label for="DiagnosisCodeValue" class="float-start">{{selectedMedicine[i].DiagnosisCode}}</label>
                            </div>
                        </div>
                    </ng-container>

                    <!-- Edit for for Quantity, Days of Supply, Drug Administration and Diagnosis code for each claim-->
                    <div *ngIf="this.editButtonList[i]=='Save'" [formGroupName]="i">

                        <div style=" margin-top: 20px;">
                            <div class="row" style=" margin-top: 5px;">
                                <div class="col-sm-4">

                                    <!-- Quantity Edit field -->
                                    <div class="mb-3">
                                        <label for="Quantity/Vials" class="form-label">Quantity / Vials<i
                                                class="fa fa-question-circle-o fafa-Question-Cirile" placement="top"
                                                ngbTooltip="Quantity dispensed must reflect the exact metric decimal quantity, without rounding representative of the package, such as a vial or each <i>(e.g. using 5 - 10ml vials entered as 5)</i>"
                                                aria-hidden="true"></i></label>

                                        <input type="text"
                                            [ngClass]="{'inpError': (!claimsList.controls[i].get('Quantity')?.valid && claimsList.controls[i].get('Quantity')?.dirty)}"
                                            class=" inp rounded-1 form-control inp" aria-describedby="emailHelp"
                                            formControlName="Quantity" maxlength="7">
                                        <div class="text-danger"
                                            *ngIf="claimsList.controls[i].get('Quantity')?.invalid && claimsList.controls[i].get('Quantity')?.dirty">
                                            <small>Invalid character</small>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-4">
                                    <!-- Days of Supply edit field -->
                                    <div class="mb-3">
                                        <label for="DaySupply/Frequency" class="form-label">Days supply / Frequency in days<i
                                                class="fa fa-question-circle-o fafa-Question-Cirile" placement="top"
                                                ngbTooltip="Days supply should accurately reflect the documented utilization and quantity dispensed as a therapeutic duration <i>(e.g. medication provided in a single dosage/treatment yet therapeutic duration/entry is 28 days)</i>."
                                                aria-hidden="true"></i></label>
                                        <input type="text"
                                            [ngClass]="{'inpError': (!claimsList.controls[i].get('Days')?.valid && claimsList.controls[i].get('Days')?.dirty)}"
                                            class=" inp rounded-1 form-control inp" aria-describedby="emailHelp"
                                            formControlName="Days" maxlength="3">
                                        <div class="text-danger"
                                            *ngIf="claimsList.controls[i].get('Days')?.dirty && claimsList.controls[i].get('Days')?.invalid">
                                            <small>Invalid character</small>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Drug Administration Edit field -->
                            <div class="col-sm-6" style="margin-bottom: 20px;">
                                <label for="exampleInputEmail1" class="form-label" style="margin-bottom: 0px;">Drug
                                    administration</label>
                                <div id="LevOfEff" class="form-text" style="margin-bottom: 8px;">Level of effort to
                                    administer
                                    the drug</div>
                                <select class="inp rounded-1 form-select" aria-label="Default select example"
                                    [ngClass]="{'inpError': (!claimsList.controls[i].get('DrugAdmin')?.valid && !claimsList.controls[i].get('DrugAdmin')?.pristine)}"
                                    formControlName="DrugAdmin">
                                    <option value="">&nbsp;Select drug administration</option>
                                    <option value="00">DUR-PPS 00 - No administration
                                    </option>
                                    <option value="11">DUR-PPS 11 - &lt; 1 hour</option>
                                    <option value="12">DUR-PPS 12 - 1-2 hours</option>
                                    <option value="13">DUR-PPS 13 - 2-3 hours</option>
                                    <option value="14">DUR-PPS 14 - 3-4 hours</option>
                                    <option value="15">DUR-PPS 15 - &gt; 4 hours</option>
                                </select>
                            </div>

                            <!-- Diagnosis Code Edit Field -->
                            <div class="row">
                                <div class="col">
                                    <label for="ICD-10 code" class="form-label float-start">ICD-10 Diagnosis code</label>
                                </div>

                                <div class="col">
                                    <a><label for="DcKeyword" (click)="showDiagnosisCode()"
                                            class="form-label float-end underline" style="color: #0470c5;">Diagnosis
                                            code keyword
                                            search</label></a>
                                </div>

                                <div class="col-12">
                                    <input id="search" type="text"
                                        [ngClass]="{'inpError': (!claimsList.controls[i].get('DiagnosisCode')?.valid && !claimsList.controls[i].get('DiagnosisCode')?.pristine)}"
                                        class=" inp rounded-1 form-control inp" [ngbTypeahead]="diagnosisSearch"
                                        (keyup)="onChangeDiagnosisInput($event,val.value)" #val
                                        formControlName="DiagnosisCode">
                                    <small *ngIf="searching" class="form-text text-muted">searching...</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END Edit Claims -->

        <!-- Date and Place of Service Form -->
        <form [formGroup]="checkCoverage">
            <div *ngIf="claimsList.length>0" class="container-fluid shadow"
                style="padding: 20px; background-color: white;  margin-top: 30px;">

                <div class="row" style=" margin-top: 5px;">

                    <!-- Date of Service -->
                    <div class="col-sm-6">
                        <div class="mb-3 row">
                            <label for="Dos" class="form-label float-left col" aria-required="true" type="text">Date of service (mm/dd/yyyy) </label>
                            <div style="position: relative;">
                                <datefield [validationMode]="dosValidationMode" [content]="dosValue"
                                    (onUpdate)="onDobUpdate($event)" [emptyValid]="emptyDOS"
                                    [dateFieldName]="dateFieldName">
                                </datefield>
                            </div>

                        </div>
                    </div>


                    <!-- Place of service -->
                    <div class="col-sm-6">
                        <label for="pos" class="form-label" type="text">Place of service</label>
                        <select class="inp rounded-1 form-select" aria-label="Default select example" aria-required="true" 
                            [ngClass]="{'inpError': (!placeOfService.valid && !placeOfService.pristine)}"
                            formControlName="placeOfService">
                            <option selected value="">-Select-</option>
                            <option label="POS 11 - Office" value="11">POS 11 - Office</option>
                            <option label="POS 12 - Home" value="12">POS 12 - Home</option>
                            <option label="POS 19 - Off Campus Outpatient Hospital" value="19">POS 19 - Off campus
                                outpatient
                                hospital</option>
                            <option label="POS 22 - On Campus Outpatient Hospital" value="22">POS 22 - On campus
                                outpatient
                                hospital</option>
                            <option label="POS 24 - Ambulatory Surgical Center" value="24">POS 24 - Ambulatory surgical
                                center
                            </option>
                        </select>
                        <!-- <div class="text-danger" *ngIf="MedStrength && MedStrength.invalid && MedStrength.touched"><small>Invalid Input</small></div>   -->
                    </div>
                </div>

            </div>
            <!--End Date and Place of Service Form -->

            <div *ngIf="claimsList.length>0" class="container container shadow-lg p-3 mb-5" style="padding:20px; background-color: #f2f6f9; margin-top: 0px;">
                <div class="row">
                    <!--Check Coverage and Back Button -->
                    <div class="col card-footer text-end me-3">
                        <a  class="btn btn-link" type="button" class="underline me-2" tabindex="0" (click)="back($event)" (keyup.enter)="back($event)">Back</a>
                        <button [disabled]="checkCoverage.invalid" class="btn btn-primary text-white shadow rounded-pill" role="button"
                            (click)="checkCoveragePage()">Check coverage</button>
                    </div>
                </div>
            </div>
        </form>

        <!-- <app-error-popup *ngIf="errorText!=''" (updateMessage)="resetErrorMessage($event)" [message]="errorText">
</app-error-popup> -->
        <!-- {{errorText}} -->
    </div>
</div>
</div>




<!-- Modals for Error, Diagnosis code, Recent Searches and Saved Searches-->
<!-- ClaimSummaryProgress Modal -->
<ng-template #claimSummaryProgress let-modal>
    <div style="height:200px" role="dialog" aria-labelledby="claimSummaryProgress">
        <div class="modal-body mt-2">
            <div class="p-3">
                <div *ngIf="this.counter==10" class="row text-center"><i class="fa fa-ban  text-danger fs-1"
                        aria-hidden="true"></i></div>
                <div @slideDown *ngIf="this.counter==10" class="text-center mt-3 fs-6">
                    {{this.trailAdjuducicationError}}</div>
                <div *ngIf="this.counter==10" @slideDown class="modal-footer border-0 mt-5">
                    <button class="btn btn-secondary position-absolute top-80 start-50 translate-middle"
                        (click)="dismissModal()">ok</button>
                </div>
                <div *ngIf="this.counter==3" class="circle">
                    <div class="checkMark"></div>
                </div>
                <div *ngIf="this.counter<3" class="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div @slideDown *ngIf="this.counter==3" class="text-center fs-6" tabindex="-1" aria-modal="true">Check coverage complete</div>

                <div @slideDown *ngIf="this.counter==2" class="text-center fs-6" tabindex="-1" aria-modal="true">Retrieving results...</div>

                <div @slideDown *ngIf="this.counter==1" class="text-center fs-6" tabindex="-1" aria-modal="true">Analyzing information…</div>

            </div>
        </div>

    </div>

</ng-template>

<!-- Error Modal -->
<ng-template #errorContent let-modal>
    <div class="p-3" role="dialog" aria-labelledby="errorContent">
        <div class="row text-center"><i class="fa fa-ban" aria-hidden="true"></i></div>
        <div class="text-center mt-2" tabindex="-1" aria-modal="true">
            {{errorText}}
        </div>
        <div class="modal-footer border-0 mt-5">
            <button class="btn btn-secondary position-absolute top-80 start-50 translate-middle"
                (click)="dismissModal()">ok</button>
        </div>
    </div>
</ng-template>

<!-- Loading.... -->
<ng-template #LoadingContent let-modal>
    <div class="modal-body" aria-hidden="true" role="dialog" aria-labelledby="LoadingContent">
        <h3 class="text-center" tabindex="-1" aria-modal="true">Loading...</h3>
    </div>
</ng-template>

<!-- Loading....
<ng-template #LimitExceeded let-modal class="modal fade" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="p-3">
            <div class="row text-center" ><i class="fa fa-ban" aria-hidden="true"></i></div>
            <div class="">
            Your saved drug limit is ten. Please remove an existing saved drug to continue.
            </div>
        <div class="modal-footer border-0">
          <button class="btn btn-secondary" (click)="dismissModal()">ok</button>
        </div>
    </div>
</ng-template> -->

<!-- Diagnosis Code Modal -->
<ng-template #diagnosisCode let-modal>
    <div class="modal-header"  aria-hidden="true" role="dialog" aria-labelledby="LoadingContent">
        <h2 class="modal-title" tabindex="-1" aria-modal="true">Search for a diagnosis code by keyword</h2>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div *ngIf="diagnosisDescription && searchDiagnosisClicked && !diagnosisDescription.valid"
        style="padding: 15px; background-color:#fff4f4;">
        <label style="color:#c60f13;"><strong>
                Please enter min 2 characters.
            </strong></label>
    </div>
    <div class="modal-body">
        <!--  NPI Seach Form-->
        <form [formGroup]="diagnosisForm">

            <div style="padding:10px;">
                <label for="text" class="form-label float-left">Enter a diagnosis description</label>
                <div class="row">
                    <div class="search col-md-10 col-sm-10 col-8">
                        <i class="fa fa-search"></i>
                        <input type="text" class="form-control inp rounded-0"
                            [ngClass]="{'inpSubmittedError alert-danger': (diagnosisDescription && !diagnosisDescription.valid && !diagnosisDescription.pristine)||(diagnosisDescription && searchDiagnosisClicked && !diagnosisDescription.valid)}"
                            id="typeahead-config" type="text" formControlName="diagnosisDescription"
                            style="text-indent: 20px;">
                        <div *ngIf="diagnosisDescription && diagnosisDescription.invalid && diagnosisDescription.dirty">
                            <div class="text-danger"
                                *ngIf="diagnosisDescription && diagnosisDescription.errors?.['minlength'] && !searchDiagnosisClicked">
                                <small>Please enter min 2 characters.</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 col-md-2 col-sm-2">
                        <button type="button" (click)="searchDiagnosisKeyword()"
                            class="btn btn-primary text-white shadow rounded-pill float-end">Search</button>
                    </div>
                </div>
                <div class="row mt-3" *ngIf="noOfRecords>0">

                    <div class="col">
                        <label class="form-label"><strong>{{noOfRecords}}</strong> results found.</label>
                    </div>
                    <div class="col">
                        <!-- Arrow Button for Previous and Next items in list -->
                        <label class="form-label float-end">{{recordsDisplayedCount}} of {{noOfRecords}} &nbsp;
                            <a (click)="loadPreviousDiagnosisKeywords()"><i class="fa fa-chevron-left"
                                    [ngClass]="{arrowDisabled: currentPage==1, arrowActive: currentPage>1}"
                                    aria-hidden="true"></i></a>
                            &nbsp;
                            <a (click)="loadNextDiagnosisKeywords()"><i class="fa fa-chevron-right arrowActive"
                                    [ngClass]="{arrowDisabled: currentPage==noOfPages, arrowActive: currentPage<noOfPages}"
                                    aria-hidden="true"></i>
                            </a>
                        </label>
                    </div>
                </div>
                <datatable *ngIf="noOfRecords>0" [headers]="diagnosisSearchHeaders" [rows]="diagnosisSearchHistory"
                    (onHeaderClicked)="onDiagnosisHeaderClick($event)" (onCellClicked)="onDiagnosisCellClick($event)" aria-labelledby="diagnosisResults">
                </datatable>
                <div class="row mt-3" *ngIf="noOfRecords>0">
                    <div class="col-6 float-end">
                        <!-- Arrow Button for Previous and Next items in list -->
                        <label class="form-label float-end">{{recordsDisplayedCount}} of {{noOfRecords}} &nbsp;
                            <a (click)="loadPreviousDiagnosisKeywords()"><i class="fa fa-chevron-left"
                                    [ngClass]="{arrowDisabled: currentPage==1, arrowActive: currentPage>1}"
                                    aria-hidden="true"></i></a>
                            &nbsp;
                            <a (click)="loadNextDiagnosisKeywords()"><i class="fa fa-chevron-right arrowActive"
                                    [ngClass]="{arrowDisabled: currentPage==noOfPages, arrowActive: currentPage<noOfPages}"
                                    aria-hidden="true"></i>
                            </a>
                        </label>
                    </div>
                </div>

                <!-- Select and Cancel buttons -->
                <div *ngIf="noOfRecords>0" class="modal-footer">
                    <a class="btn btn-link" type="button" class="btn btn-primary text-white shadow rounded-pill" (click)="modal.close('Close click')">Cancel</a>
                    <button type="button" (click)="selectDiagnosisKeywordClicked()" class="btn btn-primary text-white shadow rounded-pill">Select</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<!-- Recent Searches Modal -->
<ng-template #recentSearches let-modal>
    <div class="modal-header"  aria-hidden="true" role="dialog" aria-labelledby="recentSearches">
        <h2 id="recentMedications" class="modal-title" tabindex="-1" aria-modal="true">Medication search history</h2>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>

    <div class="modal-body">
        <datatable [headers]="recentSearchHeaders" [rows]="recentSearchHistory"
            (onHeaderClicked)="onRecentHeaderClick($event)" (onCellClicked)="onRecentCellClick($event)" aria-labelledby="recentMedications">
        </datatable>
    </div>
</ng-template>

<!-- Saved Searches Modal -->
<ng-template #savedSearches let-modal>
    <div class="modal-header" aria-hidden="true" role="dialog" aria-labelledby="savedSearches">
        <h2 id="savedMedications" class="modal-title" tabindex="-1" aria-modal="true">Saved medications</h2>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click');"></button>
    </div>
    <div class="modal-body">
        <datatable [headers]="savedSearchHeaders" [rows]="savedSearchHistory"
            (onHeaderClicked)="onSavedHeaderClick($event)" (onCellClicked)="onSavedCellClick($event)" aria-labelledby="savedMedications">
        </datatable>
    </div>
</ng-template>

<!--END Modals for Error, Diagnosis code, Recent Searches and Saved Searches-->


<!-- Templates for Data Table -->
<ng-template #drugNameTemplate let-data="data">
    <p class="large" aria-labelledby="drugNameTemplate">&nbsp;{{data.drugDescription}}</p>
</ng-template>

<ng-template #actionRemove let-data="data">
    <div class="row" aria-labelledby="actionRemove">
        <div class="col-8">
            <a class ="large underline text-danger" name="remove" style="font-weight: 700;" enableAction="true" tabindex="0">Remove</a>
        </div>
    </div>
</ng-template>

<ng-template #actionSelect let-data="data">
    <div class="row" aria-labelledby="actionSelect">
        <div class="col-4 p-2">
            <i class="fa fa-angle-right" style="font-size: 32px!important; color: #0470c5;" tabindex="0"></i>
        </div>
    </div>
</ng-template>

<ng-template #recentDrugPrdouctId let-data="data">
    <p class="large" aria-labelledby="recentDrugPrdouctId">&nbsp;{{data.productId}}</p>
</ng-template>
<ng-template #recentDrugNameTemplate let-data="data">
    <p class="large" aria-labelledby="recentDrugNameTemplate">{{data.detailedDrugName}}</p>
</ng-template>
<ng-template #actionRecentSearchRemove let-data="data">
    <div class="row" aria-labelledby="actionRecentSearchRemove">
        <div class="col-8">
            <a class="large underline text-danger mt-3" name="remove" tabindex="0" style="font-weight: 700;">Remove</a>
        </div>
    </div>
</ng-template>

<ng-template #actionRecentSearchSelect let-data="data">
    <div class="row" aria-labelledby="actionRecentSearchSelect">
        <div class="col-4 p-2">
            <i class="fa fa-angle-right" style="font-size: 32px!important; color: #0470c5;" tabindex="0"></i>
        </div>
    </div>
</ng-template>

<ng-template #diagnosisCodeTemplate let-data="data">
    <p class="large" aria-labelledby="diagnosisCodeTemplate">{{data.diagnosisCode.trim()}}</p>
</ng-template>

<ng-template #diagnosisKeywordTemplate let-data="data">
    <p class="large" aria-labelledby="diagnosisKeywordTemplate">{{data.diagnosisDescription.trim()}}</p>
</ng-template>

<ng-template #diagnosisCodeCheckboxTemplate let-data="data">
    <input class="form-check-input" style="margin-left:10px;" enableAction="true" type="checkbox"
        name="diagnosisCodeCheckbox" [checked]="data.selected" aria-labelledby="diagnosisCodeCheckboxTemplate">
</ng-template>

<!--END of Templates for Data Table -->


<ng-template #errorPopup let-modal>
    <div class="p-3" role="dialog" aria-labelledby="errorPopup">
        <div class="row text-center"><i class="fa fa-ban text-danger" aria-hidden="true"></i></div>
        <div class="text-center mt-2" tabindex="-1" aria-modal="true">{{errorPopupText}}</div>
        <div class="row border-0 mt-4 p-3 "><button
                class="col-2  position-absolute top-80 start-50 translate-middle btn btn btn-primary"
                (click)="modal.dismiss()">OK</button></div>
    </div>
</ng-template>