import { Component, OnInit } from '@angular/core';
import { ProfileService } from './../../services/profile/profile.service';
import { APPCONSTANTS } from 'src/app/constants/appconstant';
import { Router } from '@angular/router';
import { Loginwebresponse, Infusion } from 'src/app/modals/login/loginwebresponse';
import { Status } from 'src/app/modals/webresponsestatus';


@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {
  status: APPCONSTANTS;
  errorStatus!:any;
  inacStatus!:boolean;

  constructor(public profileService: ProfileService,private router: Router) { 
    this.status = profileService.appstatus;
    
  }

  ngOnInit(): void {
    // //debugger;
  this.errorStatus=JSON.parse(""+sessionStorage.getItem('state'));
   console.log(this.errorStatus);
  }

  tryAgain(){
    window.location.reload();
    this.router.navigateByUrl("/api");
  }
}
