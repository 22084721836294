<div class="user_menu_group" [ngClass]="blnMenuOpened?'open':'closed'" (focus)="blnMenuOpened">
    <a class="btn btn-link invisiblebtn menu_icon mt-1 p-0" href="javascript:void(0)" aria-label="Link"  (click)="blnMenuOpened=!blnMenuOpened">
        <!-- <img alt="" class="img logo_img" src="./../../../assets/images/menu.png"> -->
        <span class="">Menu <i class="fw-bold" [ngClass]="blnMenuOpened?'fa fa-angle-up':'fa fa-angle-down'"></i></span>
        <!-- <span class="accordin-show"><i class="fw-bold" [ngClass]="blnMenuOpened?'fa fa-angle-down':'fa fa-bars'"></i></span> -->
    </a>
</div>
<div class="verticalbar" *ngIf="blnMenuOpened"></div>
<div class="menu_items" [hidden]="!blnMenuOpened">
    <div class="horizontalbar"></div>
    <ul class="dropdown-menu overflow-visible" *ngIf="blnMenuOpened">
        <li>
            <button class="text-left border-0 ms-2 bg-transparent row" aria-label="Link to Check Medication Coverage"
                style="width: 100%;" tabindex="0" routerLink="/coverage" (click)="blnMenuOpened=!blnMenuOpened">
                <div  class="col-md-10 col-xs-8 col-sm-10 col-10 pt-3 m-0 text-start">
                    <label class="fontbold m-0">Check medication coverage</label>
                    <p  class="m-0 fs-6">See if medications are covered by member's plan.</p>
                </div>
                <div class="col-md-2 col-xs-4 col-sm-2 col-2 pt-3 m-0">
                    <img  src="./../../../assets/images/Icon_PillBottle_RGB.svg" alt="Check coverage caption"  class="figure-img img-fluid rounded" aria-hidden="true" focusable="false">
                </div>
            </button>
            <hr />
        </li>
        <li>
            <button class="text-left border-0 ms-2 bg-transparent row" aria-label="Link to Submit a medication claim"
                style="width: 100%;" routerLink="/smapclaims" (click)="blnMenuOpened=!blnMenuOpened && gotoSavedClaims()">
                <div  class="col-md-10 col-xs-8 col-sm-10 col-10 pt-3 m-0 text-start">
                    <label class="fontbold m-0">Submit a medication claim</label>
                    <p  class="m-0 fs-6">Submit medication claims from saved trials.</p>
                </div>
                <div class="col-md-2 col-xs-4 col-sm-2 col-2 pt-3 m-0">
                    <img  src="./../../../assets/images/Icon_Desktop_RGB.svg" alt="Submit Medication caption"  class="figure-img img-fluid rounded" aria-hidden="true" focusable="false">
                </div>
            </button>
            <hr />
            
        </li>
        <li>
            <button class="text-left border-0 ms-2 bg-transparent row" aria-label="Link to Search medication claims"
                style="width: 100%;" routerLink="/searchMediactionClaims" (click)="blnMenuOpened=!blnMenuOpened">
                <div  class="col-md-10 col-xs-8 col-sm-10 col-10 pt-3 m-0 text-start">
                    <label class="fontbold m-0">Search medication claims</label>
                    <p  class="m-0 fs-6">Search and view trial, paid, and reversal claims.</p>
                </div>
                <div class="col-md-2 col-xs-4 col-sm-2 col-2 pt-3 m-0">
                    <img  src="./../../../assets/images/Icon_MagnifyingGlass_RGB.svg" alt="Search medication caption"  class="figure-img img-fluid rounded" aria-hidden="true" focusable="false">
                </div>
            </button>
            <hr />
        </li>
        <li>
            <button class="text-left border-0 ms-2 bg-transparent row" aria-label="Link to Medication claims reverasals"
                style="width: 100%;" routerLink="/claimreversal" (click)="blnMenuOpened=!blnMenuOpened">
                <div  class="col-md-10 col-xs-8 col-sm-10 col-10 pt-3 m-0 text-start">
                    <label class="fontbold m-0">Medication claims reversals</label>
                    <p  class="m-0 fs-6">View submitted claims and allow claim reversals.</p>
                </div>
                <div class="col-md-2 col-xs-4 col-sm-2 col-2 pt-3 m-0">
                    <img  src="./../../../assets/images/Icon_GoDigital_RGB.svg" alt="Claim reversal caption"  class="figure-img img-fluid rounded" aria-hidden="true" focusable="false">
                </div>
            </button>
            <hr />
        </li>
        <li *ngIf="blnDrugFeeTable">
            <button class="text-left border-0 ms-2 bg-transparent row" aria-label="Link to Drug Fee Table"
                style="width: 100%;" routerLink="/drugadmin" (click)="blnMenuOpened=!blnMenuOpened">
                <div  class="col-md-10 col-xs-8 pt-3 m-0 text-start">
                    <label class="fontbold m-0">Drug fee table</label>
                    <p  class="m-0 fs-6">Associate Drug Fee cost to an infusion facility.</p>
                </div>
            </button>
            <hr />
        </li>
        <li>
            <button (click)="openUserDetails()" class="text-left border-0 ms-2 bg-transparent row" aria-label="Link to User Profile"
                style="width: 100%;">
                <div  class="col-md-10 col-xs-8 pt-3 m-0 text-start">
                    <label class="fontbold m-0">User profile</label>
                    <p  class="m-0 fs-6">View user detail information.</p>
                </div>
            </button>
            <hr />
        </li>
        <li>
            <button (click)="blnMenuOpened=!blnMenuOpened;logout()" class="text-left border-0 ms-2 p-2 bg-transparent row"
            aria-label="Link to Logout" style="width: 100%;">
                <div  class="col-md-10 col-xs-8 m-0 ps-3 text-start">
                    <label class="fontbold m-0" style="cursor: pointer;">Logout</label>
                </div>
            </button>
            <hr class="full mt-3" />
        </li>
        <li>
            <button (click)="blnMenuOpened=!blnMenuOpened" routerLink="/contactus" class="text-left border-0 ms-2 bg-transparent row"
            aria-label="Link to Contact us"  style="width: 100%;">
                <div  class="col-md-10 col-xs-8 p-3 m-0 text-start">
                    <label class="fontbold m-0">Contact us</label>
                </div>
            </button>
        </li>
    </ul>
</div>
<div class="contextbackground" *ngIf="blnMenuOpened" (click)="blnMenuOpened=false"></div>
<ng-template #userdetailmodal let-modal>
    <div class="container-fluid userdetails" aria-label="Link to User Profile Information">
        <div class="row" ngbAutofocus>
            <div class="col">
                <h4>User Profile Information</h4>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <table>
                    <tr>
                        <td>User Name</td>
                        <td>:</td>
                        <td>{{loginUser.username}}</td>
                    </tr>
                    <tr>
                        <td>User ID</td>
                        <td>:</td>
                        <td>{{loginUser.ohid}}</td>
                    </tr>
                    <tr>
                        <td>Link ID</td>
                        <td>:</td>
                        <td>{{loginUser.linkUuid}}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>Infusion Facility(s) :</p>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>Facility Name</th>
                            <th>Facility NPI</th>
                            <th>Zip Code</th>
                            <th>Permission/Role</th>
                            <th>Relation ID</th>
                            <th>Network ID</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let infusion of loginUser.infusionList">
                            <td>{{infusion.pharmacyName}}</td>
                            <td>{{infusion.pharmacyNPI}}</td>
                            <td>{{infusion.phmcyZip}}</td>
                            <td> {{getRoleName(infusion.roleTypeId)}}</td>
                            <td>{{infusion.organizationRelationId}}</td>
                            <td>{{infusion.networkId}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col text-end">
                <button type="button" class="btn btn-primary" (click)="modal.dismiss()">Close</button>
            </div>
        </div>
    </div>
</ng-template>