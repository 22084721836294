import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [NgbModalConfig, NgbModal]
})
export class AppComponent {
  title = 'SMAP Dashboard';
  constructor(config: NgbModalConfig, private modalService: NgbModal, protected router:Router) {
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;
    config.centered = true;
  }

  // @HostListener("window:beforeunload", ["$event"])
  // unloadHandler(event: any) {
  //   event.preventDefault();
  //   // return event.returnValue = "Are you sure you want to reload, this will take you to home page?";
  // }

  ngOnInit(): void {
    // //debugger;
    sessionStorage.setItem("isLoggedIn", String(true));

    // Log the initial URI
    const initialUrl = this.router.url;
    // console.log('Initial URL:', initialUrl);
    // console.log('Initial URL:', this.route);
    // console.log('Window URL:', window.location.href);
    const bypassRoutes = ['/verifyProvider','/contactus','/terms','/privacy','/error'];
    for(let i=0;i<bypassRoutes.length;i++){
      if(window.location.href.indexOf(bypassRoutes[i])!=-1){
        sessionStorage.setItem("lastPageBeforeReload",bypassRoutes[i]);
        break;
      }
      else if(window.location.href.endsWith(".com")|| window.location.href.endsWith(".com/")){
         sessionStorage.removeItem("lastPageBeforeReload");
         break;
      }
    }
    sessionStorage.setItem("isLoggedIn", String(true));
    if(sessionStorage.getItem("lastPageBeforeReload")!=null){
      //console.log("Rerouting:"+sessionStorage.getItem("lastPageBeforeReload"))
      this.goto(""+sessionStorage.getItem("lastPageBeforeReload"));
    }else{
      sessionStorage.setItem("lastPageBeforeReload", this.router.url=="/"?"":this.router.url);
    }
  }

  goto(routeUrl:string){
    if(sessionStorage.getItem("state")!=undefined){
      const savedState= JSON.parse(""+sessionStorage.getItem("state"));
      this.router.navigateByUrl("/"+routeUrl, {state: savedState});
    }else{
      this.router.navigateByUrl("/"+routeUrl);
    }
  }
}
