import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ProfileService } from '../services/profile/profile.service';
import { HttpClient } from '@angular/common/http';
import { APPCONSTANTS } from '../constants/appconstant';
import { Observable } from 'rxjs';

export function initializeProfile(httpclient: HttpClient, profileService: ProfileService) {
  return () => new Promise((resolve, reject) => {
    profileService.readJWTTokenAndUserDetails("",true).then((resp:any)=>{
      resolve(true);
    },(err:any)=>{
      console.log(err);
      resolve(true);
    })
  });
}

export interface TokenService{
  readJWTTokenAndUserDetails(accessCode:string,blnAppInitialization:boolean):Promise<boolean>;
}


@Injectable({
  providedIn: 'root'
})
//export class AuthGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad, CanMatch {
export class AuthGuard implements CanActivate {  

  constructor(private profileService:ProfileService,
    private router: Router){

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      //debugger;
    const bypassRoutes = ['/verifyProvider','/contactus','/terms','/privacy'];
    if(bypassRoutes.includes(state.url)){
      return true;
    }
    else if(this.profileService.appLogin == APPCONSTANTS.APP_INITIALIZED){
      return true;
    }
    else{
      //debugger;
      let params:URLSearchParams = new URLSearchParams(location.search);
      let accessCode:string | null=params.get('code');
      if(this.profileService.appLogin == APPCONSTANTS.APP_NOT_INITIALIZED && accessCode==null)
      {
        this.router.navigateByUrl("/sso");
        return false;
      } else {
        //debugger;
        this.profileService.appLogin = APPCONSTANTS.APP_ACCESS_CODE_RECEIVED;
        if(this.profileService.appLogin == APPCONSTANTS.APP_ACCESS_CODE_RECEIVED && accessCode!=null){
          return this.profileService.readJWTTokenAndUserDetails(accessCode);
        }
        return false;
      }
    }
  }
}


@Injectable({
  providedIn: 'root'
})
//export class AuthGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad, CanMatch {
export class NoSSOGuard implements CanActivate {  

  constructor(private _profileService:ProfileService,
    private _router: Router){

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(this._profileService.appLogin == APPCONSTANTS.APP_INITIALIZED)
    {
      this._router.navigateByUrl("/");
      return false;
    }
    else
      return true;
    
  }
}


